<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="create-order">
        <h3 class="order-type">Orden de Trabajo: {{ localNoteDetails.note_number }}</h3>
        <div class="form-group1">
                        <input type="text1" id="order_number" placeholder="Ingrese un numero" v-model="localNoteDetails.note_number" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
        <button @click="showForm('initialData')">Datos iniciales y Cobro</button>
        <button @click="showForm('assignment')">Asignación</button>
        <button @click="showForm('billing')">Facturación</button>
        <button @click="showForm('images')">Imágenes</button>
    </div>
    <div v-if="currentForm === 'initialData'">
        <div class="main-data-container">
            <div class="client-data-container">
                <div class="tittle">
                    <h4>Datos del cliente</h4>
                    <img src="@/assets/client-icon.png">
                </div>
                <div class="client-container">
                    <div class="form-group1">
                        <label for="client_name">Cliente</label>
                        <input type="text" id="client_name" v-model="client_name" @input="filterClients" @keydown.down.prevent="navigateClients(1)" @keydown.up.prevent="navigateClients(-1)" @keydown.enter.prevent="selectHighlightedClient" autocomplete="off" />
                    </div>
                    <div class="dropdown" v-if="filteredClients.length" ref="dropdownContainer" style="position: absolute; z-index: 1050; background: white; max-height: 200px; overflow-y: auto;" v-click-outside="closeDropdown">
                        <li v-for="(client, index) in filteredClients" :key="client.id" :class="{ 'active': index === highlightedIndex }" @click="selectClient(client)" ref="dropdownItem">
                            {{ client.client_name }}
                        </li>
                    </div>
                </div>
                <div class="button-add-new-client" v-if="!isClientSelected">
                    <button @click="toggleNewClientForm" class="btn-submit-save" v-if="!addNewClient">Agregar nuevo cliente</button>
                </div>
                <div class="data-client" v-if="isClientSelected">
                    <div class="data-container">
                        <div class="form-group1">
                            <label for="client_street">Calle</label>
                            <label>{{ clientDetails.client_address.street }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="client_codigoPostal">C.P</label>
                            <label>{{ clientDetails.client_address.codigoPostal }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="client_neighborhood">Colonia</label>
                            <label>{{ clientDetails.client_address.neighborhood }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="client_city">Ciudad</label>
                            <label>{{ clientDetails.client_address.city }}</label>
                        </div>
                    </div>
                    <div class="data-container">
                        <div class="form-group1">
                            <label for="client_state">Estado</label>
                            <label>{{ clientDetails.client_address.state }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="client_rfc">RFC</label>
                            <label>{{ clientDetails.client_rfc }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="phone">Teléfono</label>
                            <label>{{ clientDetails.phone }}</label>
                        </div>
                    </div>
                </div>
                <div class="data-client" v-if="addNewClient">
                    <div class="data-container">
                        <div class="checkbox-container">
                            <label class="switch">
                                <input type="checkbox" id="isVip" v-model="is_privileged">
                                <span class="slider round"></span>
                            </label>
                            <label for="isVip"> Recurrente </label>
                            <!--<input type="checkbox" id="isVip" v-model="is_privileged" />-->   
                        </div>
                        <div class="form-group1">
                            <label for="client_street">Calle</label>
                            <input type="text1" id="client_street" v-model="clientDetails.client_address.street" />
                        </div>
                        <div class="form-group1">
                            <label for="client_codigoPostal">C.P</label>
                            <input type="text1" id="client_codigoPostal" placeholder="Ej. 64849" v-model="clientDetails.client_codigoPostal" @keypress="validatePositiveIntegerField"/>
                        </div>
                        <div class="form-group1">
                            <label for="client_neighborhood">Colonia</label>
                            <input type="text1" id="client_neighborhood" placeholder="Ej. Tecnológico" v-model="client_neighborhood" />
                        </div>
                    </div>
                    <div class="data-container">
                        <div class="form-group1">
                            <label for="client_city">Ciudad</label>
                            <input type="text1" id="client_city" placeholder="Ingrese una ciudad" v-model="client_city" />
                        </div>
                        <div class="form-group1">
                            <label for="client_state">Estado</label>
                            <select class="state-dropdown" id="client_state" v-model="client_state">
                                <option v-for="state in allStates" :key="state.name" :value="state.name">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group1">
                            <label for="client_rfc">RFC</label>
                            <input type="text1" id="client_rfc" placeholder="Ingrese el RFC" v-model="clientDetails.client_rfc" />
                        </div>
                        <div class="form-group1">
                            <label for="phone">Teléfono</label>
                            <input type="text1" id="phone" v-model="clientDetails.phone" />
                        </div>
                    </div>
                    <div class="buttons-for-saveClient">
                        <button type="submit" class="btn-submit-save" @click="saveNewClient">Guardar datos del nuevo cliente</button>
                        <button @click="cancelNewClient" class="btn-submit-cancel">Cancelar</button>
                    </div>
                </div>
                <div class="tittle">
                    <h4>Datos del vehículo</h4>
                    <img src="@/assets/vehicle-icon.png">
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="vehicle_brand">Marca</label>
                        <select class="state-dropdown" v-model="localNoteDetails.vehicle_brand" @change="FetchFilteredSubBrands" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option v-for="(brand, pk) in brands" :key="brand" :value="brand">
                                {{ pk }}
                            </option>
                        </select>                
                    </div>
                    <div class="form-group1">
                        <label for="vehicle_subbrand">Submarca</label>
                        <select class="state-dropdown" v-model="localNoteDetails.vehicle_sub_brand" :disabled="blockEdit || subBrandLoading"
                            :class="{ 'grayed-out': blockEdit, 'sub-brand-loading-grayed-out': subBrandLoading }">
                            <option v-for="(sub_brand, id) in sub_brands" :key="sub_brand" :value="sub_brand">
                                {{ id }}
                            </option>
                        </select>                
                    </div>
                    <div class="form-group1">
                        <label for="vehicle_model">Modelo</label>
                        <input type="text1" id="vehicle_model" placeholder="Si desconoce, deje vacío. (Formato YYYY)" v-model="localNoteDetails.vehicle_model" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group1">
                        <label for="vehicle_plates">Placas</label>
                        <input type="text1" id="vehicle_plates" placeholder="Ingrese las placas" v-model="localNoteDetails.vehicle_plates" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="vehicle_engine_series">Serie</label>
                        <input type="text1" id="vehicle_engine_series" placeholder="Ingrese la serie" v-model="localNoteDetails.vehicle_engine_series" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="vehicle_color">Color 1</label>
                        <input type="text1" id="vehicle_color" placeholder="Ingrese el color" v-model="localNoteDetails.vehicle_color" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="vehicle_color2">Color 2</label>
                        <input type="text1" id="vehicle_color2" placeholder="Ingrese el color 2" v-model="localNoteDetails.vehicle_color2" />
                    </div>
                    <div class="form-group1">
                        <label for="insurance_policy">Póliza</label>
                        <input type="text1" id="insurance_policy" placeholder="Ingrese la póliza" v-model="localNoteDetails.insurance_policy" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="vehicle_owner_name">Asegurado</label>
                        <input type="tel1" id="vehicle_owner_name" placeholder="Ingrese el nombre del asegurado" v-model="localNoteDetails.vehicle_owner_name" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="file_number">Expediente</label>
                        <input type="tel1" id="file_number" placeholder="Ingrese el expediente" v-model="localNoteDetails.file_number" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="sucursal">Sucursal</label>
                        <select class="state-dropdown" id="sucursal" v-model="localNoteDetails.sucursal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="ASISTENCIA AUXILIO VIAL">ASISTENCIA AUXILIO VIAL</option>
                            <option value="ASISTENCIA AUXILIO VIAL FORANEAS">ASISTENCIA AUXILIO VIAL FORANEA</option>
                            <option value="ASISTENCIAS FORANEAS">ASISTENCIAS FORANEAS</option>
                            <option value="ASISTENCIAS">ASISTENCIAS</option>
                            <option value="ASISTENCIAS PARTICULAR">ASISTENCIAS PARTICULAR</option>
                            <option value="AUTOPISTA LAREDO">AUTOPISTA LAREDO</option>
                            <option value="CANCELADOS">CANCELADOS</option>
                            <option value="CONTADO">CONTADO</option>
                            <option value="DETENIDOS">DETENIDOS</option>
                            <option value="ELECTRONICA INDUSTRIA">ELECTRONICA INDUSTRIA</option>
                            <option value="ELECTRONICA REMOLQUE">ELECTRONICA REMOLQUE</option>
                            <option value="FILIALES">FILIALES</option>
                            <option value="FORANEOS">FORANEOS</option>
                            <option value="REVOLUCION">REVOLUCION</option>
                            <option value="HYLSA">HYLSA</option>
                            <option value="INSTALACION">INSTALACION</option>
                            <option value="LOTE RIO ESCONDIDO"> LOTE RIO ESCONDIDO</option>
                            <option value="LOTE SALTILLO">LOTE SALTILLO</option>
                            <option value="LOTE SANTA CATARINA Q-0420"> LOTE SANTA CATARINA Q-0420</option>
                            <option value="LOTE SANTA ROSA"> LOTE SANTA ROSA</option>
                            <option value="LOTE CHINA">LOTE CHINA</option>
                            <option value="LOTE GUADALUPE">LOTE GUADALUPE</option>
                            <option value="LOTE GUADALUPE FEDERAL">LOTE GUADALUPE FEDERAL</option>
                            <option value="LOTE JARDINES">LOTE JARDINES</option>
                            <option value="LOTE REVOLUCION">LOTE REVOLUCION</option>
                            <option value="LOTE SANTA CATARINA">LOTE SANTA CATARINA</option>
                            <option value="MANTENIMIENTO">MANTENIMIENTO</option>
                            <option value="METRO GRUAS">METRO GRUAS</option>
                            <option value="NOGALAR">NOGALAR</option>
                            <option value="OVERHAULING">OVERHAULING</option>
                            <option value="REFACTURACION">REFACTURACION</option>
                            <option value="REMOLQUE">REMOLQUE</option>
                            <option value="SAN RAFAEL">SAN RAFAEL</option>
                            <option value="SUC SANTA CATARINA">SUC SANTA CATARINA</option>
                            <option value="TRANSITO SANTA CATARINA">TRANSITO SANTA CATARINA</option>
                            <option value="VENTAS">VENTAS</option>
                            <option value="VENTA DE ACCESORIOS">VENTA DE ACCESORIOS</option>
                            <option value="VENTA DE EQUIPO USADO">VENTA DE EQUIPO USADO</option>
                            <option value="Z ALFONSO REYES">Z ALFONSO REYES</option>
                        </select>
                    </div>
                    <div class="form-group1">
                        <label for="aux_requested_service">Detalle de servicio</label>
                        <select class="state-dropdown" id="aux_requested_service" v-model="localNoteDetails.aux_requested_service" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="CAMBIO DE LLANTA">CAMBIO DE LLANTA</option>
                            <option value="PASO DE CORRIENTE">PASO DE CORRIENTE</option>
                            <option value="SUMINISTRO DE GASOLINA">SUMINISTRO DE GASOLINA</option>
                        </select>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="approved_by">Aprobado por:</label>
                        <input type="tel1" id="approved_by" placeholder="Ingrese el nombre de quien aprueba" v-model="localNoteDetails.approved_by" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="expected_time">Tiempo estimado:</label>
                        <input type="tel1" id="expected_time" placeholder="Ingrese el tiempo esperado en minutos" v-model="localNoteDetails.expected_time" />
                    </div>
                </div>
            </div>
            <div class="payment-data-container">
                <div class="tittle">
                    <h4>Origen</h4>
                    <img src="@/assets/ubi-icon.png">
                </div>
                <div class="data-container">
                    <div class="checkbox-group">
                        <input type="checkbox" id="toggleFields" v-model="showOptionalFields" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <label for="toggleFields">Servicio carretero</label>
                    </div>
                    <div class="form-group1" >
                        <label for="source_street">Calle y número</label>
                        <input type="text1" id="source_street" v-model = "localNoteDetails.destination.street_name" placeholder="Ingrese la calle principal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <!-- <div class="form-group1" v-show="!showOptionalFields">
                        <label for="source_number">Número</label>
                        <input type="text1" id="source_number" v-model = "localNoteDetails.destination.number" placeholder="Ingrese el número" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div> -->
                    <div class="form-group1" v-show="!showOptionalFields">
                        <label for="source_inbtw">Entrecalles</label>
                        <input type="text1" id="source_inbtw" v-model = "localNoteDetails.destination.between_streets" placeholder="Ingrese entre que calles se encuentra" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1" v-show="!showOptionalFields">
                        <label for="source_neighborhood">Colonia</label>
                        <input type="text1" id="source_neighborhood" v-model = "localNoteDetails.destination.neighborhood" placeholder="Ingrese la colonia" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1" >
                        <label for="source_postal_code">C.P</label>
                        <input type="text1" id="source_postal_code" v-model = "localNoteDetails.destination.zip_code" placeholder="Ingrese el código postal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group1" v-show="!showOptionalFields" style="position: relative;">
                        <label for="destination_municipality">Municipio</label>
                        <input type="text1" id="destination_municipality" v-model="localNoteDetails.destination.city" @input="destinationFilterMunicipalities" placeholder="Ingrese el municipio" autocomplete="off" />
                        <ul class="muni-dropdown" v-if="destinationFilteredMunicipalities.length" v-click-outside="closeDropdown">
                            <li v-for="(municipality, index) in destinationFilteredMunicipalities" :key="index" @click="destinationSelectMunicipality(municipality)">
                                {{ municipality }}
                            </li>
                        </ul>
                    </div>
                    <div class="form-group1" v-show="!showOptionalFields">
                        <label for="source_state">Estado</label>
                        <select class="state-dropdown" id="source_state" v-model="localNoteDetails.destination.state" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option v-for="state in allStates" :key="state.name" :value="state.name">
                                {{ state.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="source_lat">Latitud</label>
                        <input type="tel1" id="source_lat" v-model="localNoteDetails.destination.lat" placeholder="Ingrese la latitud" @keypress="validateFloatField" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="source_long">Longitud</label>
                        <input type="tel1" id="source_long" v-model="localNoteDetails.destination.lon" placeholder="Ingrese la longitud" @keypress="validateFloatField" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1" v-show="showOptionalFields">
                        <label for="source_km">KM (Solo si es en carretera)</label>
                        <input type="tel1" id="source_km" v-model = "localNoteDetails.destination_km" placeholder="Ingrese el KM de la carretera" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group1" style="display: inline-flex; align-items: center; gap: 10px; width: auto;">
                        <label for="source_notes" style="white-space: nowrap;">Observaciones</label>
                        <textarea id="source_notes" rows="1" v-model = "localNoteDetails.destination_notes" placeholder="Ingrese información adicional" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" style="resize: both; flex-shrink: 0; width: 320px; font-size: var(--text-size, 11px);"></textarea>
                    </div>  
                    </div>
                <div class="data-container">
                    <div class="form-group" style="display: inline-block; width: auto;">
                        <!-- <label for="quick_destination">Origen</label> -->
                        <textarea id="quick_destination" rows="1" v-model = "localNoteDetails.quick_destination" placeholder="Ingrese el origen" style="resize: both; overflow: auto; min-width: 785px;min-height: 50px; max-width: 100%; font-size: var(--text-size, 11px);"></textarea>
                    </div>
                </div>
                <div class="tittle">
                    <h4>Costos</h4>
                    <img src="@/assets/cost-icon.png">
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="cost_by_km">Costo por kilómetro</label>
                        <input type="text1" id="cost_by_km" placeholder="Ingrese el costo por km" v-model="localNoteDetails.cost_by_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="total_kms">Total de kilómetros</label>
                        <input type="text1" id="total_kms" placeholder="Ingrese el total de kms" v-model="localNoteDetails.total_kms" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="total_kms">Costo por tráfico</label>
                        <input type="text1" id="cost_by_traffic" placeholder="Ingrese el costo por tráfico" v-model="localNoteDetails.cost_by_traffic" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="maneuvers">Maniobras</label>
                        <input type="text1" id="maneuvers" placeholder="Ingrese las maniobras realizadas" v-model="localNoteDetails.maneuvers" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="highway_tolls">Casetas</label>
                        <input type="text1" id="highway_tolls" placeholder="Ingrese el número de casetas pagadas" v-model="localNoteDetails.highway_tolls" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="subtotal">Subtotal</label>
                        <input type="text1" id="subtotal" placeholder="Ingrese el subtotal" v-model="localNoteDetails.subtotal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="iva">IVA</label>
                        <input type="text1" id="iva" placeholder="Ingrese el iva" v-model="localNoteDetails.iva" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="retention">Retención</label>
                        <input type="tel1" id="retention" placeholder="Ingrese la retención" v-model="localNoteDetails.retention" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="total">Total</label>
                        <input type="tel1" id="total" placeholder="Ingrese el total" v-model="localNoteDetails.total" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="internal_payment_type">Tipo de pago</label>
                        <select class="state-dropdown" type="tel" id="internal-payment-type" v-model="localNoteDetails.internal_payment_type" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="4">4 - Crédito</option>
                            <option value="0">0 - Otros</option>
                        </select>
                    </div>
                    <div class="form-group1">
                        <label for="payment_method">Método de pago</label>
                        <select class="state-dropdown" id="payment_method" v-model="localNoteDetails.payment_method" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="PPD">PAGO EN PARCIALIDADES O DIFERIDO</option>
                            <option value="PUE">PAGO EN UNA EXHIBICIÓN</option>
                        </select>
                    </div>
                    <div class="form-group1">
                    <label for="payment_type">Forma de pago (SAT)</label>
                        <select class="state-dropdown" id="payment-type" v-model="localNoteDetails.payment_type" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="99" v-if="localNoteDetails.payment_method === 'PPD'">POR DEFINIR</option>
                            <template v-if="localNoteDetails.payment_method !== 'PPD'">
                                <option value="99">POR DEFINIR</option>
                                <option value="04">TARJETA DE CRÉDITO</option>
                                <option value="03">TRANSFERENCIA</option>
                                <option value="01">EFECTIVO</option>
                                <option value="28">TARJETA DE DÉBITO</option>
                                <option value="02">CHEQUE NOMINATIVO</option>
                            </template>
                        </select>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="invoice_use">CFDI</label>
                        <select class="state-dropdown" id="invoice_use" v-model="localNoteDetails.invoice_use" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="G01">ADQUISICIÓN DE MERCANCÍAS</option>
                            <option value="G02">DEVOLUCIOINES, DESCUENTOS O BONIFICACIONES</option>
                            <option value="G03">GASTOS EN GENERAL</option>
                            <option value="I01">CONSTRUCCIONES</option>
                            <option value="I02">MOBILIARIO Y EQUIPO DE OFICINA POR INVERSIONES</option>
                            <option value="I03">EQUIPO DE TRANSPORTE</option>
                            <option value="I04">EQUIPO DE CÓMPUTO Y ACCESORIOS</option>
                            <option value="I05">DADOS, TROQUELES, MOLDES, MATRICES Y HERRAMENTAL</option>
                            <option value="I06">COMUNICACIONES TELEFÓNICAS</option>
                            <option value="I07">COMUNICACIONES SATELITALES</option>
                            <option value="I08">OTRA MAQUINARIA Y EQUIPO</option>
                            <option value="D01">HONORARIOS MÉDICOS, DENTALES Y GASTOS HOSPITALARIOS</option>
                            <option value="D02">GASTOS MÉDICOS POR INCAPACIDAD O DISCAPACIDAD</option>
                            <option value="D03">GASTOS FUNERALES</option>
                            <option value="D04">DONATIVOS</option>
                            <option value="D05">INTERESES REALES EFECTIVAMENTE PAGADOS POR CRÉDITOS HIPOTECARIOS</option>
                            <option value="D06">APORTACIONES VOLUNTARIAS AL SAR</option>
                            <option value="D07">PRIMAS POR SEGUROS DE GASTOS MÉDICOS</option>
                            <option value="D08">GASTOS DE TRANSPORTACIÓN ESCOLAR OBLIGATORIA</option>
                            <option value="D09">DEPÓSITOS EN CUENTAS PARA EL AHORRO </option>
                            <option value="D10">PAGOS POR SERVICIOS EDUCATIVOS</option>
                            <option value="S01">SIN EFECTOS FISCALES</option>
                            <option value="CP01">PAGOS</option>
                            <option value="CN01">NÓMINA</option>
                        </select>
                    </div>
                    <div class="form-group1" style="display: inline-flex; align-items: center; gap: 10px; width: auto;">
                        <label for="payment-notes" style="white-space: nowrap;">Notas del pago</label>
                        <textarea id="payment-notes" rows="1" placeholder="Ingrese notas del pago" v-model="localNoteDetails.payment_notes" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" style="resize: both; flex-shrink: 0; width: 500px; font-size: var(--text-size, 11px);"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-footer">
            <form @submit.prevent="guardarDatos">
                <table>
                    <thead>
                    <tr>
                        <th>No. unidad</th>
                        <th>Nombre de unidad</th>
                        <th>KM Inicio</th>
                        <th>KM Fin</th>
                        <th>No. operador</th>
                        <th>Nombre de operador</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(grupo, index) in gruposCampos" :key="index">
                        <td>
                            <input type="text" v-model="grupo.unit" placeholder="Unidad" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                        </td>
                        <td>
                            <label :class="{ 'grayed-out': blockEdit }">{{ grupo.unidad_name || 'Nombre Unidad' }}</label>
                        </td>
                        <td>
                            <input type="number" v-model.number="grupo.start_km" placeholder="KM Inicio" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                        </td>
                        <td>
                            <input type="number" v-model.number="grupo.end_km" placeholder="KM Fin" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                        </td>
                        <td>
                            <input type="text" v-model="grupo.operator" placeholder="Operador" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" />
                        </td>
                        <td>
                            <label :class="{ 'grayed-out': blockEdit }">{{ grupo.operador_name + ' ' + grupo.operador_last_name || 'Nombre Operador' }}</label>
                        </td>
                        <td>
                            <button type="button" @click.prevent="eliminarCampos(index)" class="button-delete-operator" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" > Eliminar </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <button @click.prevent="agregarCampos" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Agregar más operadores</button>
                <button type="submit" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" :style="{ backgroundColor: blockEdit ? '#d3d3d3' : '#00ff1e', color: 'black'}">Guardar asignaciones</button>
            </form>
        </div>
    </div>
    <!-- Contenido del formulario de Asignación -->
    <div v-if="currentForm === 'assignment'">
        <div class="form-container">
            <div class="form-section">
                <div class="time-data">
                    <h3>Datos de tiempo</h3>
                    <img src="@/assets/time-icon.png">

                    <div v-for="(assignationData, index) in assignationsData" :key="index">
                        <div class="void"></div>
                        <div class="form-group">
                            <div><b>Unidad:</b> {{ assignationData.unidad }}</div>
                            <div> {{ assignationData.unidad_name }}</div>
                            <div><b>Operador:</b> {{ assignationData.operador }}</div>
                            <div>{{ assignationData.operador_name }} {{ assignationData.operador_last_name }}</div>
                            <label>Fecha y Hora de Inicio</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.start_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.start_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Arribo</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.arrival_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.arrival_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Contacto</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.contact_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.contact_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Termino</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.end_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.end_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <div class="void"></div>
                            <button class="btn-submit-save" @click="saveAssignation(assignationData,index)" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Actualizar</button>
                            <div class="void"></div>
                            <div class="form-group-final"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3>Asignación de unidad y operador</h3>
                <img src="@/assets/operator-assignament-icon.png">
                <div class="map-container">
                    <div class="map">
                        <Map
                        :center="mapCenter"
                        :zoom="10"
                        map-type-id="roadmap"
                        style="width: 40vw; height: 50vh"
                        >
                        <!-- Display blue markers for vehicle locations -->
                        <Marker
                                v-for="location in filteredBlueLocations"
                                :key="'blue_' + location.vehicle_plate"
                                :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
                                :icon="require('@/assets/grua-map-icon-marker-gray.png')"
                                :options="{ zIndex: 5 }" 
                                @click="openInfoWindow(location)"
                            />

                            <!-- Display red marker at a specified location -->
                            <Marker
                                v-if="showRedMarker"
                                :position="this.redMarkerPosition.value"
                                :icon="require('@/assets/red-marker.png')"
                                :options="{ zIndex: 99999 }" 
                            />

                            <!-- Display green markers for specific vehicle locations -->
                            <Marker
                                v-for="location in filteredGreenLocations"
                                :key="'green_' + location.vehicle_plate"
                                :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
                                :icon="require('@/assets/grua-map-icon-marker.png')"
                                :options="{ zIndex: 9999 }" 
                                @click="openInfoWindow(location)"
                            />

                        <!-- InfoWindow component -->
                        <InfoWindow
                            :options="{ content: infoContent }"
                            :position="infoPosition"
                            :opened="infoWindowOpened"
                            @closeclick="infoWindowOpened = false"
                        />
                        </Map>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3>Sugerencias</h3>
                <table>
                    <tr>
                        <th>Recomendación</th>
                        <th>Número económico</th>
                        <th>Tiempo estimado de arribo (min)</th>
                        <th>Seleccionar</th>
                    </tr>
                <tr>
                <td>1</td>
                <td>{{ this.recommendations[0]['unit'] }}</td>
                <td>{{ this.recommendations[0]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[0]['unit'])" :disabled="buttonClicked['btn1']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[0])" :class="{ 'grayed-out': buttonClicked['btn1']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[0]) }">Elegir</button></td>
                </tr>
            <tr>
                <td>2</td>
                <td>{{ this.recommendations[1]['unit'] }}</td>
                <td>{{ this.recommendations[1]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[1]['unit'])" :disabled="buttonClicked['btn2']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[1])" :class="{ 'grayed-out': buttonClicked['btn2']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[1]) }">Elegir</button></td>
            </tr>
            <tr>
                <td>3</td>
                <td>{{ this.recommendations[2]['unit'] }}</td>
                <td>{{ this.recommendations[2]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[2]['unit'])" :disabled="buttonClicked['btn3']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[2])" :class="{ 'grayed-out': buttonClicked['btn3']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[2]) }">Elegir</button></td>
            </tr>
            <tr>
                <td>4</td>
                <td>{{ this.recommendations[3]['unit'] }}</td>
                <td>{{ this.recommendations[3]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[3]['unit'])" :disabled="buttonClicked['btn4']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[3])" :class="{ 'grayed-out': buttonClicked['btn4']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[3]) }">Elegir</button></td>
            </tr>
            <tr>
                <td>5</td>
                <td>{{ this.recommendations[4]['unit'] }}</td>
                <td>{{ this.recommendations[4]['time_queue'] }}</td>
                <td><button @click="seleccionarOpcion(this.recommendations[4]['unit'])" :disabled="buttonClicked['btn5']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[4])" :class="{ 'grayed-out': buttonClicked['btn5']||blockEdit||blockRecommend||!isRecommendationValid(this.recommendations[4]) }">Elegir</button></td>
            </tr>
                </table>
                <div class="void"></div>
                <form @submit.prevent="guardarDatos">
                    <button @click.prevent="agregarCampos" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Agregar más operadores</button>
                    <button @click.prevent="fetchRecommendations()" :disabled="blockEdit||!blockRecommend||recommenderLoading" :class="{ 'grayed-out': !blockRecommend||recommenderLoading }">Recomendar</button>
                    <button type="button" @click.prevent="eliminarCampos()" class="button-delete-operator" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Eliminar</button>
                    <div v-for="(grupo, index) in gruposCampos" :key="index">
                        <div class="form-group">
                            <label :for="'unidad-' + index">Unidad asignada (Número económico)</label>
                            <input type="text" :id="'unidad-' + index" v-model="grupo.unit" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                        </div>
                        <div class="form-group">
                            <label :for="'operador-' + index">Operador asignado (Número de empleado)</label>
                            <input type="text" :id="'operador-' + index" v-model="grupo.operator" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                        </div>
                        <div class="form-group">
                            <label :for="'kmInicio-' + index">Km inicio</label>
                            <input type="text" :id="'kmInicio-' + index" v-model.number="grupo.start_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                        </div>
                        <div class="form-group">
                            <label :for="'kmFin-' + index">Km fin</label>
                            <input type="text" :id="'kmFin-' + index" v-model.number="grupo.end_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                        </div>
                        <div class="form-group-final"></div>
                    </div>
                    <button type="submit" class="btn-submit-save" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Guardar</button>
                </form>
            </div>
        </div>
    </div>

    <div v-if="currentForm === 'billing'">
        <div class="form-section">
            <h3>Facturación</h3>
            <img src="@/assets/cost-icon.png">
        </div>
        <div class="form-container">
            
            <div class="form-section">
                <h4>Datos de la factura</h4>
                <div class="checkbox-group">
                    <input type="checkbox" id="toggleFields2" v-model="invoiceBreakdown" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <label for="toggleFields2">Desglosar factura</label>
                </div>
                <!-- Not breakdown options, default -->
                <div class="form-group" v-if="!invoiceBreakdown">
                    <label for="prefered_product_key_traffic">Clave de producto</label>
                    <select id="prefered_product_key_traffic" v-model="this.prefered_product_key_traffic" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="72154503">72154503 - SERVICIO DE ALQUILER DE GRUAS</option><!--GRUA-->
                        <option value="78121603">78121603 - TARIFA DE LOS FLETES</option><!--KILOMETRAJE-->
                        <option value="90121800">90121800 - SERVICIO DE ASISTENCIA DE EMERGENCIA EN VIAJES</option> <!--PASO DE CORRIENTE-->
                        <option value="81141601">81141601 - LOGÍSTICA</option> <!--MANIOBRA . MONTACARGAS 15 MIL LBS<-->
                        <option value="78101803">78101803 - SERVICIO DE TRANSPORTE DE VEHÍCULOS</option> <!--TRAFICO-->
                        <option value="78181702">78181702 - DE ALMACENAJE DE VEHICULOS DE TRANSPORTE</option> 
                </select> 
                </div>
                <div class="form-group" v-if="!invoiceBreakdown">
                    <label for="prefered_measurement_unit_traffic">Unidad de medida</label>
                    <select id="prefered_measurement_unit_traffic" v-model="this.prefered_measurement_unit_traffic" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="ACT">ACT - TRAFICO</option>
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                    </select> 
                </div>
                <!-- Breakdown, options from client -->
                <div class="checkbox-group" v-if="invoiceBreakdown">
                    <input type="checkbox" id="toggleFields3" v-model="invoiceBreakdownEdit" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <label for="toggleFields3">Editar claves y unidades</label>
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit" >
                    <label for="prefered_product_key_pension_days">Clave de producto pensión</label>
                    <select id="prefered_product_key_pension_days" v-model="this.prefered_product_key_pension_days" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="72154503">72154503 - SERVICIO DE ALQUILER DE GRUAS</option><!--GRUA-->
                        <option value="78121603">78121603 - TARIFA DE LOS FLETES</option><!--KILOMETRAJE-->
                        <option value="90121800">90121800 - SERVICIO DE ASISTENCIA DE EMERGENCIA EN VIAJES</option> <!--PASO DE CORRIENTE-->
                        <option value="81141601">81141601 - LOGÍSTICA</option> <!--MANIOBRA . MONTACARGAS 15 MIL LBS<-->
                        <option value="78101803">78101803 - SERVICIO DE TRANSPORTE DE VEHÍCULOS</option> <!--TRAFICO-->
                        <option value="78181702">78181702 - DE ALMACENAJE DE VEHICULOS DE TRANSPORTE</option> 
                </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_measurement_unit_pension_days">Unidad de medida pensión</label>
                    <select id="prefered_measurement_unit_pension_days" v-model="this.prefered_measurement_unit_pension_days" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="ACT">ACT - TRAFICO</option>
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                    </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_product_key_traffic">Clave de producto tráfico</label>
                    <select id="prefered_product_key_traffic" v-model="this.prefered_product_key_traffic" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="72154503">72154503 - SERVICIO DE ALQUILER DE GRUAS</option><!--GRUA-->
                        <option value="78121603">78121603 - TARIFA DE LOS FLETES</option><!--KILOMETRAJE-->
                        <option value="90121800">90121800 - SERVICIO DE ASISTENCIA DE EMERGENCIA EN VIAJES</option> <!--PASO DE CORRIENTE-->
                        <option value="81141601">81141601 - LOGÍSTICA</option> <!--MANIOBRA . MONTACARGAS 15 MIL LBS<-->
                        <option value="78101803">78101803 - SERVICIO DE TRANSPORTE DE VEHÍCULOS</option> <!--TRAFICO-->
                        <option value="78181702">78181702 - DE ALMACENAJE DE VEHICULOS DE TRANSPORTE</option> 
                </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_measurement_unit_traffic">Unidad de medida tráfico</label>
                    <select id="prefered_measurement_unit_traffic" v-model="this.prefered_measurement_unit_traffic" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="ACT">ACT - TRAFICO</option>
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                    </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_product_key_cost_km">Clave de producto kilometraje</label>
                    <select id="prefered_product_key_cost_km" v-model="this.prefered_product_key_cost_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="72154503">72154503 - SERVICIO DE ALQUILER DE GRUAS</option><!--GRUA-->
                        <option value="78121603">78121603 - TARIFA DE LOS FLETES</option><!--KILOMETRAJE-->
                        <option value="90121800">90121800 - SERVICIO DE ASISTENCIA DE EMERGENCIA EN VIAJES</option> <!--PASO DE CORRIENTE-->
                        <option value="81141601">81141601 - LOGÍSTICA</option> <!--MANIOBRA . MONTACARGAS 15 MIL LBS<-->
                        <option value="78101803">78101803 - SERVICIO DE TRANSPORTE DE VEHÍCULOS</option> <!--TRAFICO-->
                        <option value="78181702">78181702 - DE ALMACENAJE DE VEHICULOS DE TRANSPORTE</option> 
                </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_measurement_unit_cost_km">Unidad de medida kilometraje</label>
                    <select id="prefered_measurement_unit_cost_km" v-model="this.prefered_measurement_unit_cost_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="ACT">ACT - TRAFICO</option>
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                    </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_product_key_maneuvers">Clave de producto maniobras</label>
                    <select id="prefered_product_key_maneuvers" v-model="this.prefered_product_key_maneuvers" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="72154503">72154503 - SERVICIO DE ALQUILER DE GRUAS</option><!--GRUA-->
                        <option value="78121603">78121603 - TARIFA DE LOS FLETES</option><!--KILOMETRAJE-->
                        <option value="90121800">90121800 - SERVICIO DE ASISTENCIA DE EMERGENCIA EN VIAJES</option> <!--PASO DE CORRIENTE-->
                        <option value="81141601">81141601 - LOGÍSTICA</option> <!--MANIOBRA . MONTACARGAS 15 MIL LBS<-->
                        <option value="78101803">78101803 - SERVICIO DE TRANSPORTE DE VEHÍCULOS</option> <!--TRAFICO-->
                        <option value="78181702">78181702 - DE ALMACENAJE DE VEHICULOS DE TRANSPORTE</option> 
                </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_measurement_unit_maneuvers">Unidad de medida maniobras</label>
                    <select id="prefered_measurement_unit_maneuvers" v-model="this.prefered_measurement_unit_maneuvers" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="ACT">ACT - TRAFICO</option>
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                    </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_product_key_service_hours">Clave de producto horas de servicio</label>
                    <select id="prefered_product_key_service_hours" v-model="this.prefered_product_key_service_hours" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="72154503">72154503 - SERVICIO DE ALQUILER DE GRUAS</option><!--GRUA-->
                        <option value="78121603">78121603 - TARIFA DE LOS FLETES</option><!--KILOMETRAJE-->
                        <option value="90121800">90121800 - SERVICIO DE ASISTENCIA DE EMERGENCIA EN VIAJES</option> <!--PASO DE CORRIENTE-->
                        <option value="81141601">81141601 - LOGÍSTICA</option> <!--MANIOBRA . MONTACARGAS 15 MIL LBS<-->
                        <option value="78101803">78101803 - SERVICIO DE TRANSPORTE DE VEHÍCULOS</option> <!--TRAFICO-->
                        <option value="78181702">78181702 - DE ALMACENAJE DE VEHICULOS DE TRANSPORTE</option> 
                </select> 
                </div>
                <div class="form-group" v-if="invoiceBreakdown && invoiceBreakdownEdit">
                    <label for="prefered_measurement_unit_service_hours">Unidad de medida horas de servicio</label>
                    <select id="prefered_measurement_unit_service_hours" v-model="this.prefered_measurement_unit_service_hours" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="ACT">ACT - TRAFICO</option>
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                    </select> 
                </div>
            </div>
            <div class="form-section">
                <h4>Datos de la cartaporte</h4>
                <div class="checkbox-group">
                    <input type="checkbox" id="toggleFields1" v-model="cartaporte" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <label for="toggleFields1">Generar carta porte</label>
                </div>
                <div class="form-group">
                    <label for="mercancia_descripcion">Descripción de mercancía</label>
                    <input type="text" id="mercancia_descripcion" placeholder="Ingrese la descripción de mercancía" v-model="localNoteDetails.mercancia_descripcion" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="mercancia_valor">Valor del vehículo</label>
                    <input type="text" id="mercancia_valor" placeholder="Ingrese el valor del vehículo" v-model="localNoteDetails.mercancia_valor" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="unit_inventory">UNIDADES DE MEDIDA DE LA MERCANCÍA</label>
                    <select id="unit_inventory" v-model="unit_inventory" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                    </select>
                </div>
            </div>
        </div>
        <button class="btn-submit-savebilling-edit-assistance" v-if="localNoteDetails.status === 2" @click="generateInvoiceDocument">Generar factura</button>
    </div>

    <div v-if="currentForm === 'images'">
    <div class="form-section">
      <h3>Evidencia del siniestro</h3>
      <img src="@/assets/images-section.png">
    </div>
    <div class="form-container">
      <div class="form-section-images" v-for="section in imageSections" :key="section.loc">
        <div class="form-group-images">
          <label>{{ section.label }}</label>
        </div>
        <div class="image-container" v-if="section.images.default">
          <img :src="section.images.default">
        </div>
        <div class="image-container-upload" v-for="(image, index) in section.images.uploaded" :key="index">
          <div class="image-wrapper">
            <img :src="image">
            <button class="delete-button" @click="deleteImage(section, index)">x</button>
          </div>
        </div>
        <button class="btn-submit-upload-images" @click="uploadFile(section)"> Subir imágen</button>
        <button class="btn-submit-download-images" @click="downloadAllImages(section)"> Descargar imágenes</button>
      </div>
    </div>
  </div>

    <button type="submit" class="btn-submit-save-edit-auxiliar" @click="saveNote" :disabled="blockEdit||!isFormValid||saving_note" :class="{ 'grayed-out-missing-input' : !isFormValid,'grayed-out': blockEdit,'saving-note':saving_note }">Guardar</button>
    <button type="button" class="btn-submit-back" onclick="history.back()">Regresar</button>
    <button type="submit" class="btn-submit-complete" @click="completeNote" v-if="localNoteDetails.status !== 4 && allTimesCompleted">
        Completar nota
    </button>
    <button type="submit" class="btn-submit-close" @click="cancelNote" v-if="localNoteDetails.status !== 2">Cancelar nota</button>
    <button type="submit" class="btn-submit-reOpen" @click="reOpenNote" v-if="localNoteDetails.status === 2 || localNoteDetails.status === 4">
        Reabrir nota
    </button>
</template>

<style scoped>
.muni-dropdown {
    position: absolute; /* Position relative to the parent container */
    top: 100%; /* Place it directly below the input field */
    left: 0; /* Align with the input field's left edge */
    z-index: 10000;
    width: 100%; /* Match the input field's width */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0; /* Remove any default padding */
    margin: 0; /* Remove the left margin */
}

.muni-dropdown li {
    padding: 8px 12px;
    cursor: pointer;
}

.muni-dropdown li:hover,
.muni-dropdown li.active {
    background-color: #007bff;
    color: #fff;
}
.dropdown {
    position: absolute;
    z-index: 10000;
    width: 15vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto; 
    margin-left: 20px;
    list-style: none;
}
.dropdown li {
    padding: 8px 12px;
    cursor: pointer;
}
.dropdown li.active {
    background-color: #007bff;
    color: #fff;
}
.main-data-container {
    display: flex;
    width: 100vw;
    height: auto;
    justify-content: space-between;
}
.client-data-container, .payment-data-container {
    width: 50vw;
    height: auto;
    padding: 10px 10px;
}
.tittle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #919191;
}
.tittle h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
}
.tittle img{
    display: flex;
    margin-left: 10px;
    width: 20px;
    height: 20px;
}
.client-container {
    width: 50vw;
}
.data-client {
    width: 50vw;
    height: auto;
}
.data-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.data-container .state-dropdown {
    z-index: 1000;
    width: 7vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto;
    position: relative;
}

.state-dropdown option, select {
    font-size: .9rem;
}
.buttons-for-saveClient {
    width: 50vw;
    height: auto;
}

.table-footer {
    display: flex;
    width: 99vw;
    height: auto;
    justify-content: space-between;
    margin: 10px;
}
.table-footer th {
    color: #000000;
    background-color: #5388b4;
}
.table-footer table {
    width: 50vw;
}
.checkbox-container {
    display: flex;
    align-items: center; /* Align items vertically */
    text-align: left; 
    font-weight: bold;
    width: 10vw;
    margin-left: 5px;
}
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.create-order{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-top: 10vh; 
    height: 5vh;
    border-bottom: 1px solid black;
    margin-left: 20px;
    margin-right: 10px;
}
.create-order button {
    padding: 5px;
    background-color: rgb(0, 17, 255);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.form-group {
    margin-bottom: 15px;
    width: 10vw;
}
.form-group1 {
    display: flex;
    width: 10vw;
    margin-left: 5px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
}
.form-group1 label {
    display: block;
    font-size: .7rem;
    color: #000000;
    width: 5vw;
}
.checkbox-group label {
    display: block;
    font-size: .7rem;
}
.checkbox-container label {
    display: block;
    font-size: .7rem;
}
input[type="text1"],
input[type="tel1"] {
    width: 7vw;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: .7rem;
    margin: 0;
    padding: 4px;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="text"],
input[type="tel"] {
    width: 20vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-submit-save {
    background-color: #00ff1e; /* Color del botón de enviar */
    color: #fff; /* Color del texto del botón */
    border: none;
    padding: 10px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-cancel {
    background-color: #ff0000; /* Color del botón de enviar */
    color: #fff; /* Color del texto del botón */
    border: none;
    padding: 10px 10px;
    border-radius: 4px;
    cursor: pointer;
}
.form-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}

.form-section, .form-section-images{
    flex-basis: calc(33% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 10px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
}

.form-section-images img{
    width: 100px;
    height: 100px;
}

.image-container {
    margin-top: 10vh;
    width: 100%;
    height: 200px;
}

.image-container-upload {
    margin-top: 2vh;
    margin-bottom: 3vh;
    width: 100%;
    height: 200px;
}

.form-group-images{
    height: 8vh;
}

.image-container-upload img{
    width: 200px !important;
    height: 200px !important;
}


.form-section:last-child {
    margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
}

h2 {
    margin-bottom: 20px; /* Espacio debajo de los títulos de sección */
}
.form-group label {
    text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
    font-weight: bold; /* Hace que el texto de la etiqueta sea negrita */
    display: inline-block; /* Hace que la etiqueta sea en bloque pero alinee su contenido */
    width: 100%; /* Asegura que la etiqueta ocupe todo el ancho disponible */
}
.form-section img{
    width: 40px;
    height: 40px;
}

.map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    padding: 20px;
}
.map {
    width: 40vw; /* Ancho inicial para pantallas grandes */
    height: 50vh; /* Altura inicial para pantallas grandes */
}


.report-map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    padding: 20px;
}
.report-map {
    width: 40vw; /* Ancho inicial para pantallas grandes */
    height: 50vh; /* Altura inicial para pantallas grandes */
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

th {
  background-color: #000000;
  color: #FFC434;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}

button {
    color: #ffffff;
    background-color: #FFC434;
    border: none;
    border-radius: 5px;
  /* Añadir estilos al botón si es necesario */
}
.void{
    height: 30px;
}
.time-data{
    display: grid;
    place-items: center;
}
textarea {
    width: 15vw; 
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical; 
}
.checkbox-group {
    display: flex;
    justify-content: flex-start; 
    gap: 5px; 
    font-weight: bold;
    width: 10vw;
    margin-left: 5px;   
}
.button-delete-operator{
    background-color: #ff0000;
    color: white;
    margin-left: 3vw;
}
.client-container .client-dropdown {
    position: absolute;
    z-index: 10000;
    width: 15vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto; 
    margin-left: 20px;
}
.form-group .client-dropdown {
    position: absolute;
    z-index: 1000;
    width: 20vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto; 
}
.client-dropdown div {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}
.client-dropdown div:hover {
    background-color: #f2f2f2;
}
.form-group select {
    width: 20vw; 
    padding: 8px; 
    margin-bottom: 10px; 
    border: 1px solid #ccc;
    border-radius: 4px; 
    background-color: white; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); 
    background-repeat: no-repeat;
    background-position: right 8px top 50%; 
    background-size: 12px; 
}
.form-group-final{
    margin-bottom: 2vh;
    border-bottom: 1px solid black;
}
.grayed-out {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
}
.grayed-out-missing-input {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.grayed-out-missing-input::after {
    content: "Los campos: Cliente, Marca, Submarca, Latitud y Longitud de origen, Latitud y Longitud de destino son obligatorios"; /* Text to show on mouse hover */
    position: absolute;
    top: -50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease;
}

.grayed-out-missing-input:hover::after {
    opacity: 1; /* Show the text on hover */
}
.saving-note {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.saving-note::after {
    content: "Espere a que se guarde la nota"; /* Text to show on mouse hover */
    position: absolute;
    top: -50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease;
}

.saving-note:hover::after {
    opacity: 1; /* Show the text on hover */
}
.text-color-for-date{
    color: rgb(21, 0, 255);
}
.btn-submit-save-edit-assistance {
    background-color: #00ff1e; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-savebilling-edit-assistance{
    background-color: #5d5d5d; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
}
.btn-submit-back {
    background-color: #ff7700; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-complete {
    background-color: #00eeff; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-close {
    background-color: #ff0000; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-reOpen {
    background-color: #0000ff; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.btn-submit-upload-images{
    background-color: #b1d0ff; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-submit-download-images{
    margin-top: 2vh;
    background-color: #1d0061; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.form-section-images {
  margin-bottom: 20px;
  clear: both;
}

.form-group-images {
  padding-bottom: 40px; /* Increased padding to ensure clear separation */
}

.image-container-upload {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.image-wrapper {
  position: relative;
  display: inline-block;
  margin: 10px; /* Ensure separation between images */
  vertical-align: top;
}

.image-wrapper img {
  display: block;
  max-width: 100%; /* Ensure the image doesn't overflow its container */
  border: 1px solid #ddd; /* Optional: add border for better visibility */
  border-radius: 4px; /* Optional: add border radius for better aesthetics */
  padding: 5px; /* Optional: add padding for better spacing */
}

.btn-submit-save-edit-auxiliar {
    background-color: #00ff1e; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}

.btn-submit-savebilling-edit-auxiliar{
    background-color: #5d5d5d; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
}
.btn-submit-savebilling-edit-assistance{
    background-color: #5d5d5d; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
}
.btn-submit-back {
    background-color: #ff7700; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-complete {
    background-color: #00eeff; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-close {
    background-color: #ff0000; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-reOpen {
    background-color: #0000ff; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.btn-submit-upload-images{
    background-color: #b1d0ff; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-submit-download-images{
    margin-top: 2vh;
    background-color: #1d0061; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

</style>

<script>
import { ref, reactive, onMounted, nextTick, computed } from 'vue'
import { Map, Marker, InfoWindow } from '@fawmi/vue-google-maps'
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    props: ['noteDetails', 'assignations','user_id'],
    components: {
        Map,
        Marker,
        InfoWindow
    },
    data() {
        const locations = ref([])
        const mapCenter = ref({ lat: 25.66831199290133, lng: -100.2802354639708 })
        const infoWindowOpened = ref(false);
        const infoContent = ref('');
        const infoPosition = reactive({ lat: 0, lng: 0 });
        const redMarkerPosition = { lat: 25.689214, lng: -100.314343 }; // Red marker position DESTINATION
        const showRedMarker = ref(true); // Variable to control the red marker visibility

        // Vector of vehicle names for green markers
        const greenVehicleNames = ref(['254']); // Example vehicle names

        const fetchLocations = () => {
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/fetch-last-vehicle-locations`)
                .then(response => {
                    locations.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        }

        onMounted(() => {
            fetchLocations();
            setInterval(fetchLocations, 20000);
        })

        const openInfoWindow = (location) => {
            infoWindowOpened.value = false;
            infoContent.value = `
                <div>
                <h1>${location.vehicle_name}</h1>
                <p>Placas: ${location.vehicle_plate}</p>
                <p>Velocidad Actual: ${location.vehicle_speed} km/h</p>
                </div>
            `;
            infoPosition.lat = parseFloat(location.lat);
            infoPosition.lng = parseFloat(location.long);
            nextTick(() => {
                infoWindowOpened.value = true;
            });
        };

        // Computed property for filtering blue locations
        const filteredBlueLocations = computed(() => {
            return locations.value.filter(location => !this.greenVehicleNames.value.includes(location.vehicle_name));
        });

        // Computed property for filtering green locations
        const filteredGreenLocations = computed(() => {
            return locations.value.filter(location => this.greenVehicleNames.value.includes(location.vehicle_name));
        });

        return {
            imageSections: [
                { loc: 'front', label: 'Fotografías de la parte de enfrente del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'engine', label: 'Fotografías del motor del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'left', label: 'Fotografías del lado izquierdo del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'right', label: 'Fotografías del lado derecho del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'back', label: 'Fotografías de la parte trasera del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'complementary', label: 'Fotografías complementarias del vehículo (Llantas, Cajuela, interior del vehículo, etc.)', images: { default: '', uploaded: [], blob_ids: [] } },
            ],
            buttonClicked: {
                btn1: false,
                btn2: false,
                btn3: false,
                btn4: false,
                btn5: false,
                images_tab : false
            },
            blockEdit: false, //Linked to the note status, determines wheter the fields are enabled or not 
            localNoteDetails: {},
            greenVehicleNames,
            filteredBlueLocations,
            filteredGreenLocations,
            mapCenter,
            infoWindowOpened,
            infoContent,
            infoPosition,
            openInfoWindow,
            redMarkerPosition,
            showRedMarker,
            client_name: this.noteDetails.client,
            vehicle_brand: this.noteDetails.vehicle_brand,
            vehicle_sub_brand: this.noteDetails.vehicle_sub_brand,
            brands: [],
            sub_brands: [],
            subBrandLoading: true,
            // Since the user is encouraged to not fill this field if they dont know it, handle as needed
            vehicle_model: this.noteDetails.vehicle_model != null? this.noteDetails.vehicle_model.toString(): null,
            vehicle_plates: this.noteDetails.vehicle_plates,
            vehicle_engine_series: this.noteDetails.vehicle_engine_series,
            vehicle_color: this.noteDetails.vehicle_color,
            vehicle_color2: this.noteDetails.vehicle_color2,
            insurance_policy: this.noteDetails.insurance_policy,          
            vehicle_owner_name: this.noteDetails.vehicle_owner_name,
            file_number: this.noteDetails.file_number,
            aux_requested_service: this.noteDetails.aux_requested_service,
            source_street: this.noteDetails.source.street_name,
            source_between_streets: this.noteDetails.source.between_streets,
            source_neighborhood: this.noteDetails.source.neighborhood,
            // source_street_number: this.noteDetails.source.number,
            source_city: this.noteDetails.source.city,
            source_zip_code: this.noteDetails.source.zip_code,
            source_state: this.noteDetails.source.state,
            source_lat: this.noteDetails.source.lat,
            source_lon: this.noteDetails.source.lon,
            // not all services need source and destination km. Check the current value and adjust the display accordingly
            source_km: this.noteDetails.source_km != null ? this.noteDetails.source_km.toString() : null,
            source_references: this.noteDetails.source_notes,
            destination_street: this.noteDetails.destination.street_name,
            destination_between_streets: this.noteDetails.destination.between_streets,
            destination_neighborhood: this.noteDetails.destination.neighborhood,
            // destination_street_number: this.noteDetails.destination.number,
            destination_city: this.noteDetails.destination.city,
            destination_zip_code: this.noteDetails.destination.zip_code,
            destination_state: this.noteDetails.destination.state,
            destination_lat: this.noteDetails.destination.lat,
            destination_lon: this.noteDetails.destination.lon,
            // not all services need source and destination km. Check the current value and adjust the display accordingly
            destination_km: this.noteDetails.destination_km != null ? this.noteDetails.destination_km.toString() : null,
            destination_references: this.noteDetails.destination_notes,
            cost_by_km: this.noteDetails.cost_by_km,
            total_kms: this.noteDetails.total_kms,
            maneuvers: this.noteDetails.maneuvers,
            highway_tolls: this.noteDetails.highway_tolls,
            subtotal: this.noteDetails.subtotal,
            iva: this.noteDetails.iva,
            retention: this.noteDetails.retention,
            total: this.noteDetails.total,
            // total_ovr: this.noteDetails.total_ovr,
            payment_method: this.noteDetails.payment_method,
            internal_payment_type: this.noteDetails.internal_payment_type,
            payment_type: this.noteDetails.payment_type,
            invoice_use: this.noteDetails.invoice_use,
            payment_notes: this.noteDetails.payment_notes,
            approved_by: this.noteDetails.approved_by,
            expected_time: this.noteDetails.expected_time,
            sucursal: this.noteDetails.sucursal,
            cost_by_traffic: this.cost_by_traffic,
            note_number: this.noteDetails.note_number,
            // metodo_pago: this.noteDetails.metodo_pago,
            // forma_pago: this.noteDetails.forma_pago,
            // clave_prod_serv: this.noteDetails.clave_prod_serv,
            // concepto_descripcion: this.noteDetails.concepto_descripcion,
            mercancia_descripcion: this.noteDetails.mercancia_descripcion,
            mercancia_valor: this.noteDetails.mercancia_valor,
            // mercancia_unidad: this.noteDetails.mercancia_unidad,
            // asegura_resp_civil: this.noteDetails.asegura_resp_civil,
            // poliza_resp_civil: this.noteDetails.poliza_resp_civil,
            // remolque_tipo: this.noteDetails.remolque_tipo,
            currentForm: 'initialData',
            client_state: '',
            allStates: [
                { name: 'Aguascalientes' },
                { name: 'Baja California' },
                { name: 'Baja California Sur' },
                { name: 'Campeche' },
                { name: 'Chiapas' },
                { name: 'Chihuahua' },
                { name: 'Ciudad de México' },
                { name: 'Coahuila' },
                { name: 'Colima' },
                { name: 'Durango' },
                { name: 'Estado de México' },
                { name: 'Guanajuato' },
                { name: 'Guerrero' },
                { name: 'Hidalgo' },
                { name: 'Jalisco' },
                { name: 'Michoacán' },
                { name: 'Morelos' },
                { name: 'Nayarit' },
                { name: 'Nuevo León' },
                { name: 'Oaxaca' },
                { name: 'Puebla' },
                { name: 'Querétaro' },
                { name: 'Quintana Roo' },
                { name: 'San Luis Potosí' },
                { name: 'Sinaloa' },
                { name: 'Sonora' },
                { name: 'Tabasco' },
                { name: 'Tamaulipas' },
                { name: 'Tlaxcala' },
                { name: 'Veracruz' },
                { name: 'Yucatán' },
                { name: 'Zacatecas' }
            ],
            filteredClientStates: [],
            filteredSourceStates: [],
            filteredDestinationStates: [],
            client_address: '',
            client_rfc: '',
            phone:'',
            addNewClient: false,
            clients: [], 
            filteredClients: [], 
            clientDetails: {
                "client_address": {
                    
                }
            }, 
            isClientSelected: false,
            showOptionalFields: false,
            showOptionalFields1: false,
            gruposCampos: [ 
            { unit: '', operator: '', start_km: null, end_km: null, assistance_note: this.noteDetails.id, assigned_by: this.user_id},
            ],
            assignationsData: [],
            saving_note: false,
            billingInfo: {},
            cartaporte: false,
            prefered_product_key_traffic: '78101803',
            prefered_measurement_unit_traffic: 'ACT',
            prefered_product_key_pension_days: '78181702',
            prefered_measurement_unit_pension_days: 'ACT',
            prefered_product_key_cost_km: '78121603',
            prefered_measurement_unit_cost_km: 'ACT',
            prefered_product_key_maneuvers: '81141601',
            prefered_measurement_unit_maneuvers: 'ACT',
            prefered_product_key_service_hours: '78101803',
            prefered_measurement_unit_service_hours: 'ACT',
            invoiceBreakdown: false,
            invoiceBreakdownEdit: false,
            applies_retention: false,
            selectedClient: null, 
            highlightedIndex: -1,
            municipalities: [
                "Monterrey", "San Nicolás de los Garza", "San Pedro Garza García",
                "Guadalupe", "Apodaca", "Escobedo", "Santa Catarina", "Juárez", "García",
                "Pesquería", "Santiago", "El Carmen", "Cadereyta"
            ],
            sourceFilteredMunicipalities: [],
            destinationFilteredMunicipalities: [],
        };
    },
    
    watch: {
        assignations: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (Array.isArray(newVal) && newVal.length > 0) {
                    this.gruposCampos = newVal.map(assignation => ({
                        id: assignation.id,
                        unit: assignation.unit.unit_short_id,
                        operator: assignation.operator.employee_ident,
                        assigned_by: assignation.assigned_by.employee_user,
                        start_km: assignation.start_km,
                        end_km: assignation.end_km,
                        assistance_note: assignation.assistance_note.id,
                        operador_name: assignation.operator.employee_first_name,
                        operador_last_name: assignation.operator.employee_last_name,
                        unidad_name: assignation.unit.unit_sub_brand
                    }));
                } 
        },
        client_state(newValue) {
            this.filteredClientStates = this.getFilteredStates(newValue);
        },
        source_state(newValue) {
            this.filteredSourceStates = this.getFilteredStates(newValue);
        },
        destination_state(newValue) {
            this.filteredDestinationStates = this.getFilteredStates(newValue);
        },
        'localNoteDetails.note_ident': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchAssignationsForNote();
            }
        }
        },
        'localNoteDetails.cost_by_km': 'updateCalculatedCosts',
        'localNoteDetails.total_kms': 'updateCalculatedCosts',
        'localNoteDetails.cost_by_traffic': 'updateCalculatedCosts',
        'localNoteDetails.maneuvers': 'updateCalculatedCosts',
        'localNoteDetails.highway_tolls': 'updateCalculatedCosts',
    },
    mounted() {
        this.fetchAssignationsForNote();
    },
    computed: {
        allTimesCompleted() {
            if (!Array.isArray(this.assignationsData) || this.assignationsData.length === 0) {
                return false;
            }
            return this.assignationsData.every(assignation =>
                assignation.start_time && assignation.arrival_time &&
                assignation.contact_time && assignation.end_time
            );
        },
        isFormValid() {
            /** Used to prevent the form submission until all required values are set */
            return (
                true
                // sub brand, client, and lat lon are required 
                // check all required fields contain data before allowing update of the note
                //Client name being null/empty implies there is nothing in the client field, thus form cannot be valid in this state 
                // this.client_name != null &&  this.client_name != "" &&
                // this.localNoteDetails.vehicle_brand != null && this.localNoteDetails.vehicle_brand != "" &&
                // this.localNoteDetails.vehicle_sub_brand != null && this.localNoteDetails.vehicle_sub_brand != "" &&
                // this.localNoteDetails.source.lat != "" &&  this.localNoteDetails.source.lat !== null &&
                // //this.localNoteDetails.source.lon != "" &&  this.localNoteDetails.source.lat !== null &&
                // this.localNoteDetails.destination.lat !== "" && this.localNoteDetails.destination.lat != null &&
                // this.localNoteDetails.destination.lon !== "" && this.localNoteDetails.destination.lon != null
            );
        },
        selectedBrandId() {
            // Find the ID (pk) for the selected brand name
            const brandEntry = Object.entries(this.brands).find(
            ([, brand]) => brand === this.localNoteDetails.vehicle_brand
            );
            return brandEntry ? brandEntry[0] : null;  // Return the ID or null if not found
        },
        selectedSubBrandId() {
            // Find the ID that matches the selected sub-brand name
            const subBrandEntry = Object.entries(this.sub_brands).find(
            ([, sub_brand]) => sub_brand === this.localNoteDetails.vehicle_sub_brand
            );
            return subBrandEntry ? subBrandEntry[0] : null;  // Returns the ID or null if not found
        },
    },
    methods: {
        sourceFilterMunicipalities() {
            const searchTerm = this.localNoteDetails.source?.city?.toLowerCase() || '';
            this.sourceFilteredMunicipalities = this.municipalities.filter(municipality =>
                municipality.toLowerCase().includes(searchTerm)
            );
        },
        sourceSelectMunicipality(municipality) {
            this.localNoteDetails.source.city = municipality;
            this.sourceFilteredMunicipalities = [];
            console.log(this.localNoteDetails.source.city);
        },
        destinationFilterMunicipalities() {
            const searchTerm = this.localNoteDetails.destination?.city?.toLowerCase() || '';
            this.destinationFilteredMunicipalities = this.municipalities.filter(municipality =>
                municipality.toLowerCase().includes(searchTerm)
            );
        },
        destinationSelectMunicipality(municipality) {
            this.localNoteDetails.destination.city = municipality;
            this.destinationFilteredMunicipalities = [];
        },
        navigateClients(direction) {
            if (!this.filteredClients.length) return;

            const count = this.filteredClients.length;
            this.highlightedIndex = (this.highlightedIndex + direction + count) % count;

            // Scroll the highlighted item into view
            this.$nextTick(() => {
                const dropdown = this.$refs.dropdownContainer;
                const highlightedItem = this.$refs.dropdownItem[this.highlightedIndex];
                if (dropdown && highlightedItem) {
                    const itemOffsetTop = highlightedItem.offsetTop;
                    const itemHeight = highlightedItem.offsetHeight;
                    const dropdownScrollTop = dropdown.scrollTop;
                    const dropdownHeight = dropdown.offsetHeight;

                    if (itemOffsetTop < dropdownScrollTop) {
                        dropdown.scrollTop = itemOffsetTop;
                    } else if (itemOffsetTop + itemHeight > dropdownScrollTop + dropdownHeight) {
                        dropdown.scrollTop = itemOffsetTop - dropdownHeight + itemHeight;
                    }
                }
            });
        },
        selectHighlightedClient() {
            if (this.highlightedIndex !== -1) {
                this.selectClient(this.filteredClients[this.highlightedIndex]);
            }
        },
        updateCalculatedCosts() {
            this.localNoteDetails.subtotal = (
                (Number(this.localNoteDetails.cost_by_km) * Number(this.localNoteDetails.total_kms) || 0) +
                (Number(this.localNoteDetails.cost_by_traffic) || 0) +
                (Number(this.localNoteDetails.maneuvers) || 0) +
                (Number(this.localNoteDetails.highway_tolls) || 0)
            ).toFixed(2); // Ensures the result is a string formatted to two decimal places
            this.localNoteDetails.iva = (
                (this.localNoteDetails.subtotal) * .16
            ).toFixed(2);
            this.localNoteDetails.retention = (
                (this.localNoteDetails.subtotal) * (this.applies_retention ? .04 : 0)
            ).toFixed(2);
            this.localNoteDetails.total = (
                Number(this.localNoteDetails.subtotal) + Number(this.localNoteDetails.iva) - Number(this.localNoteDetails.retention)
            ).toFixed(2);
        },
        async fetchClientById(clientId) {
            try {
                const apiDirectory = process.env.API_DIRECTORY; // Adjust to your actual API endpoint
                const response = await axios.get(`${apiDirectory}/clients/${clientId}/`);
                this.client_id = clientId; // Keep the client ID in sync
                this.client_name = response.data.client_name; // Update the display name
                if (response.data.prefered_product_key_pension_days) {
                    this.prefered_product_key_pension_days = response.data.prefered_product_key_pension_days;
                }
                if (response.data.prefered_measurement_unit_pension_days) {
                    this.prefered_measurement_unit_pension_days = response.data.prefered_measurement_unit_pension_days;
                }
                if (response.data.prefered_product_key_traffic) {
                    this.prefered_product_key_traffic = response.data.prefered_product_key_traffic;
                }
                if (response.data.prefered_measurement_unit_traffic) {
                    this.prefered_measurement_unit_traffic = response.data.prefered_measurement_unit_traffic;
                }
                if (response.data.prefered_product_key_cost_km) {
                    this.prefered_product_key_cost_km = response.data.prefered_product_key_cost_km;
                }
                if (response.data.prefered_measurement_unit_cost_km) {
                    this.prefered_measurement_unit_cost_km = response.data.prefered_measurement_unit_cost_km;
                }
                if (response.data.prefered_product_key_maneuvers) {
                    this.prefered_product_key_maneuvers = response.data.prefered_product_key_maneuvers;
                }
                if (response.data.prefered_measurement_unit_maneuvers) {
                    this.prefered_measurement_unit_maneuvers = response.data.prefered_measurement_unit_maneuvers;
                }
                if (response.data.prefered_product_key_service_hours) {
                    this.prefered_product_key_service_hours = response.data.prefered_product_key_service_hours;
                }
                if (response.data.prefered_measurement_unit_service_hours) {
                    this.prefered_measurement_unit_service_hours = response.data.prefered_measurement_unit_service_hours;
                }
                this.applies_retention = false;
                if (response.data.applies_retention) {
                    this.applies_retention = response.data.applies_retention;
                }
                // No need to update localNoteDetails.client here, as it should already have the correct ID
                // console.log(this.client_name)
            } catch (error) {
                console.error('Error fetching client details:', error);
            }
        },
        async fetchClients() {
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-privileged-clients`);
                this.clients = response.data;
            } catch (error) {
                // error
            }
        },
        filterClients() {
            const searchTerm = this.client_name.toLowerCase();
            if (searchTerm === '') {
                this.filteredClients = [];
                this.highlightedIndex = -1; // Reset the highlighted index
                return;
            }

            this.filteredClients = this.clients.filter(client =>
                client.client_name.toLowerCase().includes(searchTerm)
            );
            this.highlightedIndex = -1; // Reset the highlighted index when filtering
        },
        selectClient(client) {
            this.clientDetails = { ...client };
            this.client_name = client.client_name;
            this.client_id = client.id;
            this.localNoteDetails.client = this.client_id;
            this.filteredClients = [];
            this.isClientSelected = true;
            this.applies_retention = false;
            this.highlightedIndex = -1;
            if (client.applies_retention) {
                    this.applies_retention = client.applies_retention;
            }
        },
        toggleNewClientForm(){
            this.addNewClient = true;
            if (this.addNewClient) {
                this.isClientSelected = false;
            }
        },
        cancelNewClient() {
            this.addNewClient = false; 
        },
        closeDropdown() {
            this.filteredClients = [];
            this.sourceFilteredMunicipalities = [];
            this.destinationFilteredMunicipalities = [];
            this.highlightedIndex = -1;
        },
        filterStates() {
            if (!this.searchState) {
                this.filteredStates = this.states;
            } else {
                this.filteredStates = this.states.filter((state) =>
                    state.toLowerCase().includes(this.searchState.toLowerCase())
            );
            }
            this.isStateDropdownActive = true;
        },
        getFilteredStates(searchValue) {
            if (searchValue.length < 0) {
                return [];
            }
            return this.allStates.filter(state =>
                state.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        },
        selectClientState(name) {
            this.client_state = name;
            this.filteredClientStates = [];
        },
        selectSourceState(name){
            this.source_state = name;
            this.filteredSourceStates = [];
        },
        selectDestinationState(name){
            this.destination_state = name;
            this.filteredDestinationStates = [];
        },
        agregarCampos() {
            this.gruposCampos.push({ unit: '', operator: '', start_km: 0, end_km: 0, assistance_note: this.noteDetails.id, assigned_by: this.user_id});
        },
        async guardarDatos() {
            const apiDirectory = process.env.API_DIRECTORY;
            //Call the unit assignations endpoint to check if any of the units that is about to be assigned is in hard busy state
            let units = [];
            let response;
            const busyUnits = {};
            for (let obj of this.gruposCampos) {
                let unitValue = obj['unit'];
                units.push({ 'unit': unitValue });
            }
            console.log(units)
            try {
                response = await axios.post(`${apiDirectory}/get-unit-assignations`, units);
                // This iteration ensures even if the response contains duplicates, they are removed 
                response.data.forEach(item => {
                    if (item.status === "Ocupada") {
                        busyUnits[item.unit_short_id] = item.status;
                    }
                });
            }
            catch (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Al menos una de las unidades que proporcionó no existen.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                } else if (error.response && error.response.status === 400) {
                    console.error("Bad request", error)
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Por favor intentelo de nuevo. Si el error persiste, contacte a su administrador.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                } else if (error.response && error.response.status === 500) {
                    console.error("Internal server error", error)
                    Swal.fire({
                        title: '¡Error interno del servidor!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Por favor intentelo de nuevo. Si el error persiste, contacte a su administrador.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                } else {
                    console.error("Uncaught error", error)
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Verifique que la información que introdujo sea válida',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                }
            }
            // After the unit status have been succesfully fetched, warn the user if there is a hard busy unit, if its not the case, or they 
            // ack the warning, continue, else stop
            if (Object.keys(busyUnits).length > 0) {
                // Construct message for SweetAlert
                let message = "Al menos una de las unidades que está asignando ya se encuentra ocupada.\n\n";
                message += "Unidades Ocupadas:\n";
                for (const unitId in busyUnits) {
                    message += `${unitId}\n`;
                }
                const result = await Swal.fire({
                    title: "¡Advertencia!",
                    text: message,
                    icon: "warning",
                    showCancelButton: true,
                    buttons: {
                        cancel: "Cancelar",
                        confirm: "Continuar"
                    }
                });
                if (!result.isConfirmed) return
            }
            try {
                // Update note status to 5
                const noteID = this.localNoteDetails.note_ident;
                this.localNoteDetails.status = 5;
                this.sanitizeForm();
                await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
            }
            // Update the note status
            catch (error) {
                console.error(error)
                Swal.fire({
                    title: '¡Error!',
                    text: 'Hubo un error al actualizar el estatus de la nota, Por favor intente de nuevo.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        return
                    }
                });
                // Even if the user ignores the popup, exit out of the func
                return
            }
            // Update the note assignations    
            try {
                if (Object.keys(this.assignations).length === 0) {
                    // If assignations is empty, use POST method
                    response = await axios.post(`${apiDirectory}/set-note-assignations`, this.gruposCampos);
                } else {
                    // If assignations is not empty, use PUT method
                    response = await axios.put(`${apiDirectory}/set-note-assignations`, this.gruposCampos);
                }
                Swal.fire({
                    title: '¡Actualizado!',
                    text: 'Las asignaciones se han actualizado con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(); // Reload the page after 'Ok' is clicked
                    }
                });
            } catch (error) {
                // Check if the error is due to DNE 
                if (error.response && error.response.status === 404) {
                    console.error("Not found", error)
                    Swal.fire(
                        '¡Error!',
                        'Verifique el id de las unidades y operadores que intenta asignar. Si esta unidad existía en el pasado, asegurese de que no ha sido eliminada.',
                        'error'
                    );
                } else if (error.response && error.response.status === 400) {
                    console.error("Bad request", error)
                    Swal.fire(
                        '¡Error!',
                        'Verifique que los valores que intenta asignar son correctos.',
                        'error'
                    );
                } else {
                    console.error("Failed to update assignation:", error);
                    Swal.fire(
                        '¡Error!',
                        'Hay errores en las asignaciones',
                        'error'
                    );
                }
            }
        },
        seleccionarOpcion(unit){
            var n = this.assignations.length;
            if(n>0){
                this.agregarCampos()
                n = n+1;
            }
            else{
                n = 1;
            }
            this.gruposCampos[n-1].unit = unit
            this.buttonClicked['btn1'] = true;
            this.buttonClicked['btn2'] = true;
            this.buttonClicked['btn3'] = true;
            this.buttonClicked['btn4'] = true;
            this.buttonClicked['btn5'] = true;
        },
        async eliminarCampos(index = this.gruposCampos.length - 1) {
            const apiDirectory = process.env.API_DIRECTORY;

            if (this.gruposCampos.length > 1) {
                const assignationToDelete = this.gruposCampos[index];

                try {
                // Make the API call for the specific assignation
                await axios.post(`${apiDirectory}/delete-note-assignations`, assignationToDelete);

                // Remove the row from the gruposCampos array
                this.gruposCampos.splice(index, 1);

                Swal.fire({
                    title: '¡Actualizado!',
                    text: 'Las asignaciones se han actualizado con éxito',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                    location.reload(); // Reload the page after 'Ok' is clicked
                    }
                });
                } catch (error) {
                console.error(error);
                Swal.fire({
                    title: 'Error',
                    text: 'Hubo un problema al eliminar la asignación. Por favor, inténtalo de nuevo.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                }
            } else {
                Swal.fire('Error', 'Debe haber al menos un grupo de campos.', 'error');
            }
        },
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        showForm(formName) {
            // To save unnecesary calls to azure, as tradeoff images are loaded on demand 
            if (formName=='images'&this.buttonClicked.images_tab==false){
                this.fetchImages();
            }
            this.currentForm = formName;
        },
        saveNote: async function() {
            try {
                const noteID = this.noteDetails.note_ident;
                const apiDirectory = process.env.API_DIRECTORY;
                // Handle the changes of the destination for the source as they are the same on this type of note
                this.localNoteDetails.source = this.localNoteDetails.destination;
                this.sanitizeForm();
                this.saving_note = true;
                await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                Swal.fire(
                    '¡Actualizado!',
                    'Los datos del formulario se han actualizado con éxito.',
                    'success'
                )
                this.saving_note = false;
            } catch (error) {
                // Polygon condition and bad serializer cases
                console.error(error)
                this.saving_note = false;
                if (error.response.status == 400) {
                    let isPolygon = false
                    if (error.response.data) {
                        const errorDataString = JSON.stringify(error.response.data);
                        try { isPolygon = errorDataString.includes("Mexico") }                        
                        catch { isPolygon = false }
                    }
                    if (isPolygon) {
                        Swal.fire('Error', 'Verifique que la latitud y longitud sean válidas. \nLos datos que intentó ingresar no corresponden a una dirección dentro de México.', 'error');
                    }
                    else {
                        Swal.fire('Error', 'Verifique que los datos que está ingresando son válidos', 'error');
                    }
                }
                else {
                    Swal.fire('Error', 'Hubo un problema al actualizar los datos de la nota.', 'error');
                }
            }
        },
        completeNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.status = 4; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while completing the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Completado!',
                    text: 'La nota se ha completado con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al completar la nota.', 'error');
            }
        },
        reOpenNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.status = 1; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while reopening the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Reabierto!',
                    text: 'La nota se ha reabierto con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al reabrir la nota.', 'error');
            }
        },
        cancelNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.status = 2; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while closing the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Cancelado!',
                    text: 'La nota y sus asignaciones se han cancelado con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al cancelar la nota.', 'error');
            }
        },
        saveBilling() {
            // Generate sign and stamp invoice
            // const firstAssignation = this.assignationsData[0];
            // console.log(firstAssignation);
            const noteID = this.localNoteDetails.note_ident; 
            const vectorNoteID = this.localNoteDetails.note_ident.split('-');
            const apiDirectory = process.env.API_DIRECTORY;
            const dateDifferenceDays = Math.ceil(Math.ceil(Math.abs(new Date(this.localNoteDetails.lot_end_time) - new Date(this.localNoteDetails.lot_start_time)) / (1000 * 60 * 60)) / 24);
            const productDescriptions = {
                '78101803': "TRAFICO",
                '72154503': 'SERVICIO DE GRUA',
                '78121603': 'KILOMETRAJE',
                '90121800': 'ASISTENCIA DE EMERGENCIA',
                '81141601': 'MONTACARGAS 15 MIL LBS',
                '78181702': 'PENSION',
                // Add more keys as needed
            };
            const retention_perc = this.applies_retention ? 0.04 : 0.00;
            console.log(retention_perc);
            // const subbrandinfo = axios.post(`${apiDirectory}/get-sub-brand`, {"sub_brand_id": firstAssignation.assistance_note.vehicle_sub_brand});
            // console.log(subbrandinfo);
            const distTime = axios.post(`${apiDirectory}/get-distance-and-time`, {"org_lat": this.localNoteDetails.source.lat,
                                                                        "org_lng": this.localNoteDetails.source.lon,
                                                                        "dest_lat": this.localNoteDetails.destination.lat,
                                                                        "dest_lng": this.localNoteDetails.destination.lon});
            distTime.then(response => {
                const distance = response.data.distance;
                this.distance = distance;});
            return axios.get(`${apiDirectory}/get-billing-info/${noteID}/`)
                .then(response => {
                    // console.log(response.data['client']['tax_regime']);

                    try{
            let conceptos;
            // console.log(this.invoiceBreakdown);
                if (this.invoiceBreakdown === false){
                    conceptos = { 
                        //Custom product key
                        1:{
                        clave_prod_serv: this.prefered_product_key_traffic,
                        cantidad:'1',
                        clave_unidad: this.prefered_measurement_unit_traffic,    
                        descripcion: productDescriptions[this.prefered_product_key_traffic],
                        valor_unitario: response.data['subtotal'],
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: response.data['iva'],
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: Number(response.data['subtotal']) * retention_perc,
                            }
                        },
                    }};
                } else {
                    // console.log('hola');
                    const grandTraffic = Number(response.data['cost_by_traffic']);
                    const grandHighwayToll = Number(response.data['highway_tolls']);
                    const grandManeuvers = Number(response.data['maneuvers']);
                    // console.log(grandManeuvers);
                    const grandKms = Number(response.data['cost_by_km']);
                    // const grandImpoundLot =  Number(response.data['lot_total_cost']);
                    conceptos = { 
                        1:{ //TRAFICO
                        clave_prod_serv: this.prefered_product_key_traffic,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_traffic,    
                        descripcion: 'TRAFICO',
                        valor_unitario: grandTraffic.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandTraffic * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toLocaleString(),
                                importe: grandTraffic * retention_perc,
                            }
                        },
                    },
                    2: {//MANOIBRAS
                        clave_prod_serv:this.prefered_product_key_maneuvers,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_maneuvers,    
                        descripcion: 'MANIOBRAS',
                        valor_unitario: grandManeuvers.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandManeuvers * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: grandManeuvers * retention_perc
                            }
                        },
                    },
                    3:{//CASETAS
                        clave_prod_serv:this.prefered_product_key_traffic,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_traffic,    
                        descripcion: 'CASETAS',
                        valor_unitario: grandHighwayToll.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandHighwayToll * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: grandHighwayToll * retention_perc
                            }
                        },
                    },
                    4:{//KILOMETRAJE
                        clave_prod_serv:this.prefered_product_key_cost_km,
                        cantidad: response.data['total_kms'].toString(),
                        clave_unidad:this.prefered_measurement_unit_cost_km,    
                        descripcion: 'KILOMETRAJE',
                        valor_unitario: grandKms.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandKms * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: grandKms * retention_perc
                            }
                        },
                    },
                    5:{//PENSION
                        clave_prod_serv:this.prefered_product_key_pension_days,
                        cantidad: dateDifferenceDays,
                        clave_unidad:this.prefered_measurement_unit_pension_days,    
                        descripcion: 'PENSION',
                        valor_unitario: response.data['lot_cost_per_day'].toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: Number(response.data['lot_cost_per_day'])  * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: Number(response.data['lot_cost_per_day']) * retention_perc
                            }
                        },
                    }
                };
                }
                //comenzar diccionario con info
                this.billingInfo = {
                    cartaporte: this.cartaporte,
                    receptor: {
                        rfc: response.data['client']['client_rfc'],
                        nombre: response.data['client']['client_name'],
                        uso_cfdi: response.data['invoice_use'],
                        domicilio_fiscal_receptor: response.data['client']['client_address']['codigoPostal'],
                        regimen_fiscal_receptor: response.data['client']['tax_regime']
                    },
                    metodo_pago: response.data['payment_method'],
                    forma_pago: response.data['payment_type'],
                    serie: vectorNoteID[0],
                    folio: vectorNoteID[2],
                    conceptos: conceptos,
                    // { 
                    //     1:{
                    //     clave_prod_serv:claveProdServ,
                    //     cantidad:'1',
                    //     clave_unidad:'ACT',    
                    //     descripcion: 'SERVICIO DE LAS ORDENES ' + orderNumbers,
                    //     valor_unitario: grandSubtotal.toString(),
                    //     objeto_imp: '02', 
                    //     no_identificacion: '8635',
                    //     impuestos:{
                    //         traslados:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.16',
                    //             importe: grandSubtotal * .16,
                    //         },
                    //         retenciones:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.04',
                    //             importe: grandSubtotal * .04
                    //         }
                    //     },
                    // }},
                    
                };
                console.log(this.billingInfo)
                return this.invoiceStamping(this.billingInfo)
            
            .then(stampedInvoice => {
                return stampedInvoice;
            })
            .catch(error => {
                console.error('Error fetching locations:', error);
            });
        } catch (error) {
        console.error('Error constructing billing info:', error);
    }



                    // this.billingInfo = {
                    //     cartaporte:0,
                    //     receptor:{
                    //         rfc: response.data['client']['client_rfc'], 
                    //         nombre: response.data['client']['client_name'],
                    //         uso_cfdi: response.data['invoice_use'],
                    //         domicilio_fiscal_receptor: response.data['client']['client_address']['codigoPostal'],
                    //         regimen_fiscal_receptor: response.data['client']['tax_regime']
                    //     },
                    //     metodo_pago: response.data['payment_method'],
                    //     forma_pago: response.data['payment_type'], 
                    //     serie: vectorNoteID[0], 
                    //     folio: vectorNoteID[2], 
                    //     conceptos:{
                    //          1:{
                    //         clave_prod_serv: this.prefered_product_key,
                    //         cantidad:'1',
                    //         clave_unidad: this.prefered_measurement_unit,
                    //         descripcion: productDescriptions[this.prefered_product_key],
                    //         valor_unitario: response.data['subtotal'],
                    //         objeto_imp: '02', 
                    //         no_identificacion: '8635',
                    //         impuestos:{
                    //             traslados:{
                    //                 impuesto:'002',
                    //                 tipo_factor:'Tasa',
                    //                 tasa_o_cuota:'0.16',
                    //                 importe: response.data['iva'],
                    //             },
                    //             retenciones:{
                    //                 impuesto:'002',
                    //                 tipo_factor:'Tasa',
                    //                 tasa_o_cuota:'0.04',
                    //                 importe: response.data['retention']
                    //             }
                    //         },
                    // }}};
                    return this.invoiceStamping(this.billingInfo);
                })
                .then(stampedInvoice => {
                    return stampedInvoice;
                })
                .catch(error => {
                    console.error('Error getting auxiliar information:', error);
                });
        },
        invoiceStamping(billingInfo) {
            const apiDirectory = process.env.API_DIRECTORY;
            return axios.post(`${apiDirectory}/invoice-stamping`, billingInfo)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    // const errorMessage = 
                    //     error.response.data.error === 'conversion from NoneType to Decimal is not supported'
                    //         ? 'Error en datos de costos'
                    //         : error.response.data.error;
                    console.error('Error generando factura:', error);
                    Swal.fire('Error', 'Hubo un problema al generar la factura. ' + error.response.data.error, 'error');
                    console.log(error.response.data.error);
                    console.log(error);
                });
        },
        async generateInvoiceDocument(){
            const apiDirectory = process.env.API_DIRECTORY;
            const signedXML = await this.saveBilling();
            // console.log(this.localNoteDetails);
            const firstAssignation = this.assignationsData[0];
            const dateDifferenceHours = Math.ceil(Math.abs(new Date(firstAssignation.end_time) - new Date(firstAssignation.start_time)) / (1000 * 60 * 60));
            axios.post(`${apiDirectory}/generate-invoice-documents`, 
                        {signedXML: signedXML,
                        plh_authorize: this.localNoteDetails.approved_by,
                        plh_operatorName: firstAssignation.operator.employee_first_name + ' ' + firstAssignation.operator.employee_last_name,
                        plh_operatorNumber: firstAssignation.operator.employee_ident,
                        plh_orderId: this.localNoteDetails.note_ident,
                        plh_unitNumber: firstAssignation.unit.unit_short_id,
                        plh_plates: this.localNoteDetails.vehicle_plates,
                        plh_vehicleBrand: this.selectedBrandId + ' ' + this.selectedSubBrandId, 
                        plh_vehicleModel: this.localNoteDetails.vehicle_model + '  ' + this.localNoteDetails.vehicle_color,
                        plh_niv: this.localNoteDetails.vehicle_engine_series,
                        plh_insuredName: this.localNoteDetails.vehicle_owner_name,
                        plh_record: this.localNoteDetails.file_number,
                        plh_insurancePolicy: this.localNoteDetails.insurance_policy,
                        plh_event: this.localNoteDetails.insurance_incident,
                        plh_origin: this.localNoteDetails.source.neighborhood + ', ' + this.localNoteDetails.source.city + ' ' + this.localNoteDetails.source.state,
                        plh_serviceDescription: this.localNoteDetails.aux_requested_service,
                        plh_startTime: firstAssignation.start_time,
                        plh_arrivalTime: firstAssignation.arrival_time,
                        plh_endTime: firstAssignation.end_time,
                        // plh_retention:this.localNoteDetails.retention,
                        plh_totalTime: dateDifferenceHours,
                        plh_discount: '0.00',
                        note_type: 'AUX'
                        }, 
            {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'factura_multiformato.zip');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('Error generating files', error)
            });
        },
        fetchLocations() {
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/fetch-last-vehicle-locations`)
                .then(response => {
                    this.locations.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        },
        onMounted() {
            this.fetchLocations();
            setInterval(this.fetchLocations, 20000);
        },
        async saveNewClient() {
            const clientData = {
                client_ident: 'test',
                client_name: this.client_name,
                client_address: {
                    street: this.clientDetails.client_address.street,
                    codigoPostal: this.clientDetails.client_codigoPostal,
                    neighborhood: this.client_neighborhood,
                    city: this.client_city,
                    state: this.client_state,
                },
                client_rfc: this.clientDetails.client_rfc,
                phone: this.clientDetails.phone,
                client_status: 1,
                is_privileged: false
                
            };
            try {
                // Validate clientData here (optional)

                // Replace 'http://yourbackend.api/clients' with your actual API endpoint
                console.log(clientData);
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/create-client`, clientData, {
                            headers:
                            {
                                'Content-Type': 'application/json',
                            },
                        });
                this.client_id = response.data.id;
                this.localNoteDetails.client = this.client_id;
                
                // If the request is successful
                Swal.fire('Guardado!', 'El nuevo cliente se guardó correctamente.', 'success');
                

            } catch (error) {
                console.error('There was an error saving the client:', error);
                Swal.fire('Error', 'Hubo un problema al guardar el cliente nuevo.', 'error');
            }
        },
        fetchAssignationsForNote() {
            const apiDirectory = process.env.API_DIRECTORY;
            const noteId = this.localNoteDetails.note_ident;
            axios.post(`${apiDirectory}/get-note-assignations`, { note_ident: noteId })
                .then(response => {
                    this.assignationsData = response.data.map(assignation => ({
                        ...assignation,
                        unidad: assignation.unit.unit_short_id,
                        operador: assignation.operator.employee_ident,
                        operador_name: assignation.operator.employee_first_name,
                        operador_last_name: assignation.operator.employee_last_name,
                        unidad_name: assignation.unit.unit_sub_brand
                    }));
                    //Used afterwards to compare if the original or the updated value should be used when making an update
                    this.initialAssignations = JSON.parse(JSON.stringify(this.assignationsData));
                })
                .catch(error => {
                    console.error('Error fetching assignations:', error);
                });
        },
        toISOFormatWithTimezone(dateString) {
            if (!dateString) return null;
            try {
                const date = new Date(dateString);
                let isoDate = date.toISOString();
                return isoDate;
            }
            catch (error) {
                console.error(error);
                throw new Error('Failed to format date str: ' + error.message);
            }
        },
        async saveAssignation(assignationData, index) {
            let payload;
            // check if indeed there was a change. If the original time and current time are different, plus current is not null, set flag to true
            const start_time_updated = assignationData.start_time != null && (assignationData.start_time != this.initialAssignations[index].start_time);
            const arrival_time_updated = assignationData.arrival_time != null && (assignationData.arrival_time != this.initialAssignations[index].arrival_time);
            const contact_time_updated = assignationData.contact_time != null && (assignationData.contact_time != this.initialAssignations[index].contact_time);
            const end_time_updated = assignationData.end_time != null && (assignationData.end_time != this.initialAssignations[index].end_time);
            try {
                payload = {
                    // Depending on the flag control wether to use the (new) current value, or the existing one
                    start_time: start_time_updated ? this.toISOFormatWithTimezone(assignationData.start_time) : this.initialAssignations[index].start_time,
                    arrival_time: arrival_time_updated ? this.toISOFormatWithTimezone(assignationData.arrival_time) : this.initialAssignations[index].arrival_time,
                    contact_time: contact_time_updated ? this.toISOFormatWithTimezone(assignationData.contact_time) : this.initialAssignations[index].contact_time,
                    end_time: end_time_updated ? this.toISOFormatWithTimezone(assignationData.end_time) : this.initialAssignations[index].end_time,
                };
            }
            catch (error) {
                console.error(error)
                Swal.fire({
                    title: 'Error!',
                    text: 'Se recibio un valor inválido de tiempo para la actualización. Verifique los datos que introdujo.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                })
                return
            }
            const apiDirectory = process.env.API_DIRECTORY;
            const assignationId = assignationData.id;

            try {
                await axios.patch(`${apiDirectory}/assignation-detail/${assignationId}/`, payload);

                if (assignationData.start_time) {
                    this.localNoteDetails.status = 1;
                    await this.saveNote();
                }

                Swal.fire({
                    title: '¡Completado!',
                    text: 'La hora y fecha se actualizó con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(); // Reload the page after 'Ok' is clicked
                    }
                });
            } catch (error) {
                console.error('Error al actualizar la asignación:', error);
                Swal.fire('Error', 'Hubo un problema al actualizar las asignaciones', 'error');
            }
        },
        formatDateTime(isoString) {
            if (!isoString) {
                // If the string is empty or null, returns the message
                return "No hay datos de tiempo";
            }

            // Check if the timezone offset contains seconds and remove them if present
            const timezoneIndex = isoString.lastIndexOf('-') !== -1 ? isoString.lastIndexOf('-') : isoString.lastIndexOf('+');
            let timezone = isoString.substring(timezoneIndex);
            const timezoneParts = timezone.split(':');
            if (timezoneParts.length > 2) {
                // Remove seconds from the timezone offset
                timezone = `${timezoneParts[0]}:${timezoneParts[1]}`;
            }
            const isoStringWithoutSeconds = isoString.substring(0, timezoneIndex) + timezone;

            // Attempt to create a Date object with the corrected ISO string
            const date = new Date(isoStringWithoutSeconds);

            // Check if the date is 'Invalid Date'
            if (isNaN(date.getTime())) {
                return "No hay datos de tiempo";
            }

            const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };

            const formattedDate = date.toLocaleDateString('es-MX', optionsDate);
            const formattedTime = date.toLocaleTimeString('es-MX', optionsTime).toLowerCase();

            // Combine the parts by eliminating AM/PM repetition
            const matchAmPm = formattedTime.match(/am|pm/);
            const amPm = matchAmPm ? ` ${matchAmPm[0]}` : '';
            return `${formattedTime.replace(/am|pm/, '').trim()} del ${formattedDate}${amPm}`;
        },
        async fetchRecommendations() {
            const noteID = this.$route.params.noteID; // Get noteID from route params
            this.recommendations = [
                { 'unit': 'Cargando', 'time_queue': 'Cargando' },
                { 'unit': 'Cargando', 'time_queue': 'Cargando' },
                { 'unit': 'Cargando', 'time_queue': 'Cargando' },
                { 'unit': 'Cargando', 'time_queue': 'Cargando' },
                { 'unit': 'Cargando', 'time_queue': 'Cargando' }]
            this.recommenderLoading = true; 
            this.$forceUpdate();
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/recommend-tow`, { "note_ident": noteID });
                //this.recommendations = response.data;
                this.recommendations = [...response.data];
                while (this.recommendations.length < 5) {
                this.recommendations.push({ unit: "No disponible", time_queue: "No disponible" });
                }
                this.greenVehicleNames.value = this.recommendations.map(rec => rec.unit);
                //console.log(this.greenVehicleNames.value);
                this.redMarkerPosition.value = {lat: parseFloat(this.noteDetails.destination.lat), lng: parseFloat(this.noteDetails.destination.lon)};
                
                this.blockRecommend = false;
                this.$forceUpdate();
                console.log("recommendations are", this.recommendations)
            }
            catch (error) {
                this.recommenderLoading = false; 
                console.error("Failed to fetch recommendations:", error);
                if (error.response.status == 500) {
                    Swal.fire(
                        '¡Error!',
                        'Ocurrió un error al obtener las recomendaciones\n Intente de nuevo. Si el problema persiste, contacte a su administrador',
                        'error'
                    );
                }
                this.recommendations = [
                    { 'unit': 'Error', 'time_queue': 'Error' },
                    { 'unit': 'Error', 'time_queue': 'Error' },
                    { 'unit': 'Error', 'time_queue': 'Error' },
                    { 'unit': 'Error', 'time_queue': 'Error' },
                    { 'unit': 'Error', 'time_queue': 'Error' }]
            }
        },
        sanitizeForm() {
            /**Used to sanitize values before submitting any changes */

            //Since not all notes require km, change empty str to null
            this.localNoteDetails.source_km == "" ? this.localNoteDetails.source_km = null : true
            this.localNoteDetails.destination_km == "" ? this.localNoteDetails.destination_km = null : true
            //Since user is advised to not fill model if unkown, check if empty then change to null if its the case
            this.localNoteDetails.vehicle_model == "" ? this.localNoteDetails.vehicle_model = null : true
        },
        validatePositiveIntegerField(event) {
            /**Used to validate positive integer fields */
            if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault();
            }
        },
        validateFloatField(event) {
            const charCode = event.charCode;
            const value = event.target.value;

            const isDigit = charCode >= 48 && charCode <= 57;
            const isMinus = charCode === 45 && value.indexOf('-') === -1;
            const isDot = charCode === 46 && value.indexOf('.') === -1;

            if (!isDigit && !isMinus && !isDot) {
                event.preventDefault();
            }
        },
        async FetchBrands() {
            /** Fetches the brands from the db an passes it to the brands prop */
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-brands`);
                // Tmp dict to hold the response after processing 
                const brandsDict = {};
                // Convert response into dict of form: {pk:brand}
                response.data.forEach(brand => {
                    //brandsDict[brand.pk] = brand.brand;  // Adjust based on actual keys in your data
                    brandsDict[brand.brand] = brand.pk;  // Adjust based on actual keys in your data
                });
                console.log(brandsDict)
                this.brands = brandsDict
            }
            catch (error) {
                console.error("Failed to fetch brands", error);
                console.log(error.response)
                if (error.response.status == 400) {
                    Swal.fire('Error', "Hubo un problema con la solicitud. Intentalo nuevamente.\n\
                    Si el problema persiste, contacte a su administrador.", 'error');
                    return
                }
                if (error.response.status == 500) {
                    Swal.fire('Error', "Ocurrió un error desconocido. Intentalo nuevamente.\n\
                    Si el problema persiste, contacte a su administrador.", 'error');
                }
            }
        },
        async FetchFilteredSubBrands(event){
            // Grab the value from the brand selector 
            this.subBrandLoading = true;
            const selectedBrandPk = event.target.value;
            // Checks if there was a change in the brand. If so, reset the vue model value to null to prevent form submission until a conscius selection is made
            selectedBrandPk !== this.localNoteDetails.vehicle_brand ?  this.localNoteDetails.vehicle_sub_brand = null : true;
            try { 
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/get-filtered-sub-brands`,{"brand_id":selectedBrandPk});
                // Tmp dict to hold the response after processing 
                const subBrandsDict = {};
                // Convert response into dict of form: {id:sub_brand + "Clase: "+ required_class}
                const classes={1:'A',2:'B',3:'C',4:'D'}
                response.data.forEach(sub_brand => {
                    subBrandsDict[sub_brand.sub_brand+' '+'-- Clase: '+classes[sub_brand.required_unit_type]] = sub_brand.id;  // Adjust based on actual keys in your data
                });
                this.sub_brands = subBrandsDict;
                this.subBrandLoading = false
            }
            catch (error) {
                console.error("Failed to fetch brands", error);
                console.log(error)
                if (error.response.status == 400) {
                    Swal.fire('Error', "Hubo un problema con la solicitud. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');

                }
                if (error.response.status == 500) {
                    Swal.fire('Error', "Ocurrió un error desconocido. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');
                }
            }
        },
        async SetStartingBrandSubBrand(){
            try { 
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/get-sub-brand`,{"sub_brand_id":this.noteDetails.vehicle_sub_brand});
                // Tmp dict to hold the response after processing 
                this.localNoteDetails.vehicle_brand = this.brands[response.data.brand];
                this.localNoteDetails.vehicle_sub_brand = response.data.id;
                //const classes={1:'A',2:'B',3:'C',4:'D'};
                const syntheticEvent = {
                    target: {
                        value: this.localNoteDetails.vehicle_brand 
                    }
                };
                await this.FetchFilteredSubBrands(syntheticEvent);
            }
            catch (error) {
                console.error("Failed to fetch brands", error);
                console.log(error.response)
                if (error.response.status == 400) {
                    Swal.fire('Error', "Hubo un problema con la solicitud. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');

                }
                if (error.response.status == 500) {
                    Swal.fire('Error', "Ocurrió un error desconocido. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');
                }
            }
        },
        isRecommendationValid(recommendation){
            return recommendation['unit']!='No disponible';
        },
        async fetchImages() {
            //Retrieves all the images linked to the current note 
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-files/`, {
                    params: {
                        object_id: this.localNoteDetails.note_ident,
                        object_type: 'note',
                    },
                });
                const files = response.data;
                this.clearImages();
                this.populateImages(files);
                this.buttonClicked.images_tab = true;
                //console.log('Populated image blobs are ',this.imageSections)
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        },
        populateImages(files) {
            files.forEach(file => {
                const section = this.imageSections.find(section => section.loc === file.metadata.loc);
                if (section) {
                    section.images.uploaded.push(`data:image/jpeg;base64,${file.content}`); 
                    section.images.blob_ids.push(file.file_id);
                }
            });
        },
        clearImages() {
            this.imageSections.forEach(section => {
                section.images.uploaded = [];
                section.images.blob_ids = [];
            });
        },
        selectFile() {
            // Used to handle the image uploads 
            return new Promise((resolve) => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*'; 
                input.onchange = (event) => {
                    resolve(event.target.files[0]);
                };
                input.click();
            });
        },
        async uploadFile(section) {
            // Handles the upload of images to the upload-file endpoint 
            //console.log('Triggered section:',section)
            const file = await this.selectFile();
            if (!file) {
                Swal.fire({
                        title: '¡Error!',
                        text: 'Ocurrio un error al subir el archivo. Intente de nuevo.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
            }

            const formData = new FormData();
            formData.append('file', file);
            formData.append('object_type', 'note');
            formData.append('object_id', this.localNoteDetails.note_ident);
            formData.append('object_metadata', JSON.stringify({'loc': section.loc}));
            //console.log('form data',formData)
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                //const response =
                await axios.post(`${apiDirectory}/upload-file`, formData);
                //console.log('Response from upload',response)
                this.fetchImages();
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },
        downloadAllImages(section) {
            // Get the list of images for the specified section
            const images = section.images.uploaded;

            if (images.length === 0) {
                Swal.fire({
                    title: '¡Error!',
                    text: 'No hay imágenes en esta sección.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // return from func
                        return
                    }
                });
            }

            // Trigger download for each image
            images.forEach((image, index) => {
                const link = document.createElement('a');
                link.href = image; // Image data URL
                link.download = `${section.loc}_${index + 1}.jpg`; // Name the downloaded file
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
            Swal.fire({
                    title: '¡Completado!',
                    text: 'Descarga realizada',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                })
        },
        async deleteImage(section, index) {

            // Find the corresponding blob ID
            const blobId = section.images.blob_ids[index];

            if (!blobId) {
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ocurrio un error al eliminar el archivo. Intente de nuevo. Si el problema persiste, contacte a su administrador.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        return
                    }
                });
            }

            try {
                const apiDirectory = process.env.API_DIRECTORY;
                await axios.post(`${apiDirectory}/delete-file/`, { blob_id: blobId });

                // Remove the image from the section and the blob id from the dict 
                section.images.uploaded.splice(index, 1);
                section.images.blob_ids.splice(index, 1);


                Swal.fire({
                    title: '¡Completado!',
                    text: 'La imagen se eliminó con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                })
            } catch (error) {
                console.error('Error deleting image:', error);
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ocurrio un error al procesar la solicitud para eliminar la imagen. Por favor intente de nuevo. Si el problema periste, contacte a su administraodr.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // return from func
                        return
                    }
                });
            }
        },
    },
    async created() {
        // Here you can add any additional initialization that needs to happen when the component is created
        // For instance, setting initial form values based on props
        this.localNoteDetails = { ...this.noteDetails };
        this.greenVehicleNames.value = [];
        this.redMarkerPosition.value = { lat: 25.689214, lng: -100.314343 }; 
        this.fetchClients();
        this.fetchClientById(this.localNoteDetails.client);
        await this.FetchBrands()
        await this.SetStartingBrandSubBrand()
        this.blockEdit = this.noteDetails.status == 2 || this.noteDetails.status == 4 // Determines wheter editing the note should be enabled or disabled based on status
        this.blockRecommend = true; // Safelock to prevent buttons from being used before recommendations are computed
        this.recommenderLoading = false; // Safelock to prevent recommender from being invoked multiple times 
        this.recommendations = [
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' }]
    }
};
</script>
