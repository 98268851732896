<template>
    <div class="nav-bar-maintenance">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    
    <div class="void-new-unit"></div>

    <div class="form-section">
        <h4>Datos de la unidad</h4>
        <img src="@/assets/vehicle-icon.png">
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group">
                <label>ID</label>
                <input type="text" placeholder="Introduce el ID de la unidad" v-model="localUnitDetails.unit_id"/>
            </div>
            <div class="form-group">
                <label># Económico</label>
                <input type="text" placeholder="Introduce el número económico de la unidad" v-model="localUnitDetails.unit_short_id"/>
            </div>
            <div class="form-group">
                <label>Propietario</label>
                <select v-model="localUnitDetails.unit_owner">
                    <option value=1>Grúas Monterrey</option>
                    <option value=2>Grúas Saltillo</option>
                    <option value=3>Metrogruas</option>
                </select>
            </div>
            <div class="form-group">
                <label>Placas</label>
                <input type="text" placeholder="Introduce las placas" v-model="localUnitDetails.unit_plates"/>
            </div>
        </div>
        <div class="form-section">
            <div class="form-group">
                <label>Serie</label>
                <input type="text" placeholder="Introduce la serie" v-model="localUnitDetails.unit_engine_num"/>
            </div>
            <div class="form-group">
                <label>Marca</label>
                <select v-model="localUnitDetails.unit_brand">
                    <option value=1>Hino</option>
                    <option value=2>Volkswagen</option>
                    <option value=3>Dodge</option>
                    <option value=4>Freightliner</option>
                    <option value=5>Mercedez Benz</option>
                    <option value=6>KENWORTH</option>
                    <option value=7>Nissan</option>
                    <option value=8>Vento</option>
                    <option value=9>Honda</option>
                    <option value=10>Toyota</option>
                    <option value=11>Zoomlion</option>
                    <option value=12>Hyster</option>
                    <option value=13>Taylor</option>
                    <option value=14>Heli</option>
                </select>
            </div>
            <div class="form-group">
                <label>Sub marca</label>
                <select v-model="localUnitDetails.unit_sub_brand">
                    <option value="716 SUPERLONG">716 SUPERLONG</option>
                    <option value="FL-M2-106-25K-15">FL-M2-106-25K-15</option>
                    <option value="M2106 35K EUROV">M2106 35K EUROV</option>
                    <option value="FL-M2-106-16">FL-M2-106-16</option>
                    <option value="CL120">CL120</option>
                    <option value="M2 35K">M2 35K</option>
                    <option value="FL-M2-35K-12">FL-M2-35K-12</option>
                    <option value="816 SUPERLONG">816 SUPERLONG</option>
                    <option value="CASCADIA DD15">CASCADIA DD15</option>
                    <option value="716 SUPER LONG">716 SUPER LONG</option>
                    <option value="RAM 4000">RAM 4000</option>
                    <option value="TIPO T800B">TIPO T800B</option>
                    <option value="M2 106 25K">M2 106 25K</option>
                    <option value="FL360 2528">FL360 2528</option>
                    <option value="Modelo 9.170 4X2">Modelo 9.170 4X2</option>
                    <option value="Modelo 9.170 DELIVERY">Modelo 9.170 DELIVERY</option>
                    <option value="CL120 COLUMBIA">CL120 COLUMBIA</option>
                    <option value="TIPO T800B">TIPO T800B</option>
                    <option value="RAM 4000 GASOLINA">RAM 4000 GASOLINA</option>
                    <option value="M2 33K">M2 33K</option>
                    <option value="M2 25K">M2 25K</option>
                    <option value="Modelo 9.170 DELIVERY 4x2">Modelo 9.170 DELIVERY 4x2</option>
                    <option value="RAM 4000  DIESEL">RAM 4000  DIESEL</option>
                    <option value="FL360">FL360</option>
                    <option value="KENMEX 5a. RUEDA">KENMEX 5a. RUEDA</option>
                    <option value="March">March</option>
                    <option value="Moto">Moto</option>
                    <option value="GL150 CARGO">GL150 CARGO</option>
                    <option value="8FDU30"> 8FDU30</option>
                    <option value="7FDU70"> 7FDU70</option>
                    <option value="02-7FD40"> 02-7FD40</option>
                    <option value="FD35Z"> FD35Z</option>
                    <option value="FD50"> FD50</option>
                    <option value="FD70"> FD70</option>
                    <option value="H280XL"> H280XL</option>
                    <option value="FD35"> FD35</option>
                    <option value="FD50"> FD50</option>
                    <option value="FD70"> FD70</option>
                    <option value="T360L"> T360L</option>
                    <option value="T360L"> T360L 36,000 LBS</option>
                    <option value="FD160"> FD160</option>
                    <option value="CPCD70-W2G"> CPCD70-W2G</option>
                    <option value="CPQYD50-KUG3"> CPQYD50-KUG3</option>
                    <option value="CPCD100-W5G"> CPCD100-W5G</option>
                </select>
            </div>
            <div class="form-group">
                <label>Modelo</label>
                <input type="text" placeholder="Introduce el año del modelo" v-model="localUnitDetails.unit_model"/>
            </div>
        </div>
        <div class="form-section">
            <div class="form-group">
                <label>Clase</label>
                <select v-model="localUnitDetails.unit_class">
                    <option value=1>Federales</option>
                    <option value=2>Particulares</option>
                    <option value=3>Reparto</option>
                    <option value=4>N/A</option>
                    <option value=5>Sitio</option>
                    <option value=6>Discapacitado</option>
                    <option value=7>Auxilio</option>
                </select>
            </div>
            <div class="form-group">
                <label>Tipo</label>
                <select v-model="localUnitDetails.unit_type">
                    <option value=1>CAJA SECA</option>
                    <option value=2>INDUSTRIALES</option>
                    <option value=3>MONTACARGAS</option>
                    <option value=4>NODRIZAS</option>
                    <option value=5>PARTICULARES</option>
                    <option value=6>PLATAFORMAS  Y LOW BOYS</option>
                    <option value=7>RAMPAS</option>
                    <option value=8>RAMPAS 4 CARROS</option>
                    <option value=9>RAMPAS GRANDES</option>
                    <option value=10>REMOLQUES CHICOS</option>
                    <option value=11>REMOLQUES GRANDES</option>
                    <option value=12>REPARTO</option>
                    <option value=13>TITANES</option>
                    <option value=14>TRACTORES</option>
                    <option value=15>Rescate</option>
                    <option value=16>AUXILIO VIAL</option>
                </select>
            </div>
            <div class="form-group">
                <label>Categoría</label>
                <select v-model="localUnitDetails.unit_category">
                    <option value=1>Categoría A</option>
                    <option value=2>Categoría B</option>
                    <option value=3>Categoría C</option>
                    <option value=4>Categoría D</option>
                    <option value=5>AUXILIO VIAL</option>
                    <option value=6>MONTACARGAS</option>
                </select>
            </div>
            <!-- <div class="form-group">
                <label for="????">Estatus</label>
                <select id="???" v-model="localUnitDetails.unit_status">
                    <option value=1>Disponible</option>
                    <option value=2>Ocupada</option>
                    <option value=3>Fuera de servicio</option>
                    <option value=4>En servicio</option>
                    <option value=5>En mantenimiento</option>  
                </select> 
            </div> -->
        </div>
    </div>

    <button type="submit" class="btn-submit-save" @click="saveUnitProfile">Guardar</button>
    <button type="button" class="btn-submit-cancel" onclick="history.back()">Cancelar</button>
</template>

<style>
.nav-bar-maintenance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background-color: #FFC434;
    z-index: 1000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.form-container {
    display: flex;
    justify-content: center;
    margin: 5px;
}
.form-section {
    flex-basis: calc(33% - 10px); 
    margin-right: 10px;
    background: #fff; 
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); 
}
.form-section:last-child {
    margin-right: 0;
}
.form-group {
    margin-bottom: 15px;
    width: 5vw;
}
label {
    display: block;
    margin-bottom: 10px;
}
input[type="text"],
input[type="tel"] {
    width: 15vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.btn-submit-save {
    background-color: #00ff1e; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-cancel {
    background-color: #ff0000; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
h2 {
    margin-bottom: 20px;
}
.form-group label {
    text-align: left; 
    font-weight: bold; 
    display: inline-block; 
    width: 100%; 
}
.form-section img{
    width: 40px;
    height: 40px;
}
.form-check-input {
    width: 20px;
}
textarea {
    width: 20vw; 
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    resize: vertical; 
}
.void-new-unit{
    margin-top: 10vh;
}
</style>

<script>

import axios from 'axios';
import Swal from 'sweetalert2'

export default {
    props: ['unitDetails'],
    data(){
        return{
            localUnitDetails: {...this.unitDetails},
            unit_id: this.unitDetails.unit_id,
            unit_short_id: this.unitDetails.short_id,
            unit_owner: this.unitDetails.unit_owner,
            unit_plates: this.unitDetails.unit_plates,
            unit_engine_num: this.unitDetails.unit_engine_num,
            unit_brand: this.unitDetails.unit_brand,
            unit_sub_brand: this.unitDetails.unit_sub_brand,
            unit_model: this.unitDetails.unit_model,
            unit_class: this.unitDetails.unit_class,
            unit_type: this.unitDetails.unit_type,
            unit_category: this.unitDetails.unit_category,
            unit_status: this.unitDetails.unit_status,
            unit_deleted: this.unitDetails.unit_deleted,
            unitCivilLiabilityInsurance:'dfghj',
            unitCivilLiabilityPolicy:'dfghj',
            unitGrossWeight:'65',
            unitTrailerSubType:'a',
            unitVehicularConfiguration:'5544',
            unitSctPermission:'tytyty'
        }
    },
    watch: {
        unitDetails: {
            deep: true,
            handler(newVal) {
                this.localUnitDetails = {...newVal};
            }
        }
    },
    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        saveUnitProfile: async function() {
            try {
                const unit_id = this.unitDetails.unit_id; // Ensure this matches the actual ID property
                const apiDirectory = process.env.API_DIRECTORY;
                this.saving_unit = true
                await axios.put(`${apiDirectory}/unit_detail/${unit_id}/`, this.localUnitDetails);
                Swal.fire(
                    '¡Actualizado!',
                    'Los datos del formulario se han actualizado con éxito.',
                    'success'
                )
                this.saving_unit = false;
                // Additional actions after success, e.g., redirecting or updating local state
            } catch (error) {
                console.error(error)
                this.saving_unit=false
                Swal.fire('Error', 'Hubo un problema al actualizar los datos de la unidad.', 'error');
                
            }
        },
    },
    async created () {
        this.localUnitDetails = {...this.unitDetails };
    }
}
</script>