<!--TODO: make this component responsive-->
<template>
    <div class="nav-bar-operations">
      <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="welcome-create-order">
      <h3>Crear nueva orden</h3>
    </div>
    <div class="principal-options">
      <div class="wrapper">
        <h2>Remolque</h2>
        <div class="card">
          <img src="@/assets/A-vial-card.jpg">
          <div class="info">
            <a href="./new-order-assistance" class="btn">Crear</a>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <h2>Auxilio vial</h2>
        <div class="card">
          <img src="@/assets/A-auxiliar-card.jpg">
          <div class="info">
            <a href="./new-order-auxiliar" class="btn">Crear</a>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <h2>Montacarga</h2>
        <div class="card">
          <img src="@/assets/Montacarga-card.jpg">
          <div class="info">
            <a href="./new-order-forklift" class="btn">Crear</a>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <h2>Lote</h2>
        <div class="card">
          <img src="@/assets/Lote-card.jpg">
          <div class="info">
            <a href="./new-order-lot" class="btn">Crear</a>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.welcome-create-order{
    display: flex;
    justify-content: space-between; /* Aligns child elements to the end of the container */
    align-items: center; /* Center child elements vertically */
    margin-top: 100px;
    height: 4vh;
    border-bottom: 1px solid black;
    margin-left: 20px;
    margin-right: 20px;
}
.principal-options {
    width: 68vw;
    height: 100%;
    margin-left: 15vw;
    padding: 0;
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}
.wrapper {
    margin: 0;
    padding: 20px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 0px;
}

.card {
    position: relative;
    width: 100%;
    height: 200%;
    padding: 2rem 1rem;
    background: #fff;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    transition: 0.5s ease-in-out;
    
}
.card:hover{
    transform: translateY(20px);
}
.card::before{
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgb(242, 255, 0));
    z-index: 2;
    transition: 0.5s all;
    opacity: 0;
}
.card:hover:before{
    opacity: 1;
}
.card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.card .info{
    position: relative;
    top: 40%;
    left: -2%;
    justify-content: center;
    align-items: center;
    z-index: 3;
    color: #fff;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s all;
}
.card:hover .info{
    opacity: 1;
    transform: translateY(0px);
}
@media screen and (min-height: 930px) {
  .welcome-create-order{
    display: flex;
    justify-content: space-between; /* Aligns child elements to the end of the container */
    align-items: center; /* Center child elements vertically */
    margin-top: 11vh;
    height: 4vh;
    border-bottom: 1px solid black;
    margin-left: 20px;
    margin-right: 20px;
}
}

</style>

<script>
export default{
    methods:{
        backMainPanel() {
            window.location.href = '/main-panel';
        },
    }
}
</script>