<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button" />
    </div>
    <div class="text-operations"></div>
    <div class="form-section">
        <h3>Creación de factura agrupada de montacargas</h3>
        <img src="@/assets/filtros.png">
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label for="note_ident">Identificador de la nota</label>
                <input type="text" id="note_ident" v-model="noteIdent" placeholder="Ingrese el identificador de la nota a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label>Seleccione la fecha de inicio</label>
                <input type="datetime-local" v-model="startDate" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                <label>Seleccione la fecha del final</label>
                <input type="datetime-local" v-model="endDate" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="client_name">Cliente</label>
                <input type="text" id="client_name" v-model="clientName" placeholder="Ingrese el cliente a buscar" />
            </div>
        </div>
        <!-- Lo del cliente se va a reemplazar por este código
            <div class="form-group-filters">
                    <label for="client_name">Cliente</label>
                    <input type="text" id="client_name" placeholder="Escribe y seleccione el nombre del cliente" v-model="client_name" @input="filterClients" autocomplete="off" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    <div class="client-dropdown" v-if="filteredClients.length" v-click-outside="closeDropdown">
                        <div v-for="client in filteredClients" :key="client.id" @click="selectClient(client)">
                            {{ client.client_name }}
                        </div>
                    </div>
                </div>
        -->
        <div class="form-section">
            <div class="form-group-filters">
                <label for="origin">Origen</label>
                <input type="text" id="origin" v-model="origin" placeholder="Ingrese el origen a buscar" />
            </div>
        </div>
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label for="destination">Destino</label>
                <input type="text" id="destination" v-model="destination" placeholder="Ingrese el destino a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="unit">Unidad</label>
                <input type="text" id="unit" v-model="unit" placeholder="Ingrese la unidad a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="operator">Operador</label>
                <input type="text" id="operator" v-model="operator" placeholder="Ingrese el operador a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="file">Expediente</label>
                <input type="text" id="file" v-model="file" placeholder="Ingrese el expediente a buscar" />
            </div>
        </div>
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label for="clerk">Capturista</label>
                <input type="text" id="clerk" v-model="clerk" placeholder="Ingrese el capturista a buscar" />
            </div>
        </div>
        <!--
        <div class="form-section">
            <div class="form-group-filters">
                <label for="??">Seleccione los tipos de nota a buscar</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Remolque</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Auxilio Vial</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Montacarga</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Lote</label>
            </div>
        </div>
    -->
        <div class="form-section">
            <div class="form-group-filters">
                <label for="??">Seleccione el estatus de la nota</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isActive" v-model="isActive" />
                <span style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isAssigned" v-model="isAssigned"/>
                <span style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isProgrammed" v-model="isProgrammed"/>
                <span style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isCanceled" v-model="isCanceled"/>
                <span style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="isCompleted" v-model="isCompleted"/>
                <span style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
            </div>
        </div>

        <!--
        <div class="form-section">
            <div class="form-group-filters">
                <label for="??">Seleccione que tenga carta porte y/o factura</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Facturado</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>Facturado con carta porte</label>
            </div>
            <div class="checkbox-group">
                <input type="checkbox" id="??"/>
                <label>No facturado</label>
            </div>
        </div>
    -->
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label for="plate">Placa</label>
                <input type="text" id="plate" v-model="plate" placeholder="Ingrese la placa a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="engine">Serie</label>
                <input type="text" id="engine" v-model="engine" placeholder="Ingrese la serie a buscar" />
            </div>
        </div>
    </div>
    <button type="submit" class="btn-submit-save" @click="queryOrders">Aplicar filtros</button>

    <!-- Table for scheduled orders -->
    <div class="table-container">
        <h2>Resultados</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>Seleccionar</th>
                    <th>ID</th>
                    <th>Fecha y hora de solicitud</th>
                    <th>Cliente</th>
                    <th>Origen</th>
                    <th>Unidad</th>
                    <th>Operador</th>
                    <th>Expediente</th>
                    <th>Capturista</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in queriedOrders" :key="index">
                    <td>
                        <span
                            style="background-color: orangered; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{      }}</span>
                    <input type="checkbox" :value="order.note_ident" v-model="selectedNotes" />
                </td>
                    <td @click="viewNoteDetails(order.type, order.note_ident)">
                        <span
                            style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{
                            order.note_ident }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident}} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>
                        <span v-if="order.note_status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.note_status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.note_status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.note_status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.note_status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.note_status }}</span> <!-- Status -->
                    </td>
                    <th></th>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPage" :disabled="currentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in TotalPages" :key="pageNumber"
                @click="goToPage(pageNumber)"
                :class="{ active: pageNumber === currentPage }">{{ pageNumber }}</span>
            <button @click="nextPage" :disabled="currentPage >= TotalPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
        <button type="submit" class="btn-submit-save" @click="handleSelectedNotes">Facturar</button>
    </div>

    <div v-if="showPopup" class="popup-overlay" @click="closePopup">
      <div class="popup-content" @click.stop>
        <div class="popup-header">
          <div class="popup-header-left">
            <p><strong>Factura #</strong> 105,926-G (OT: VARIAS) &nbsp; <strong>Estatus:</strong> Pagada</p>
            <p><strong>Cliente #</strong> {{ this.clientInfo.client_ident }}</p>
          </div>
          <!-- <div class="popup-header-right"> -->
            <!-- <p><strong>Sucursal:</strong> ASISTENCIAS FORANEAS</p> -->
          <!-- </div> -->
        </div>

        <div class="popup-body">
          <div class="popup-left-section">
            <p><strong>Nombre:</strong> {{ this.clientInfo.client_name }}</p>
            <p><strong>Dirección:</strong> Calle: {{ this.clientInfo.client_address.street }}</p>
            <p><strong>RFC:</strong> {{ this.clientInfo.client_rfc }} &nbsp; <strong>Tel:</strong> {{ this.clientInfo.phone }}</p>
            <p><strong>Ciudad:</strong> {{ this.clientInfo.client_address.city }}</p>
            <p><strong>CP:</strong> {{ this.clientInfo.client_address.codigoPostal }}</p>
            <!-- <p class="horizontal-line" ><strong>Autorizan:</strong> alin 5538996718, hector 5538295079, vero 5538993185</p> -->
            <p><strong>Comentarios:</strong> <br>
              Comentarios Adicionales: <a href="#">Desplegar Ordenes</a>
            </p>
            <div class="checkbox-group">
            <input type="checkbox" id="toggleFields1" v-model="invoiceBreakdown" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
            <label for="toggleFields1">Desglosar factura</label>
        </div>
        <!-- <p><strong>Clave de producto:</strong></p>
        <div class="form-group" v-if="!invoiceBreakdown">
                <select id="prefered_product_key_traffic" v-model="this.prefered_product_key_traffic" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <option value="72154503">72154503 - SERVICIO DE ALQUILER DE GRUAS</option>
                    <option value="78121603">78121603 - TARIFA DE LOS FLETES</option>
                    <option value="90121800">90121800 - SERVICIO DE ASISTENCIA DE EMERGENCIA EN VIAJES</option> 
                    <option value="81141601">81141601 - LOGÍSTICA</option>
                    <option value="78101803">78101803 - SERVICIO DE TRANSPORTE DE VEHÍCULOS</option> 
                    <option value="78181702">78181702 - DE ALMACENAJE DE VEHICULOS DE TRANSPORTE</option> 
            </select> 
            </div>
            <p><strong>Unidad de medida:</strong></p>
            <div class="form-group" v-if="!invoiceBreakdown">
                <select id="prefered_measurement_unit_traffic" v-model="this.prefered_measurement_unit_traffic" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <option value="ACT">ACT - TRAFICO</option>
                    <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                    <option value="H87">H87 - PIEZA</option>
                </select> 
            </div> -->
            <button type="submit" class="btn-submit-save" @click="generateInvoiceDocument">Generar factura</button>
          </div>

          <div class="popup-right-section">
            <p class="horizontal-line"><strong>Fechas (CST)</strong></p>
            <p>Factura: {{ nowDateTime }}</p>
            <!-- <p>Vencimiento: 06/12/2024</p> -->
            <!-- <p>Probable Pago: 07/11/2024</p> -->
            <p class="horizontal-line"><strong>Costos</strong></p>
            <p>Descuento: 0.00 </p>
            <p>Subtotal: {{ this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['subtotal'])|| 0), 0) }}</p>
            <p>IVA: {{ this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['iva'])|| 0), 0) }}</p>
            <p>IVA Retenido: {{ this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['subtotal']) * (this.applies_retention? 0.04 : 0)|| 0), 0) }}</p>
            <p class="horizontal-line"><strong>Total: {{ this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['subtotal'])|| 0), 0) +
                                                         this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['iva'])|| 0), 0) -
                                                         this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['subtotal']) * (this.applies_retention? 0.04 : 0)|| 0), 0) }}</strong></p>
            <p><strong>Metodo de Pago: </strong></p> 
            <div class="form-group">
                <!-- <label for="payment_method">Método de pago</label> -->
                <select id="payment_method" v-model="this.selectedNoteDetails[0].payment_method" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <option value="PPD">PAGO EN PARCIALIDADES O DIFERIDO</option>
                    <option value="PUE">PAGO EN UNA EXHIBICIÓN</option>
                </select>
            </div>
            <p><strong>Tipo de Pago:</strong></p>
            <div class="form-group">
                <!-- <label for="payment_type">Tipo de pago</label> -->
                <select id="payment-type" v-model="this.selectedNoteDetails[0].payment_type" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <option value="99" v-if="this.selectedNoteDetails[0].payment_method === 'PPD'">POR DEFINIR</option>
                    <template v-if="this.selectedNoteDetails[0].payment_method !== 'PPD'">
                        <option value="99">POR DEFINIR</option>
                        <option value="04">TARJETA DE CRÉDITO</option>
                        <option value="03">TRANSFERENCIA</option>
                        <option value="01">EFECTIVO</option>
                        <option value="28">TARJETA DE DÉBITO</option>
                        <option value="02">CHEQUE NOMINATIVO</option>
                    </template>
                </select>
            </div>
            <!-- <p>Forma de Pago (SAT): Por definir</p> -->
          </div>
        </div>

        <button class="popup-close-button" @click="closePopup">Close</button>
      </div>
    </div>

</template>

<style>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}

.logo-button {
    position: absolute; 
    height: 90%; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}

.text-operations {
    margin-top: 10vh; /* Adjust this value to ensure it's below the nav bar */
    left: 0;
    width: 100%;
    height: 5vh;
    border-bottom: 1px solid black;
    background-color: #ffffff;
    z-index: 999; /* Ensure it's above other content */
    font-size: 30px;
    display: flex;
    justify-content: space-between; /* Adjust spacing between elements */
    align-items: center; /* Align items vertically */
    overflow: hidden; /* Prevent text overflow */
}

.text-operations h2 {
    font-size: inherit; /* Adjust font size as needed */
    margin: 0;
    padding: 0;
    margin-left: 2vh;
}

.form-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}

.form-section{
    flex-basis: calc(33% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 10px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
}

.form-section-images img{
    width: 100px;
    height: 100px;
}

.image-container {
    margin-top: 10vh;
    width: 100%;
    height: 200px;
}

.image-container-upload {
    margin-top: 2vh;
    margin-bottom: 3vh;
    width: 100%;
    height: 200px;
}

.form-group-filters-images{
    height: 8vh;
}

.image-container-upload img{
    width: 200px !important;
    height: 200px !important;
}

.form-section:last-child {
    margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
}

h2 {
    margin-bottom: 20px; /* Espacio debajo de los títulos de sección */
}

.form-group-filters label {
    text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
    font-weight: bold; /* Hace que el texto de la etiqueta sea negrita */
    display: inline-block; /* Hace que la etiqueta sea en bloque pero alinee su contenido */
    width: 400%; /* Asegura que la etiqueta ocupe todo el ancho disponible */
}

.form-section img{
    width: 40px;
    height: 40px;
}

.form-group-filters input{
    width: 100%;
}

.checkbox-group {
    display: flex;
    justify-content: flex-start; 
    gap: 5px; 
    font-weight: bold;
}

.checkbox-group input{
    margin-bottom: 1vh;
}

.table-container {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 0;
    padding: 1vh;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.6em; 
}

.table th, .table td {
    border-left: none;
    border-right: none;
    padding: 5px; 
    text-align: left;
}

.table tbody tr:first-child td {
    border-top: none;
}

.table tbody tr:hover td {
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}

.table tbody tr:last-child td {
    border-bottom: none;
}

.table thead input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border-width: 1px;
}

.table th{
    border-left: none;
    border-right: none;
    padding: 10px;
    text-align: left
}

.table td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.pagination-container {
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.pagination-container button {
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    margin: 0 10px;
    background-color: transparent; 
    border: none; 
}

.pagination-container button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.pagination-container span {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-container span.active {
    background-color: #FFC434;
}

.pagination-container button img {
    width: 25px; 
    height: auto; 
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.popup-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  color: #000;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.popup-header-left p,
.popup-header-right p {
  margin: 5px 0;
}

.popup-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.popup-left-section,
.popup-right-section {
  width: 48%;
}

.popup-right-section {
  border-left: 1px solid #000;
  padding-left: 15px;
}

.popup-left-section p,
.popup-right-section p {
  margin: 5px 0;
}

.popup-left-section a {
  color: #FFC434;
  text-decoration: underline;
  cursor: pointer;
}

.popup-close-button {
  background-color: #FFC434;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 15px;
  color: #000;
  font-weight: bold;
  float: right;
}

/* Custom horizontal lines */
.horizontal-line {
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
}


</style>

<script>

import axios from 'axios';
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            noteIdent: '',
            startDate: '',
            endDate: '',
            clientName: '',
            origin: '',
            destination: '',
            unit: '',
            operator: '',
            file: '',
            clerk: '',
            plate: '',
            engine: '',
            queriedOrders: [],
            selectedNotes: [],
            currentPage: 1,
            pageSize:20,
            nOrders: 1,
            StatusCounts: {
                Activo: 1, // Example hardcoded value
                Cancelado: 1, // Example hardcoded value
                Programado: 1, // Example hardcoded value
                Completado: 1, // Example hardcoded value
                Asignada: 1
            },
            isActive: '',
            isAssigned: '',
            isCanceled: '',
            isCompleted: '',
            isProgrammed: '',
            selectedNoteDetails: [],
            showPopup: false,
            clientInfo: {},
            nowDateTime: this.getCurrentDateTime(),
            invoiceBreakdown: false,
            prefered_product_key_traffic: '78101803',
            prefered_measurement_unit_traffic: 'ACT',
            // prefered_product_key_pension_days: '78181702',
            // prefered_measurement_unit_pension_days: 'ACT',
            // prefered_product_key_cost_km: '78121603',
            // prefered_measurement_unit_cost_km: 'ACT',
            prefered_product_key_maneuvers: '81141601',
            prefered_measurement_unit_maneuvers: 'ACT',
            prefered_product_key_service_hours: '78101803',
            prefered_measurement_unit_service_hours: 'ACT',
            // invoiceBreakdown: false,
            invoiceBreakdownEdit: false,
            applies_retention: false,
        };
    },

    computed: {

        PaginatedOrders() {
            //**Contains page of orders with status NE to "Programado" */
            return this.queriedOrders
        },

        TotalPages() {
            return Math.ceil((this.StatusCounts.Activo + this.StatusCounts.Asignada + this.StatusCounts.Programado, + this.StatusCounts.Completado + this.StatusCounts.Asignada) / this.pageSize);
            // return Math.ceil(this.assistance_n_inProcessOrders / this.assistancePageSize);
        },  
    },

    methods: {
        getCurrentDateTime() {
            const now = new Date();
            return now.toLocaleString("en-US", { timeZone: "CST" }); // Adjust format and locale as needed
            },

        backMainPanel() {
            window.location.href = '/main-panel';
        },

        viewNoteDetails(notetype, noteID) {
            // Calls EditNote component for aux or assistance notes
            Swal.fire({
                title: '¿Estás seguro?',
                text: `¿Quieres ver los detalles de la nota ${noteID}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, ver detalles',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'EditNote', params: { notetype, noteID } });
                }
            });
        },

        goToPage(pageNumber) {
            this.currentPage = pageNumber;
            const page = this.currentPage
            this.queryOrders(page);
        },

        nextPage() {
            if (this.currentPage < this.TotalPages) this.currentPage++;
            const page = this.currentPage
            this.queryOrders(page);
        },

        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
            const page = this.currentPage
            this.queryOrders(page);
        },

        async handleSelectedNotes() {
            console.log(this.selectedNotes); // Array of selected note IDs

            const apiDirectory = process.env.API_DIRECTORY;

            for (const noteID of this.selectedNotes) {
            try {
                let response;
                if (noteID.includes("MTC")) {
                    response = await axios.get(`${apiDirectory}/forklift-notes/${noteID}/`);
                } else {
            response = await axios.get(`${apiDirectory}/notes/${noteID}/`);
            }

            // Add  the fetched note details to the list
            this.selectedNoteDetails.push({
                ...response.data,
            });

            // console.log(this.selectedNoteDetails);
            response = await axios.get(`${apiDirectory}/clients/` + this.selectedNoteDetails[0]['client'] + '/');
            this.clientInfo = response.data;
            if (this.clientInfo.prefered_product_key_traffic) {
                    this.prefered_product_key_traffic = this.clientInfo.prefered_product_key_traffic;
            }
            if (this.clientInfo.prefered_measurement_unit_traffic) {
                this.prefered_measurement_unit_traffic = this.clientInfo.prefered_measurement_unit_traffic;
            }
            // if (this.clientInfo.prefered_product_key_cost_km) {
            //     this.prefered_product_key_cost_km = this.clientInfo.prefered_product_key_cost_km;
            // }
            // if (this.clientInfo.prefered_measurement_unit_cost_km) {
            //     this.prefered_measurement_unit_cost_km = this.clientInfo.prefered_measurement_unit_cost_km;
            // }
            if (this.clientInfo.prefered_product_key_maneuvers) {
                this.prefered_product_key_maneuvers = this.clientInfo.prefered_product_key_maneuvers;
            }
            if (this.clientInfo.prefered_measurement_unit_maneuvers) {
                this.prefered_measurement_unit_maneuvers = this.clientInfo.prefered_measurement_unit_maneuvers;
            }
            if (this.clientInfo.prefered_product_key_service_hours) {
                this.prefered_product_key_service_hours = this.clientInfo.prefered_product_key_service_hours;
            }
            if (this.clientInfo.prefered_measurement_unit_service_hours) {
                this.prefered_measurement_unit_service_hours = this.clientInfo.prefered_measurement_unit_service_hours;
            }
            if (this.clientInfo.applies_retention) {
                this.applies_retention = this.clientInfo.applies_retention;
            }
            // console.log(this.clientInfo);


        } catch (error) {
        console.error(`Failed to fetch details for note ${noteID}:`, error);
        }
        }
            console.log(this.selectedNoteDetails); // Array of selected note IDs
            this.showPopup = true;

             // Run saveBilling after fetching notes
            // await this.generateInvoiceDocument();

        },

        async fetchStatistics(){
            const payload = {
                note_ident: this.noteIdent,
                start_date: this.startDate,
                end_date: this.endDate,
                client_name: this.clientName,
                origin: this.origin,
                destination: this.destination,
                unit: this.unit,
                operator: this.operator,
                file: this.file,
                clerk: this.clerk,
                is_active: this.isActive,
                is_completed: this.isCompleted,
                is_assigned: this.isAssigned,
                is_canceled: this.isCanceled,
                is_programmed: this.isProgrammed
            };
            let response 
            const apiDirectory = process.env.API_DIRECTORY;
            // Request schedules status as or condition (Check view for details)
            try{
            response = await axios.post(`${apiDirectory}/get-global-filtered-statistics-forklift`, payload);
                const statusDict = {
                    1: "Activo",
                    2: "Cancelado",
                    3: "Programado",
                    4: "Completado",
                    5: "Asignada"
                };
                // Initialization to avoid undefined on bar
                let statusCounts = {
                    "Activo":0,
                    "Cancelado":0,
                    "Programado":0,
                    "Completado":0,
                    "Asignada":0
                };
                // Assign based on response 
                // console.log(response.data);
                const responseData = response.data;
                // console.log(responseData);
                responseData.forEach(item => {
                    const status = statusDict[item.status]; // Get the name from the statusDict
                    const count = item.count;
                    statusCounts[status] = count;
                });
                // Returned from statistics endpoint 
                this.StatusCounts = statusCounts;
            }
            catch(error){
                console.error("There was an error fetching the statistics",error)
            }
        },

        closePopup() {
    this.showPopup = false;
    this.selectedNoteDetails = [];
  },

        async queryOrders(){
            this.fetchStatistics()
            const payload = {
                note_ident: this.noteIdent,
                start_date: this.startDate,
                end_date: this.endDate,
                client_name: this.clientName,
                origin: this.origin,
                destination: this.destination,
                unit: this.unit,
                operator: this.operator,
                file: this.file,
                clerk: this.clerk,
                is_active: this.isActive,
                is_completed: this.isCompleted,
                is_assigned: this.isAssigned,
                is_canceled: this.isCanceled,
                is_programmed: this.isProgrammed
            };

            try {
                const apiDirectory = process.env.API_DIRECTORY;
                // Set responseType to 'blob' to handle file download
                const response = await axios.post(`${apiDirectory}/get-global-filtered-orders-forklift?page=${this.currentPage}&page_size=${this.pageSize}`, payload, {
                });
                
                const responseData = response

                responseData.data.results.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.queriedOrders = responseData.data.results
            } catch (error) {
                const errorMessage = error.response ? error.response.data.error : error.message;
                Swal.fire({
                    icon: 'error',
                    title: 'Error Generando Reporte',
                    text: errorMessage,
                });
            }
        },
        async fetchNoteDetails(noteID) {
      console.log(noteID,'this is the note id i got and will determine the endpoint i call')
      // Determine which endpoint to call based on the route params
    //   if (noteID.includes("MTC")){
    //     try {
    //     const apiDirectory = process.env.API_DIRECTORY;
    //     const response = await axios.get(`${apiDirectory}/forklift-notes/` + noteID + "/");
    //     this.noteDetails = response.data;
    //     this.noteType = 3; // Assuming the response includes a type field
    //   } catch (error) {
    //     console.error("Failed to fetch note details:", error);
    //   }
    //   }
    //   else{
      try {
        const apiDirectory = process.env.API_DIRECTORY;
        const response = await axios.get(`${apiDirectory}/forklift-notes/` + noteID + "/");
        this.noteDetails = response.data;
        this.noteType = response.data.type; // Assuming the response includes a type field
      } catch (error) {
        console.error("Failed to fetch forklift note details:", error);
        // Handle error (e.g., show user feedback)
      }
    // }
    },
    async saveBilling() {
        // Generate sign and stamp invoice
        // const claveProdServ = '78101803';//client['clave_prod'] || '090909';
        const apiDirectory = process.env.API_DIRECTORY;
        const firstNote = this.selectedNoteDetails[0];
        const vectorNoteID = firstNote['note_ident'].split('-');
        const client = await axios.get(`${apiDirectory}/client/` + firstNote['client'] + "/");
        const orderNumbers = this.selectedNotes
            .map(note => note.split('-')[2]) // Extract the third part of each `note_ident`
            .join(', '); // Concatenate with ", "
        const grandSubtotal = this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['subtotal'])|| 0), 0).toFixed(2);
        // console.log(orderNumbers); // Output: "ABC, DEF, GHI"
        // console.log(firstNote);
        // const prefered_product_key_traffic = client.data['prefered_product_key_traffic'] ? client.data['prefered_product_key_traffic'] : 
        // console.log(grandSubtotal.toString());
        const retention_perc = this.applies_retention ? 0.04 : 0.00;
        
        try{
            let conceptos;
                if (this.invoiceBreakdown === false){
                    conceptos = { 
                        //Custom product key
                        1:{
                        clave_prod_serv:this.prefered_product_key_traffic,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_traffic,    
                        descripcion: 'SERVICIO DE LAS ORDENES ' + orderNumbers,
                        valor_unitario: grandSubtotal.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: (grandSubtotal * 0.16).toFixed(2),
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:retention_perc.toString(),
                                importe: (grandSubtotal * retention_perc).toFixed(2)
                            }
                        },
                    }};
                } else {
                    const grandTraffic = this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['cost_by_traffic'])|| 0), 0);
                    const grandPermissions = this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['cost_by_permissions'])|| 0), 0);
                    const grandManeuvers = this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['cost_by_maneuvers'])|| 0), 0);
                    const grandHr = this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['cost_by_hr']) * Number(note['total_hours']) || 0), 0);
                    // const grandImpoundLot = this.selectedNoteDetails.reduce((sum, note) => Number(sum) + (Number(note['lot_total_cost'])|| 0), 0);
                    conceptos = { 
                        1:{ //TRAFICO
                        clave_prod_serv:this.prefered_product_key_traffic,
                        cantidad:'1',
                        clave_unidad: this.prefered_measurement_unit_traffic,     
                        descripcion: 'TRAFICO DE LAS ORDENES ' + orderNumbers,
                        valor_unitario: grandTraffic.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandTraffic * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:retention_perc.toString(),
                                importe: (grandTraffic * retention_perc).toFixed(2)
                            }
                        },
                    },
                    2: {//MANOIBRAS
                        clave_prod_serv:this.prefered_product_key_maneuvers,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_maneuvers,    
                        descripcion: 'MANIOBRAS DE LAS ORDENES ' + orderNumbers,
                        valor_unitario: grandManeuvers.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandManeuvers * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:retention_perc.toString(),
                                importe: (grandManeuvers * retention_perc).toFixed(2)
                            }
                        },
                    },
                    3:{//PERMISSIONS
                        clave_prod_serv:this.prefered_measurement_unit_traffic,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_traffic,   
                        descripcion: 'PERMISOS DE LAS ORDENES ' + orderNumbers,
                        valor_unitario: grandPermissions.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandPermissions * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:retention_perc.toString(),
                                importe: (grandPermissions * retention_perc).toFixed(2)
                            }
                        },
                    },
                    4:{//Costo por serivicio
                        clave_prod_serv:this.prefered_product_key_service_hours,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_service_hours,    
                        descripcion: 'COSTO POR SERVICIO DE LAS ORDENES ' + orderNumbers,
                        valor_unitario: grandHr.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandHr * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:retention_perc.toString(),
                                importe: (grandHr * retention_perc).toFixed(2)
                            }
                        },
                    },
                    // 5:{//PENSION
                    //     clave_prod_serv:'78181702',
                    //     cantidad:'1',
                    //     clave_unidad:'ACT',    
                    //     descripcion: 'PENSION DE LAS ORDENES ' + orderNumbers,
                    //     valor_unitario: grandImpoundLot.toString(),
                    //     objeto_imp: '02', 
                    //     no_identificacion: '8635',
                    //     impuestos:{
                    //         traslados:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.16',
                    //             importe: grandImpoundLot * .16,
                    //         },
                    //         retenciones:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.04',
                    //             importe: grandImpoundLot * .04
                    //         }
                    //     },
                    // }
                };
                }
                //comenzar diccionario con info
                this.billingInfo = {
                    cartaporte:0,
                    receptor:{
                        rfc: client.data['client_rfc'], 
                        nombre: client.data['client_name'],
                        uso_cfdi: firstNote['invoice_use'],
                        domicilio_fiscal_receptor: client.data['client_address']['codigoPostal'],
                        regimen_fiscal_receptor: client.data['tax_regime']
                    },
                    metodo_pago: firstNote['payment_method'],
                    forma_pago: firstNote['payment_type'], 
                    serie: vectorNoteID[0], 
                    folio: vectorNoteID[2], 
                    conceptos: conceptos,
                    // { 
                    //     1:{
                    //     clave_prod_serv:claveProdServ,
                    //     cantidad:'1',
                    //     clave_unidad:'ACT',    
                    //     descripcion: 'SERVICIO DE LAS ORDENES ' + orderNumbers,
                    //     valor_unitario: grandSubtotal.toString(),
                    //     objeto_imp: '02', 
                    //     no_identificacion: '8635',
                    //     impuestos:{
                    //         traslados:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.16',
                    //             importe: grandSubtotal * .16,
                    //         },
                    //         retenciones:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.04',
                    //             importe: grandSubtotal * .04
                    //         }
                    //     },
                    // }},
                    
                };
                return this.invoiceStamping(this.billingInfo)
            
            .then(stampedInvoice => {
                return stampedInvoice;
            })
            .catch(error => {
                console.error('Error fetching locations:', error);
            });
        } catch (error) {
        console.error('Error constructing billing info:', error);
    }
},
        invoiceStamping(billingInfo) {
            const apiDirectory = process.env.API_DIRECTORY;
            return axios.post(`${apiDirectory}/invoice-stamping`, billingInfo)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        },
        async generateInvoiceDocument(){
            const apiDirectory = process.env.API_DIRECTORY;
            const signedXML = await this.saveBilling();
            const firstNote = this.selectedNoteDetails[0];
            const vectorNoteID = firstNote['note_ident'].split('-');
            // const firstAssignation = this.assignationsData[0];
            // const dateDifferenceHours = Math.ceil(Math.abs(new Date(firstAssignation.end_time) - new Date(firstAssignation.start_time)) / (1000 * 60 * 60));
            axios.post(`${apiDirectory}/generate-invoice-documents`, 
                        {signedXML: signedXML,
                        // plh_authorize: this.localNoteDetails.approved_by,
                        plh_operatorName: 'VARIOS',
                        // plh_operatorNumber: firstAssignation.operator.employee_ident,
                        plh_orderId: 'VARIOS',
                        // plh_unitNumber: firstAssignation.unit.unit_short_id,
                        // plh_plates: this.localNoteDetails.vehicle_plates,
                        // plh_vehicleBrand: this.selectedBrandId + ' ' + this.selectedSubBrandId,
                        // plh_vehicleModel: this.localNoteDetails.vehicle_model + '  ' + this.localNoteDetails.vehicle_color,
                        // plh_niv: this.localNoteDetails.vehicle_engine_series,
                        // plh_insuredName: this.localNoteDetails.vehicle_owner_name,
                        // plh_record: this.localNoteDetails.file_number,
                        plh_insurancePolicy: 'VARIOS', //this.localNoteDetails.insurance_policy,
                        // plh_destination: this.localNoteDetails.destination.neighborhood + ', ' + this.localNoteDetails.destination.city + ' ' + this.localNoteDetails.destination.state,
                        // plh_origin: this.localNoteDetails.source.neighborhood + ', ' + this.localNoteDetails.source.city + ' ' + this.localNoteDetails.source.state,
                        // plh_maneuver: ' ',
                        // plh_startTime: firstAssignation.start_time,
                        // plh_arrivalTime: firstAssignation.arrival_time,
                        // plh_endTime: firstAssignation.end_time,
                        // plh_retention:this.localNoteDetails.retention,
                        plh_totalTime: '0.0', //dateDifferenceHours,
                        plh_discount: '0.00',
                        note_type: vectorNoteID[0]
                        }, 
                {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'factura_multiformato.zip');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('Error generating files', error)
            });
        },
    }
}
</script>