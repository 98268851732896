<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button" />
    </div>
    <div class="text-operations"></div>
    <div class="form-section">
        <h3>Generación de papeleta de remolque</h3>
        <img src="@/assets/filtros.png">
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group-filters">
                <label>Seleccione la fecha de inicio</label>
                <input type="datetime-local" v-model="startDate" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                <label>Seleccione la fecha final</label>
                <input type="datetime-local" v-model="endDate" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="client_name">Cliente</label>
                <input type="text" id="client_name" v-model="clientName" placeholder="Ingrese el cliente a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="file_number">Expediente</label>
                <input type="text" id="file_number" v-model="fileNumber" placeholder="Ingrese el expediente a buscar" />
            </div>
        </div>
        <div class="form-section">
            <div class="form-group-filters">
                <label for="clerk">Capturista</label>
                <input type="text" id="clerk" v-model="clerk" placeholder="Ingrese el capturista a buscar" />
            </div>
        </div>
    </div>
    <button type="submit" class="btn-submit-save" @click="generateReport">Generar reporte</button>
</template>

<style>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}

.logo-button {
    position: absolute; 
    height: 90%; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}

.text-operations {
    margin-top: 10vh; /* Adjust this value to ensure it's below the nav bar */
    left: 0;
    width: 100%;
    height: 5vh;
    border-bottom: 1px solid black;
    background-color: #ffffff;
    z-index: 999; /* Ensure it's above other content */
    font-size: 30px;
    display: flex;
    justify-content: space-between; /* Adjust spacing between elements */
    align-items: center; /* Align items vertically */
    overflow: hidden; /* Prevent text overflow */
}

.text-operations h2 {
    font-size: inherit; /* Adjust font size as needed */
    margin: 0;
    padding: 0;
    margin-left: 2vh;
}

.form-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}

.form-section{
    flex-basis: calc(33% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 10px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
}

.form-section-images img{
    width: 100px;
    height: 100px;
}

.image-container {
    margin-top: 10vh;
    width: 100%;
    height: 200px;
}

.image-container-upload {
    margin-top: 2vh;
    margin-bottom: 3vh;
    width: 100%;
    height: 200px;
}

.form-group-filters-images{
    height: 8vh;
}

.image-container-upload img{
    width: 200px !important;
    height: 200px !important;
}

.form-section:last-child {
    margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
}

h2 {
    margin-bottom: 20px; /* Espacio debajo de los títulos de sección */
}

.form-group-filters label {
    text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
    font-weight: bold; /* Hace que el texto de la etiqueta sea negrita */
    display: inline-block; /* Hace que la etiqueta sea en bloque pero alinee su contenido */
    width: 400%; /* Asegura que la etiqueta ocupe todo el ancho disponible */
}

.form-section img{
    width: 40px;
    height: 40px;
}

.form-group-filters input{
    width: 100%;
}

.checkbox-group {
    display: flex;
    justify-content: flex-start; 
    gap: 5px; 
    font-weight: bold;
}

.checkbox-group input{
    margin-bottom: 1vh;
}

.table-container {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 0;
    padding: 1vh;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.6em; 
}

.table th, .table td {
    border-left: none;
    border-right: none;
    padding: 5px; 
    text-align: left;
}

.table tbody tr:first-child td {
    border-top: none;
}

.table tbody tr:hover td {
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}

.table tbody tr:last-child td {
    border-bottom: none;
}

.table thead input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border-width: 1px;
}

.table th{
    border-left: none;
    border-right: none;
    padding: 10px;
    text-align: left
}

.table td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.pagination-container {
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.pagination-container button {
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    margin: 0 10px;
    background-color: transparent; 
    border: none; 
}

.pagination-container button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.pagination-container span {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-container span.active {
    background-color: #FFC434;
}

.pagination-container button img {
    width: 25px; 
    height: auto; 
}
</style>

<script>
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2

export default {
    data() {
        return {
            startDate: '',
            endDate: '',
            clientName: '',
            fileNumber: '',
            clerk: '',
            blockEdit: false, // Set to true or false based on your requirements
        };
    },
    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        async generateReport() {
            const payload = {
                start_date: this.startDate,
                end_date: this.endDate,
                client_name: this.clientName,
                file_number: this.fileNumber,
                clerk: this.clerk,
            };

            try {
                const apiDirectory = process.env.API_DIRECTORY;
                // Set responseType to 'blob' to handle file download
                const response = await axios.post(`${apiDirectory}/reports/assistance/`, payload, {
                    responseType: 'blob', // Important for file download
                });

                // Create a URL for the blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'assistance_report.csv'); // Set the desired file name

                // Append to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Cleanup
                link.remove();
                window.URL.revokeObjectURL(url);

                console.log('Report generated and downloaded successfully.');
            } catch (error) {
                const errorMessage = error.response ? error.response.data.error : error.message;
                Swal.fire({
                    icon: 'error',
                    title: 'Error Generando Reporte',
                    text: errorMessage,
                });
            }
        },
    },
};
</script>
