<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="create-order">
        <h3 class="order-type">Orden de Trabajo: {{ localNoteDetails.note_number }}</h3>
        <div class="form-group1">
                        <input type="text1" id="order_number" placeholder="Ingrese un numero" v-model="localNoteDetails.note_number" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
        <button @click="showForm('initialData')">Datos iniciales y Cobro</button>
        <button @click="showForm('assignment')">Asignación</button>
        <button @click="showForm('billing')">Facturación</button>
    </div>
    <div v-if="currentForm === 'initialData'">
        <div class="main-data-container">
            <div class="client-data-container">
                <div class="tittle">
                    <h4>Datos del cliente</h4>
                    <img src="@/assets/client-icon.png">
                </div>
                <div class="client-container">
                    <div class="form-group1">
                        <label for="client_name">Cliente</label>
                        <input type="text" id="client_name" v-model="client_name" @input="filterClients" @keydown.down.prevent="navigateClients(1)" @keydown.up.prevent="navigateClients(-1)" @keydown.enter.prevent="selectHighlightedClient" autocomplete="off" />
                    </div>
                    <div class="dropdown" v-if="filteredClients.length" ref="dropdownContainer" style="position: absolute; z-index: 1050; background: white; max-height: 200px; overflow-y: auto;" v-click-outside="closeDropdown">
                        <li v-for="(client, index) in filteredClients" :key="client.id" :class="{ 'active': index === highlightedIndex }" @click="selectClient(client)" ref="dropdownItem">
                            {{ client.client_name }}
                        </li>
                    </div>
                </div>
                <div class="button-add-new-client" v-if="!isClientSelected">
                    <button @click="toggleNewClientForm" class="btn-submit-save" v-if="!addNewClient" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Agregar nuevo cliente</button>
                </div>
                <div class="data-client" v-if="isClientSelected">
                    <div class="data-container">
                        <div class="form-group1">
                            <label for="client_street">Calle</label>
                            <label>{{ clientDetails.client_address.street }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="client_codigoPostal">C.P</label>
                            <label>{{ clientDetails.client_address.codigoPostal }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="client_neighborhood">Colonia</label>
                            <label>{{ clientDetails.client_address.neighborhood }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="client_city">Ciudad</label>
                            <label>{{ clientDetails.client_address.city }}</label>
                        </div>
                    </div>
                    <div class="data-container">
                        <div class="form-group1">
                            <label for="client_state">Estado</label>
                            <label>{{ clientDetails.client_address.state }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="client_rfc">RFC</label>
                            <label>{{ clientDetails.client_rfc }}</label>
                        </div>
                        <div class="form-group1">
                            <label for="phone">Teléfono</label>
                            <label>{{ clientDetails.phone }}</label>
                        </div>
                    </div>
                </div>
                <div class="data-client" v-if="addNewClient">
                    <div class="data-container">
                        <div class="checkbox-container">
                                <label class="switch">
                                    <input type="checkbox" id="isVip" v-model="is_privileged">
                                    <span class="slider round"></span>
                                </label>
                                <label for="isVip"> Recurrente </label>
                                <!--<input type="checkbox" id="isVip" v-model="is_privileged" />-->   
                        </div>
                        <div class="form-group1">
                            <label for="client_street">Calle</label>
                            <input type="text1" id="client_street" v-model="clientDetails.client_address.street" />
                        </div>
                        <div class="form-group1">
                            <label for="client_codigoPostal">C.P</label>
                            <input type="text1" id="client_codigoPostal" placeholder="Ej. 64849" v-model="clientDetails.client_codigoPostal" @keypress="validatePositiveIntegerField"/>
                        </div>
                        <div class="form-group1">
                            <label for="client_neighborhood">Colonia</label>
                            <input type="text1" id="client_neighborhood" placeholder="Ej. Tecnológico" v-model="client_neighborhood" />
                        </div>
                    </div>
                    <div class="data-container">
                        <div class="form-group1">
                            <label for="client_city">Ciudad</label>
                            <input type="text1" id="client_city" placeholder="Ingrese una ciudad" v-model="client_city" />
                        </div>
                        <div class="form-group1">
                            <label for="client_state">Estado</label>
                            <select class="state-dropdown" id="client_state" v-model="client_state">
                                <option v-for="state in allStates" :key="state.name" :value="state.name">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group1">
                            <label for="client_rfc">RFC</label>
                            <input type="text1" id="client_rfc" placeholder="Ingrese el RFC" v-model="clientDetails.client_rfc" />
                        </div>
                        <div class="form-group1">
                            <label for="phone">Teléfono</label>
                            <input type="text1" id="phone" v-model="clientDetails.phone" />
                        </div>
                    </div>
                    <div class="buttons-for-saveClient">
                        <button type="submit" class="btn-submit-save" @click="saveNewClient">Guardar datos del nuevo cliente</button>
                        <button @click="cancelNewClient" class="btn-submit-cancel">Cancelar</button>
                    </div>
                </div>
                <div class="tittle">
                    <h4>Origen</h4>
                    <img src="@/assets/ubi-icon.png">
                </div>
                <div class="data-container">
                    <div class="checkbox-container">
                        <div class="checkbox-group">
                            <input type="checkbox" id="toggleFields" v-model="showOptionalFields">
                            <label for="toggleFields">Servicio carretero</label>
                        </div>
                    </div>
                    <div class="form-group1" >
                        <label for="source_street">Calle y número</label>
                        <input type="text1" id="source_street" v-model = "localNoteDetails.source_street" placeholder="Ingrese la calle y número principal" />
                    </div>
                    <!-- <div class="form-group1" v-show="!showOptionalFields">
                        <label for="source_number">Número</label>
                        <input type="text1" id="source_number" v-model = "localNoteDetails.source_street_number" placeholder="Ingrese el número" />
                    </div> -->
                    <div class="form-group1" v-show="!showOptionalFields">
                        <label for="source_inbtw">Entrecalles</label>
                        <input type="text1" id="source_inbtw" v-model = "localNoteDetails.source_between_streets" placeholder="Ingrese entre que calles se encuentra" />
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1" v-show="!showOptionalFields">
                        <label for="source_neighborhood">Colonia</label>
                        <input type="text1" id="source_neighborhood" v-model = "localNoteDetails.source_neighborhood" placeholder="Ingrese la colonia" />
                    </div>
                    <div class="form-group1">
                        <label for="source_postal_code">C.P</label>
                        <input type="text1" id="source_postal_code" v-model = "localNoteDetails.source_zip_code" placeholder="Ingrese el código postal" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group1" v-show="!showOptionalFields" style="position: relative;">
                        <label for="source_municipality">Municipio</label>
                        <input type="text1" id="source_municipality" v-model="localNoteDetails.source.city" @input="sourceFilterMunicipalities" placeholder="Ingrese el municipio" autocomplete="off" />
                        <ul class="muni-dropdown" v-if="sourceFilteredMunicipalities.length" v-click-outside="closeDropdown">
                            <li v-for="(municipality, index) in sourceFilteredMunicipalities" :key="index" @click="sourceSelectMunicipality(municipality)">
                                {{ municipality }}
                            </li>
                        </ul>
                    </div>
                    <div class="form-group1" v-show="!showOptionalFields">
                        <label for="source_state">Estado</label>
                        <select class="state-dropdown" id="source_state" v-model="localNoteDetails.source_state">
                            <option v-for="state in allStates" :key="state.name" :value="state.name">
                                {{ state.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group1" style="display: inline-flex; align-items: center; gap: 10px; width: auto;">
                    <label for="source_notes" style="white-space: nowrap;">Observaciones</label>
                    <textarea id="source_notes" rows="1" v-model = "localNoteDetails.source_references" placeholder="Ingrese información adicional" style="resize: both; flex-shrink: 0; width: 320px; font-size: var(--text-size, 11px);"></textarea>
                </div>
                <div class="data-container">
                    <div class="form-group" style="display: inline-block; width: auto;">
                        <!-- <label for="quick_origin">Origen</label> -->
                        <textarea id="quick_origin" rows="1" v-model = "localNoteDetails.quick_origin" placeholder="Ingrese el origen" style="resize: both; overflow: auto; min-width: 785px; min-height: 50px; max-width: 100%; font-size: var(--text-size, 11px);"></textarea>
                    </div>
                </div>
                <div class="tittle">
                    <h4>Datos de la carga</h4>
                    <img src="@/assets/contenedor.png">
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="????">Ancho</label>
                        <input type="text1" id="????" placeholder="Ingrese el ancho de la carga" v-model="localNoteDetails.forklift_width" @keypress="validateFloatField"/>
                    </div>
                    <div class="form-group1">
                        <label for="????">Alto</label>
                        <input type="text1" id="????" placeholder="Ingrese el alto de la carga" v-model="localNoteDetails.forklift_height" @keypress="validateFloatField"/>
                    </div>
                    <div class="form-group1">
                        <label for="???">Largo</label>
                        <input type="text1" id="???" placeholder="Ingrese el largo de la carga" v-model="localNoteDetails.forklift_lon" @keypress="validateFloatField"/>
                    </div>
                    <div class="form-group1">
                        <label for="???">Peso</label>
                        <input type="text1" id="???" placeholder="Ingrese el peso de la carga" v-model="localNoteDetails.forklift_weight" @keypress="validateFloatField"/>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="maneuver_to_perform">Maniobra a realizar</label>
                        <textarea id="source_notes" rows="1" v-model = "localNoteDetails.maneuver_to_perform" placeholder="Ingrese detalles sobre la maniobra que se va a realizar"></textarea>
                    </div>  
                    <div class="form-group1" >
                        <label for="forklift_capacity">Capacidad del montacargas</label>
                        <input type="text1" id="forklift_capacity" v-model = "localNoteDetails.forklift_capacity" placeholder="Ingrese la capacidad del montacargas" />
                    </div>
                    <div class="form-group1">
                        <label for="sucursal">Sucursal</label>
                        <select class="state-dropdown" id="sucursal" v-model="localNoteDetails.sucursal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="ASISTENCIA AUXILIO VIAL">ASISTENCIA AUXILIO VIAL</option>
                            <option value="ASISTENCIA AUXILIO VIAL FORANEAS">ASISTENCIA AUXILIO VIAL FORANEA</option>
                            <option value="ASISTENCIAS FORANEAS">ASISTENCIAS FORANEAS</option>
                            <option value="ASISTENCIAS">ASISTENCIAS</option>
                            <option value="ASISTENCIAS PARTICULAR">ASISTENCIAS PARTICULAR</option>
                            <option value="AUTOPISTA LAREDO">AUTOPISTA LAREDO</option>
                            <option value="CANCELADOS">CANCELADOS</option>
                            <option value="CONTADO">CONTADO</option>
                            <option value="DETENIDOS">DETENIDOS</option>
                            <option value="ELECTRONICA INDUSTRIA">ELECTRONICA INDUSTRIA</option>
                            <option value="ELECTRONICA REMOLQUE">ELECTRONICA REMOLQUE</option>
                            <option value="FILIALES">FILIALES</option>
                            <option value="FORANEOS">FORANEOS</option>
                            <option value="REVOLUCION">REVOLUCION</option>
                            <option value="HYLSA">HYLSA</option>
                            <option value="INSTALACION">INSTALACION</option>
                            <option value="LOTE RIO ESCONDIDO"> LOTE RIO ESCONDIDO</option>
                            <option value="LOTE SALTILLO">LOTE SALTILLO</option>
                            <option value="LOTE SANTA CATARINA Q-0420"> LOTE SANTA CATARINA Q-0420</option>
                            <option value="LOTE SANTA ROSA"> LOTE SANTA ROSA</option>
                            <option value="LOTE CHINA">LOTE CHINA</option>
                            <option value="LOTE GUADALUPE">LOTE GUADALUPE</option>
                            <option value="LOTE GUADALUPE FEDERAL">LOTE GUADALUPE FEDERAL</option>
                            <option value="LOTE JARDINES">LOTE JARDINES</option>
                            <option value="LOTE REVOLUCION">LOTE REVOLUCION</option>
                            <option value="LOTE SANTA CATARINA">LOTE SANTA CATARINA</option>
                            <option value="MANTENIMIENTO">MANTENIMIENTO</option>
                            <option value="METRO GRUAS">METRO GRUAS</option>
                            <option value="NOGALAR">NOGALAR</option>
                            <option value="OVERHAULING">OVERHAULING</option>
                            <option value="REFACTURACION">REFACTURACION</option>
                            <option value="REMOLQUE">REMOLQUE</option>
                            <option value="SAN RAFAEL">SAN RAFAEL</option>
                            <option value="SUC SANTA CATARINA">SUC SANTA CATARINA</option>
                            <option value="TRANSITO SANTA CATARINA">TRANSITO SANTA CATARINA</option>
                            <option value="VENTAS">VENTAS</option>
                            <option value="VENTA DE ACCESORIOS">VENTA DE ACCESORIOS</option>
                            <option value="VENTA DE EQUIPO USADO">VENTA DE EQUIPO USADO</option>
                            <option value="Z ALFONSO REYES">Z ALFONSO REYES</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="payment-data-container">
                <div class="tittle">
                    <h4>Costos</h4>
                    <img src="@/assets/cost-icon.png">
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="cost_by_hr">Costo por hora</label>
                        <input type="text1" id="cost_by_hr" v-model="localNoteDetails.cost_by_hr" placeholder="Ingrese el costo por el tiempo utilizado" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="total_hours">Horas totales</label>
                        {{ localNoteDetails.total_hours }}
                    </div>
                    <div class="form-group1">
                        <label for="cost_by_traffic">Costo por tráfico</label>
                        <input type="text1" id="cost_by_traffic" v-model="localNoteDetails.cost_by_traffic" placeholder="Ingrese el costo por tráfico" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="cost_by_maneuvers">Maniobras</label>
                        <input type="text1" id="cost_by_maneuvers" v-model="localNoteDetails.cost_by_maneuvers" placeholder="Ingrese el costo por las maniobras realizadas" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="cost_by_permissions">Costo por permisos</label>
                        <input type="text1" id="cost_by_permissions" v-model="localNoteDetails.cost_by_permissions" placeholder="Ingrese el costo por permisos" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="subtotal">Subtotal</label>
                        <input type="text1" id="subtotal" v-model="localNoteDetails.subtotal" placeholder="Ingrese el subtotal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="iva">IVA</label>
                        <input type="text1" id="iva" v-model="localNoteDetails.iva" placeholder="Ingrese el iva" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group1">
                        <label for="total">Total</label>
                        <input type="tel1" id="total" v-model="localNoteDetails.total" placeholder="Ingrese el total" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="internal_payment_type">Tipo de pago</label>
                        <select class="state-dropdown" type="tel" id="internal-payment-type" v-model="localNoteDetails.internal_payment_type" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="4">4 - Crédito</option>
                            <option value="0">0 - Otros</option>
                        </select>
                    </div>
                    <div class="form-group1">
                        <label for="payment_method">Método de pago</label>
                        <select class="state-dropdown" type="tel" id="payment_method" v-model="localNoteDetails.payment_method" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="PPD">PAGO EN PARCIALIDADES O DIFERIDO</option>
                            <option value="PUE">PAGO EN UNA EXHIBICIÓN</option>
                        </select>
                    </div>
                    <div class="form-group1">
                        <label for="payment_type">Forma de pago (SAT)</label>
                        <select class="state-dropdown" type="tel" id="payment-type" v-model="localNoteDetails.payment_type" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="99" v-if="payment_method === 'PPD'">POR DEFINIR</option>
                            <template v-if="payment_method !== 'PPD'">
                                <option value="99">POR DEFINIR</option>
                                <option value="04">TARJETA DE CRÉDITO</option>
                                <option value="03">TRANSFERENCIA</option>
                                <option value="01">EFECTIVO</option>
                                <option value="28">TARJETA DE DÉBITO</option>
                                <option value="02">CHEQUE NOMINATIVO</option>
                            </template>
                        </select>
                    </div>
                    <div class="form-group1">
                        <label for="invoice_use">CFDI</label>
                        <select class="state-dropdown" id="invoice_use" v-model="localNoteDetails.invoice_use" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                            <option value="G01">ADQUISICIÓN DE MERCANCÍAS</option>
                            <option value="G02">DEVOLUCIOINES, DESCUENTOS O BONIFICACIONES</option>
                            <option value="G03">GASTOS EN GENERAL</option>
                            <option value="I01">CONSTRUCCIONES</option>
                            <option value="I02">MOBILIARIO Y EQUIPO DE OFICINA POR INVERSIONES</option>
                            <option value="I03">EQUIPO DE TRANSPORTE</option>
                            <option value="I04">EQUIPO DE CÓMPUTO Y ACCESORIOS</option>
                            <option value="I05">DADOS, TROQUELES, MOLDES, MATRICES Y HERRAMENTAL</option>
                            <option value="I06">COMUNICACIONES TELEFÓNICAS</option>
                            <option value="I07">COMUNICACIONES SATELITALES</option>
                            <option value="I08">OTRA MAQUINARIA Y EQUIPO</option>
                            <option value="D01">HONORARIOS MÉDICOS, DENTALES Y GASTOS HOSPITALARIOS</option>
                            <option value="D02">GASTOS MÉDICOS POR INCAPACIDAD O DISCAPACIDAD</option>
                            <option value="D03">GASTOS FUNERALES</option>
                            <option value="D04">DONATIVOS</option>
                            <option value="D05">INTERESES REALES EFECTIVAMENTE PAGADOS POR CRÉDITOS HIPOTECARIOS</option>
                            <option value="D06">APORTACIONES VOLUNTARIAS AL SAR</option>
                            <option value="D07">PRIMAS POR SEGUROS DE GASTOS MÉDICOS</option>
                            <option value="D08">GASTOS DE TRANSPORTACIÓN ESCOLAR OBLIGATORIA</option>
                            <option value="D09">DEPÓSITOS EN CUENTAS PARA EL AHORRO </option>
                            <option value="D10">PAGOS POR SERVICIOS EDUCATIVOS</option>
                            <option value="S01">SIN EFECTOS FISCALES</option>
                            <option value="CP01">PAGOS</option>
                            <option value="CN01">NÓMINA</option>
                        </select>
                    </div>
                </div>
                <div class="data-container">
                    <div class="form-group1">
                        <label for="payment-notes">Notas del pago</label>
                        <textarea id="payment-notes" rows="1" placeholder="Ingrese notas del pago" style="font-size: var(--text-size, 11px);"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-footer">
            <form @submit.prevent="guardarDatos">
                <table>
                    <thead>
                    <tr>
                        <th>No. unidad</th>
                        <th>Nombre de unidad</th>
                        <th>KM Inicio</th>
                        <th>KM Fin</th>
                        <th>No. operador</th>
                        <th>Nombre de operador</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(grupo, index) in gruposCampos" :key="index">
                        <td>
                            <input type="text" v-model="grupo.unit" placeholder="Unidad" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                        </td>
                        <td>
                            <label :class="{ 'grayed-out': blockEdit }">{{ grupo.unidad_name || 'Nombre Unidad' }}</label>
                        </td>
                        <td>
                            <input type="number" v-model.number="grupo.start_km" placeholder="KM Inicio" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                        </td>
                        <td>
                            <input type="number" v-model.number="grupo.end_km" placeholder="KM Fin" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                        </td>
                        <td>
                            <input type="text" v-model="grupo.operator" placeholder="Operador" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" />
                        </td>
                        <td>
                            <label :class="{ 'grayed-out': blockEdit }">{{ grupo.operador_name + ' ' + grupo.operador_last_name || 'Nombre Operador' }}</label>
                        </td>
                        <td>
                            <button type="button" @click.prevent="eliminarCampos(index)" class="button-delete-operator" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" > Eliminar </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <button @click.prevent="agregarCampos" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Agregar más operadores</button>
                <button type="submit" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" :style="{ backgroundColor: blockEdit ? '#d3d3d3' : '#00ff1e', color: 'black'}">Guardar asignaciones</button>
            </form>
        </div>
    </div>

    <div v-if="currentForm === 'assignment'">
        <div class="form-container">
            <div class="form-section">
                <div class="time-data">
                    <h3>Datos de tiempo</h3>
                    <img src="@/assets/time-icon.png">
                
                    <div v-for="(assignationData, index) in assignationsData" :key="index">
                        <div class="void"></div>
                        <div class="form-group">
                            <div><b>Unidad:</b> {{ assignationData.unidad }}</div>
                            <div> {{ assignationData.unidad_name }}</div>
                            <div><b>Operador:</b> {{ assignationData.operador }}</div>
                            <div>{{ assignationData.operador_name }} {{ assignationData.operador_last_name }}</div>
                            <label>Fecha y Hora de Inicio</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.start_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.start_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Arribo</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.arrival_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.arrival_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Contacto</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.contact_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.contact_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <label>Fecha y Hora de Termino</label>
                            <div class="text-color-for-date"><label>{{ formatDateTime(assignationData.end_time) }}</label></div>
                            <input type="datetime-local" v-model="assignationData.end_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                            <div class="void"></div>
                            <button class="btn-submit-save" @click="saveAssignation(assignationData,index)" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Actualizar</button>
                            <div class="void"></div>
                            <div class="form-group-final"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <h3>Asignación de unidad y operador</h3>
                <img src="@/assets/operator-assignament-icon.png">
                <div class="map-container">
                    <div class="map">
                            <Map
                            :center="mapCenter"
                            :zoom="10"
                            map-type-id="roadmap"
                            style="width: 50vw; height: 50vh"
                            >
                            <!-- Display blue markers for vehicle locations -->
                            <Marker
                                v-for="location in filteredBlueLocations"
                                :key="'blue_' + location.vehicle_plate"
                                :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
                                :icon="require('@/assets/grua-map-icon-marker-gray.png')"
                                :options="{ zIndex: 5 }" 
                                @click="openInfoWindow(location)"
                            />

                            <!-- Display red marker at a specified location -->
                            <Marker
                                v-if="showRedMarker"
                                :position="this.redMarkerPosition.value"
                                :icon="require('@/assets/red-marker.png')"
                                :options="{ zIndex: 99999 }" 
                            />

                            <!-- Display green markers for specific vehicle locations -->
                            <Marker
                                v-for="location in filteredGreenLocations"
                                :key="'green_' + location.vehicle_plate"
                                :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
                                :icon="require('@/assets/grua-map-icon-marker.png')"
                                :options="{ zIndex: 9999 }" 
                                @click="openInfoWindow(location)"
                            />

                        <!-- InfoWindow component -->
                        <InfoWindow
                            :options="{ content: infoContent }"
                            :position="infoPosition"
                            :opened="infoWindowOpened"
                            @closeclick="infoWindowOpened = false"
                        />
                        </Map>
                </div>
            </div>
        </div>
        <div class="form-section">
            
            <div class="void"></div>
            <form @submit.prevent="guardarDatos">
                <button @click.prevent="agregarCampos" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Agregar más operadores</button>
                <button type="button" @click.prevent="eliminarCampos()" class="button-delete-operator" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Eliminar</button>
                <div v-for="(grupo, index) in gruposCampos" :key="index">
                    <div class="form-group">
                        <label :for="'unidad-' + index">Unidad asignada (Número económico)</label>
                        <input type="text" :id="'unidad-' + index" v-model="grupo.unit" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label :for="'operador-' + index">Operador asignado (Número de empleado)</label>
                        <input type="text" :id="'operador-' + index" v-model="grupo.operator" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                    </div>
                    <div class="form-group">
                        <label :for="'kmInicio-' + index">Km inicio</label>
                        <input type="text" :id="'kmInicio-' + index" v-model.number="grupo.start_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group">
                        <label :for="'kmFin-' + index">Km fin</label>
                        <input type="text" :id="'kmFin-' + index" v-model.number="grupo.end_km" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group-final"></div>
                </div>
                <button type="submit" class="btn-submit-save" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">Guardar</button>
            </form>
        </div>
    </div>
    </div>

    <div v-if="currentForm === 'billing'">
        <div class="form-section">
            <h3>Facturación</h3>
            <img src="@/assets/cost-icon.png">
        </div>
        <div class="form-container">
            
            <div class="form-section">
                <h4>Datos de la factura</h4>
                <div class="form-group">
                    <label for="prefered_product_key">CLAVE DE PRODUCTO</label>
                    <select id="prefered_product_key" v-model="this.prefered_product_key" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="72154503">72154503 - SERVICIO DE ALQUILER DE GRUAS</option><!--GRUA-->
                        <option value="78121603">78121603 - TARIFA DE LOS FLETES</option><!--KILOMETRAJE-->
                        <option value="90121800">90121800 - SERVICIO DE ASISTENCIA DE EMERGENCIA EN VIAJES</option> <!--PASO DE CORRIENTE-->
                        <option value="81141601">81141601 - LOGÍSTICA</option> <!--MANIOBRA . MONTACARGAS 15 MIL LBS<-->
                        <option value="78101803">78101803 - SERVICIO DE TRANSPORTE DE VEHÍCULOS</option> <!--TRAFICO-->
                </select> 
                </div>
                <div class="form-group">
                    <label for="prefered_measurement_unit">UNIDAD DE MEDIDA</label>
                    <select id="prefered_measurement_unit" v-model="this.prefered_measurement_unit" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="ACT">ACT - TRAFICO</option>
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                </select> 
                </div>
            </div>
            <div class="form-section">
                <h4>Datos de la cartaporte</h4>
                <div class="checkbox-group">
                    <input type="checkbox" id="toggleFields1" v-model="cartaporte" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                    <label for="toggleFields1">Generar carta porte</label>
                </div>
                <div class="form-group">
                    <label for="mercancia_descripcion">Descripción de mercancía</label>
                    <input type="text" id="mercancia_descripcion" placeholder="Ingrese la descripción de mercancía" v-model="localNoteDetails.mercancia_descripcion" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="mercancia_valor">Valor del vehículo</label>
                    <input type="text" id="mercancia_valor" placeholder="Ingrese el valor del vehículo" v-model="localNoteDetails.mercancia_valor" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label for="unit_inventory">UNIDADES DE MEDIDA DE LA MERCANCÍA</label>
                    <select id="unit_inventory" v-model="unit_inventory" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="E48">E48 - UNIDAD DE SERVICIO</option>
                        <option value="H87">H87 - PIEZA</option>
                    </select>
                </div>
            </div>
        </div>
        <button class="btn-submit-savebilling-edit-assistance" v-if="localNoteDetails.status === 2" @click="generateInvoiceDocument">Generar factura</button>
    </div>
    <button type="submit" class="btn-submit-save-edit-forklift" @click="saveNote" :disabled="blockEdit||!isFormValid||saving_note" :class="{ 'grayed-out-missing-input':!isFormValid,'grayed-out': blockEdit, 'saving-note':saving_note }">Guardar</button>
    <button type="button" class="btn-submit-back" onclick="history.back()">Regresar</button>
    <button type="submit" class="btn-submit-complete" @click="completeNote" v-if="localNoteDetails.note_status !== 4 && allTimesCompleted">
        Completar nota
    </button>
    <button type="submit" class="btn-submit-close" @click="cancelNote" v-if="localNoteDetails.note_status !== 2">Cancelar nota</button>
    <button type="submit" class="btn-submit-reOpen" @click="reOpenNote" v-if="localNoteDetails.note_status === 2 || localNoteDetails.note_status === 4">
        Reabrir nota
    </button>
</template>

<style scoped>
.muni-dropdown {
    position: absolute; /* Position relative to the parent container */
    top: 100%; /* Place it directly below the input field */
    left: 0; /* Align with the input field's left edge */
    z-index: 10000;
    width: 100%; /* Match the input field's width */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0; /* Remove any default padding */
    margin: 0; /* Remove the left margin */
}

.muni-dropdown li {
    padding: 8px 12px;
    cursor: pointer;
}

.muni-dropdown li:hover,
.muni-dropdown li.active {
    background-color: #007bff;
    color: #fff;
}
.dropdown {
    position: absolute;
    z-index: 10000;
    width: 15vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto; 
    margin-left: 20px;
    list-style: none;
}
.dropdown li {
    padding: 8px 12px;
    cursor: pointer;
}
.dropdown li.active {
    background-color: #007bff;
    color: #fff;
}
.main-data-container {
    display: flex;
    width: 100vw;
    height: auto;
    justify-content: space-between;
}
.client-data-container, .payment-data-container {
    width: 50vw;
    height: auto;
    padding: 10px 10px;
}
.tittle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #919191;
}
.tittle h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
}
.tittle img{
    display: flex;
    margin-left: 10px;
    width: 20px;
    height: 20px;
}
.client-container {
    width: 50vw;
}
.data-client {
    width: 50vw;
    height: auto;
}
.data-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.data-container .state-dropdown {
    z-index: 1000;
    width: 7vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto;
    position: relative;
}

.state-dropdown option, select {
    font-size: .9rem;
}
.buttons-for-saveClient {
    width: 50vw;
    height: auto;
}

.table-footer {
    display: flex;
    width: 99vw;
    height: auto;
    justify-content: space-between;
    margin: 10px;
}
.table-footer th {
    color: #000000;
    background-color: #5388b4;
}
.table-footer table {
    width: 50vw;
}
.checkbox-container {
    display: flex;
    align-items: center; /* Align items vertically */
    text-align: left; 
    font-weight: bold;
    width: 10vw;
    margin-left: 5px;
}
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.create-order{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-top: 10vh; 
    height: 5vh;
    border-bottom: 1px solid black;
    margin-left: 20px;
    margin-right: 10px;
}
.create-order button {
    padding: 5px;
    background-color: rgb(0, 17, 255);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.form-group {
    margin-bottom: 15px;
    width: 10vw;
}
.form-group1 {
    display: flex;
    width: 10vw;
    margin-left: 5px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
}
.form-group1 label {
    display: block;
    font-size: .7rem;
    color: #000000;
    width: 5vw;
}
.checkbox-group label {
    display: block;
    font-size: .7rem;
}
.checkbox-container label {
    display: block;
    font-size: .7rem;
}
input[type="text1"],
input[type="tel1"] {
    width: 7vw;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: .7rem;
    margin: 0;
    padding: 4px;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="text"],
input[type="tel"] {
    width: 20vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-submit-save {
    background-color: #00ff1e; /* Color del botón de enviar */
    color: #fff; /* Color del texto del botón */
    border: none;
    padding: 10px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-cancel {
    background-color: #ff0000; /* Color del botón de enviar */
    color: #fff; /* Color del texto del botón */
    border: none;
    padding: 10px 10px;
    border-radius: 4px;
    cursor: pointer;
}
.form-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}

.form-section, .form-section-images{
    flex-basis: calc(33% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 10px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
}

.form-section-images img{
    width: 100px;
    height: 100px;
}

.image-container {
    margin-top: 10vh;
    width: 100%;
    height: 200px;
}

.image-container-upload {
    margin-top: 2vh;
    margin-bottom: 3vh;
    width: 100%;
    height: 200px;
}

.form-group-images{
    height: 8vh;
}

.image-container-upload img{
    width: 200px !important;
    height: 200px !important;
}


.form-section:last-child {
    margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
}

h2 {
    margin-bottom: 20px; /* Espacio debajo de los títulos de sección */
}
.form-group label {
    text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
    font-weight: bold; /* Hace que el texto de la etiqueta sea negrita */
    display: inline-block; /* Hace que la etiqueta sea en bloque pero alinee su contenido */
    width: 100%; /* Asegura que la etiqueta ocupe todo el ancho disponible */
}
.form-section img{
    width: 40px;
    height: 40px;
}

.map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    padding: 20px;
}
.map {
    width: 50vw; /* Ancho inicial para pantallas grandes */
    height: 50vh; /* Altura inicial para pantallas grandes */
}


.report-map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    padding: 20px;
}
.report-map {
    width: 40vw; /* Ancho inicial para pantallas grandes */
    height: 50vh; /* Altura inicial para pantallas grandes */
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
}

th {
  background-color: #000000;
  color: #FFC434;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}

button {
    color: #ffffff;
    background-color: #FFC434;
    border: none;
    border-radius: 5px;
  /* Añadir estilos al botón si es necesario */
}
.void{
    height: 30px;
}
.time-data{
    display: grid;
    place-items: center;
}
textarea {
    width: 15vw; 
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical; 
}
.checkbox-group {
    display: flex;
    justify-content: flex-start; 
    gap: 5px; 
    font-weight: bold;
    width: 10vw;
    margin-left: 5px;   
}
.button-delete-operator{
    background-color: #ff0000;
    color: white;
    margin-left: 3vw;
}
.client-container .client-dropdown {
    position: absolute;
    z-index: 10000;
    width: 15vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto; 
    margin-left: 20px;
}
.form-group .client-dropdown {
    position: absolute;
    z-index: 1000;
    width: 20vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto; 
}
.client-dropdown div {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}
.client-dropdown div:hover {
    background-color: #f2f2f2;
}
.form-group select {
    width: 20vw; 
    padding: 8px; 
    margin-bottom: 10px; 
    border: 1px solid #ccc;
    border-radius: 4px; 
    background-color: white; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); 
    background-repeat: no-repeat;
    background-position: right 8px top 50%; 
    background-size: 12px; 
}
.form-group-final{
    margin-bottom: 2vh;
    border-bottom: 1px solid black;
}
.grayed-out {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
}
.grayed-out-missing-input {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.grayed-out-missing-input::after {
    content: "Los campos: Cliente, Marca, Submarca, Latitud y Longitud de origen, Latitud y Longitud de destino son obligatorios"; /* Text to show on mouse hover */
    position: absolute;
    top: -50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease;
}

.grayed-out-missing-input:hover::after {
    opacity: 1; /* Show the text on hover */
}
.saving-note {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.saving-note::after {
    content: "Espere a que se guarde la nota"; /* Text to show on mouse hover */
    position: absolute;
    top: -50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease;
}

.saving-note:hover::after {
    opacity: 1; /* Show the text on hover */
}
.text-color-for-date{
    color: rgb(21, 0, 255);
}
.btn-submit-save-edit-forklift {
    background-color: #00ff1e; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-savebilling-edit-forklift {
    background-color: #5d5d5d; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
}
.btn-submit-back {
    background-color: #ff7700; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-complete {
    background-color: #00eeff; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-close {
    background-color: #ff0000; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-reOpen {
    background-color: #0000ff; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
</style>

<script>
import { ref, reactive, onMounted, nextTick, computed } from 'vue'
import { Map, Marker, InfoWindow } from '@fawmi/vue-google-maps'
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    props: ['noteDetails', 'assignations','user_id'],
    components: {
        Map,
        Marker,
        InfoWindow
    },
    data() {
        const locations = ref([])
        const mapCenter = ref({ lat: 25.66831199290133, lng: -100.2802354639708 })
        const infoWindowOpened = ref(false);
        const infoContent = ref('');
        const infoPosition = reactive({ lat: 0, lng: 0 });
        const redMarkerPosition = ref({ lat: 25.689214, lng: -100.314343 }); // Red marker position DESTINATION
        const showRedMarker = ref(true); // Variable to control the red marker visibility

        // Vector of vehicle names for green markers
        const greenVehicleNames = ref(['254']); // Example vehicle names
        //console.log(greenVehicleNames.value);

        const fetchLocations = () => {
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/fetch-last-vehicle-locations`)
                .then(response => {
                    locations.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        }

        onMounted(() => {
            fetchLocations();
            setInterval(fetchLocations, 20000);
        })

        const openInfoWindow = (location) => {
            infoWindowOpened.value = false;
            infoContent.value = `
                <div>
                <h1>${location.vehicle_name}</h1>
                <p>Placas: ${location.vehicle_plate}</p>
                <p>Velocidad Actual: ${location.vehicle_speed} km/h</p>
                </div>
            `;
            infoPosition.lat = parseFloat(location.lat);
            infoPosition.lng = parseFloat(location.long);
            nextTick(() => {
                infoWindowOpened.value = true;
            });
        };

        // Computed property for filtering blue locations
        const filteredBlueLocations = computed(() => {
            return locations.value.filter(location => !this.greenVehicleNames.value.includes(location.vehicle_name));
        });

        // Computed property for filtering green locations
        const filteredGreenLocations = computed(() => {
            return locations.value.filter(location => this.greenVehicleNames.value.includes(location.vehicle_name));
        });

        return {
            blockEdit: false, //Linked to the note status, determines wheter the fields are enabled or not 
            localNoteDetails: {},
            greenVehicleNames,
            filteredBlueLocations,
            filteredGreenLocations,
            mapCenter,
            infoWindowOpened,
            infoContent,
            infoPosition,
            openInfoWindow,
            redMarkerPosition,
            showRedMarker,
            client_name: this.noteDetails.client,
            // Since the user is encouraged to not fill this field if they dont know it, handle as needed
            file_number: this.noteDetails.file_number,
            source_street: this.noteDetails.source.street_name,
            source_between_streets: this.noteDetails.source.between_streets,
            source_neighborhood: this.noteDetails.source.neighborhood,
            // source_street_number: this.noteDetails.source.number,
            source_city: this.noteDetails.source.city,
            source_zip_code: this.noteDetails.source.zip_code,
            source_state: this.noteDetails.source.state,
            source_lat: this.noteDetails.source.lat,
            source_lon: this.noteDetails.source.lon,
            // not all services need source and destination km. Check the current value and adjust the display accordingly
            source_km: this.noteDetails.source_km != null ? this.noteDetails.source_km.toString() : null,
            source_references: this.noteDetails.source_notes,
            subtotal: this.noteDetails.subtotal,
            iva: this.noteDetails.iva,
            retention: this.noteDetails.retention,
            total: this.noteDetails.total,
            //total_ovr: this.noteDetails.total_ovr,
            payment_method: this.noteDetails.payment_method,
            internal_payment_type: this.noteDetails.internal_payment_type,
            payment_type: this.noteDetails.payment_type,
            invoice_use: this.noteDetails.invoice_use,
            payment_notes: this.noteDetails.payment_notes,
            metodo_pago: this.noteDetails.metodo_pago,
            forma_pago: this.noteDetails.forma_pago,
            clave_prod_serv: this.noteDetails.clave_prod_serv,
            concepto_descripcion: this.noteDetails.concepto_descripcion,
            mercancia_descripcion: this.noteDetails.mercancia_descripcion,
            mercancia_unidad: this.noteDetails.mercancia_unidad,
            asegura_resp_civil: this.noteDetails.asegura_resp_civil,
            poliza_resp_civil: this.noteDetails.poliza_resp_civil,
            remolque_tipo: this.noteDetails.remolque_tipo,
            maneuver_to_perform: this.noteDetails.maneuver_to_perform,
            forklift_capacity: this.noteDetails.forklift_capacity,
            cost_by_hr: this.noteDetails.cost_by_hr,
            cost_by_traffic: this.noteDetails.cost_by_traffic,
            cost_by_maneuvers: this.noteDetails.cost_by_maneuvers,
            cost_by_permissions: this.noteDetails.cost_by_permissions,
            sucursal: this.noteDetails.sucursal,
            quick_origin: this.noteDetails.quick_origin,
            note_number: this.noteDetails.note_number,
            currentForm: 'initialData',
            client_state: '',
            allStates: [
                { name: 'Aguascalientes' },
                { name: 'Baja California' },
                { name: 'Baja California Sur' },
                { name: 'Campeche' },
                { name: 'Chiapas' },
                { name: 'Chihuahua' },
                { name: 'Ciudad de México' },
                { name: 'Coahuila' },
                { name: 'Colima' },
                { name: 'Durango' },
                { name: 'Estado de México' },
                { name: 'Guanajuato' },
                { name: 'Guerrero' },
                { name: 'Hidalgo' },
                { name: 'Jalisco' },
                { name: 'Michoacán' },
                { name: 'Morelos' },
                { name: 'Nayarit' },
                { name: 'Nuevo León' },
                { name: 'Oaxaca' },
                { name: 'Puebla' },
                { name: 'Querétaro' },
                { name: 'Quintana Roo' },
                { name: 'San Luis Potosí' },
                { name: 'Sinaloa' },
                { name: 'Sonora' },
                { name: 'Tabasco' },
                { name: 'Tamaulipas' },
                { name: 'Tlaxcala' },
                { name: 'Veracruz' },
                { name: 'Yucatán' },
                { name: 'Zacatecas' }
            ],
            filteredClientStates: [],
            filteredSourceStates: [],
            client_address: '',
            client_rfc: '',
            phone:'',
            addNewClient: false,
            clients: [], 
            filteredClients: [], 
            clientDetails: {
                "client_address": {
                    
                }
            }, 
            isClientSelected: false,
            showOptionalFields: false,
            showOptionalFields1: false,
            gruposCampos: [ 
            { unit: '', operator: '', start_km: null, end_km: null, assistance_note: this.noteDetails.id, assigned_by: this.user_id},
            ],
            assignationsData: [],
            saving_note: false,
            forklift: {width:this.noteDetails.forklift_width,height:this.noteDetails.forklift_height, 
                lon: this.noteDetails.forklift_long, weight: this.noteDetails.forklift_weight},
            total_hours: this.noteDetails.total_hours,
            prefered_product_key_traffic: '78101803',
            prefered_measurement_unit_traffic: 'ACT',
            // prefered_product_key_pension_days: '78181702',
            // prefered_measurement_unit_pension_days: 'ACT',
            // prefered_product_key_cost_km: '78121603',
            // prefered_measurement_unit_cost_km: 'ACT',
            prefered_product_key_maneuvers: '81141601',
            prefered_measurement_unit_maneuvers: 'ACT',
            prefered_product_key_service_hours: '78101803',
            prefered_measurement_unit_service_hours: 'ACT',
            invoiceBreakdown: false,
            invoiceBreakdownEdit: false,
            applies_retention: false,
            cartaporte: false,
            selectedClient: null, 
            highlightedIndex: -1,
            municipalities: [
                "Monterrey", "San Nicolás de los Garza", "San Pedro Garza García",
                "Guadalupe", "Apodaca", "Escobedo", "Santa Catarina", "Juárez", "García",
                "Pesquería", "Santiago", "El Carmen", "Cadereyta"
            ],
            sourceFilteredMunicipalities: [],
            destinationFilteredMunicipalities: [],
        };
    },
    watch: {
        assignations: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (Array.isArray(newVal) && newVal.length > 0) {
                    this.gruposCampos = newVal.map(assignation => ({
                        id: assignation.id,
                        unit: assignation.unit.unit_short_id,
                        operator: assignation.operator.employee_ident,
                        assigned_by: assignation.assigned_by.employee_user,
                        start_km: assignation.start_km,
                        end_km: assignation.end_km,
                        assistance_note: assignation.assistance_note.id,
                        operador_name: assignation.operator.employee_first_name,
                        operador_last_name: assignation.operator.employee_last_name,
                        unidad_name: assignation.unit.unit_sub_brand
                    }));
                    } 
        },
        client_state(newValue) {
            this.filteredClientStates = this.getFilteredStates(newValue);
        },
        source_state(newValue) {
            this.filteredSourceStates = this.getFilteredStates(newValue);
        },
        'localNoteDetails.note_ident': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchAssignationsForNote();
            }
        }
        },
        'localNoteDetails.cost_by_hr': 'updateCalculatedCosts',
        'localNoteDetails.total_hours': 'updateCalculatedCosts',
        'localNoteDetails.cost_by_traffic': 'updateCalculatedCosts',
        'localNoteDetails.cost_by_maneuvers': 'updateCalculatedCosts',
        'localNoteDetails.cost_by_permissions': 'updateCalculatedCosts',
    },
    mounted() {
        this.fetchAssignationsForNote();
    },
    computed: {
        allTimesCompleted() {
            if (!Array.isArray(this.assignationsData) || this.assignationsData.length === 0) {
                return false;
            }
            return this.assignationsData.every(assignation =>
                assignation.start_time && assignation.arrival_time &&
                assignation.contact_time && assignation.end_time
            );
        },
        isFormValid() {
            /** Used to prevent the form submission until all required values are set */
            return (
                true
                // sub brand, client, and lat lon are required 
                // check all required fields contain data before allowing update of the note
                //Client name being null/empty implies there is nothing in the client field, thus form cannot be valid in this state 
                // this.client_name != null &&  this.client_name != ""
            );
        }
    },
    methods: {
        sourceFilterMunicipalities() {
            const searchTerm = this.localNoteDetails.source?.city?.toLowerCase() || '';
            this.sourceFilteredMunicipalities = this.municipalities.filter(municipality =>
                municipality.toLowerCase().includes(searchTerm)
            );
        },
        sourceSelectMunicipality(municipality) {
            this.localNoteDetails.source.city = municipality;
            this.sourceFilteredMunicipalities = [];
            console.log(this.localNoteDetails.source.city);
        },
        destinationFilterMunicipalities() {
            const searchTerm = this.localNoteDetails.destination?.city?.toLowerCase() || '';
            this.destinationFilteredMunicipalities = this.municipalities.filter(municipality =>
                municipality.toLowerCase().includes(searchTerm)
            );
        },
        destinationSelectMunicipality(municipality) {
            this.localNoteDetails.destination.city = municipality;
            this.destinationFilteredMunicipalities = [];
        },
        navigateClients(direction) {
            if (!this.filteredClients.length) return;

            const count = this.filteredClients.length;
            this.highlightedIndex = (this.highlightedIndex + direction + count) % count;

            // Scroll the highlighted item into view
            this.$nextTick(() => {
                const dropdown = this.$refs.dropdownContainer;
                const highlightedItem = this.$refs.dropdownItem[this.highlightedIndex];
                if (dropdown && highlightedItem) {
                    const itemOffsetTop = highlightedItem.offsetTop;
                    const itemHeight = highlightedItem.offsetHeight;
                    const dropdownScrollTop = dropdown.scrollTop;
                    const dropdownHeight = dropdown.offsetHeight;

                    if (itemOffsetTop < dropdownScrollTop) {
                        dropdown.scrollTop = itemOffsetTop;
                    } else if (itemOffsetTop + itemHeight > dropdownScrollTop + dropdownHeight) {
                        dropdown.scrollTop = itemOffsetTop - dropdownHeight + itemHeight;
                    }
                }
            });
        },
        selectHighlightedClient() {
            if (this.highlightedIndex !== -1) {
                this.selectClient(this.filteredClients[this.highlightedIndex]);
            }
        },
        updateCalculatedCosts() {
            this.localNoteDetails.subtotal = (
                (Number(this.localNoteDetails.cost_by_hr) * Number(this.localNoteDetails.total_hours) || 0) +
                (Number(this.localNoteDetails.cost_by_traffic) || 0) +
                (Number(this.localNoteDetails.cost_by_maneuvers) || 0) +
                (Number(this.localNoteDetails.cost_by_permissions) || 0)
            ).toFixed(2); // Ensures the result is a string formatted to two decimal places
            this.localNoteDetails.iva = (
                (this.localNoteDetails.subtotal) * .16
            ).toFixed(2);
            this.localNoteDetails.retention = (
                (this.localNoteDetails.subtotal) * (this.applies_retention ? .04 : 0)
            ).toFixed(2);
            this.localNoteDetails.total = (
                Number(this.localNoteDetails.subtotal) + Number(this.localNoteDetails.iva) - Number(this.localNoteDetails.retention)
            ).toFixed(2);
        },
        async fetchClientById(clientId) {
            try {
                const apiDirectory = process.env.API_DIRECTORY; // Adjust to your actual API endpoint
                const response = await axios.get(`${apiDirectory}/clients/${clientId}/`);
                this.client_id = clientId; // Keep the client ID in sync
                // this.client_name = response.data.client_name; // Update the display name
                // if (response.data.prefered_product_key_pension_days) {
                //     this.prefered_product_key_pension_days = response.data.prefered_product_key_pension_days;
                // }
                // if (response.data.prefered_measurement_unit_pension_days) {
                //     this.prefered_measurement_unit_pension_days = response.data.prefered_measurement_unit_pension_days;
                // }
                if (response.data.prefered_product_key_traffic) {
                    this.prefered_product_key_traffic = response.data.prefered_product_key_traffic;
                }
                if (response.data.prefered_measurement_unit_traffic) {
                    this.prefered_measurement_unit_traffic = response.data.prefered_measurement_unit_traffic;
                }
                // if (response.data.prefered_product_key_cost_km) {
                //     this.prefered_product_key_cost_km = response.data.prefered_product_key_cost_km;
                // }
                // if (response.data.prefered_measurement_unit_cost_km) {
                //     this.prefered_measurement_unit_cost_km = response.data.prefered_measurement_unit_cost_km;
                // }
                if (response.data.prefered_product_key_maneuvers) {
                    this.prefered_product_key_maneuvers = response.data.prefered_product_key_maneuvers;
                }
                if (response.data.prefered_measurement_unit_maneuvers) {
                    this.prefered_measurement_unit_maneuvers = response.data.prefered_measurement_unit_maneuvers;
                }
                if (response.data.prefered_product_key_service_hours) {
                    this.prefered_product_key_service_hours = response.data.prefered_product_key_service_hours;
                }
                if (response.data.prefered_measurement_unit_service_hours) {
                    this.prefered_measurement_unit_service_hours = response.data.prefered_measurement_unit_service_hours;
                }
                if (response.data.applies_retention) {
                    // this.applies_retention = response.data.applies_retention;
                    this.applies_retention = false;
                }
                // No need to update localNoteDetails.client here, as it should already have the correct ID
                //console.log(this.client_name)
            } catch (error) {
                console.error('Error fetching client details:', error);
            }
        },
        async fetchClients() {
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-privileged-clients`);
                this.clients = response.data;
            } catch (error) {
                // error
            }
        },
        filterClients() {
            const searchTerm = this.client_name.toLowerCase();
            if (searchTerm === '') {
                this.filteredClients = [];
                this.highlightedIndex = -1; // Reset the highlighted index
                return;
            }
            this.filteredClients = this.clients.filter(client =>
                client.client_name.toLowerCase().includes(searchTerm)
            );
            this.highlightedIndex = -1; // Reset the highlighted index when filtering
        },
        selectClient(client) {
            this.clientDetails = { ...client };
            this.client_name = client.client_name;
            this.client_id = client.id;
            this.localNoteDetails.client = this.client_id;
            this.filteredClients = [];
            this.isClientSelected = true;
            this.highlightedIndex = -1;
        },
        toggleNewClientForm(){
            this.addNewClient = true;
            if (this.addNewClient) {
                this.isClientSelected = false;
            }
        },
        cancelNewClient() {
            this.addNewClient = false; 
        },
        closeDropdown() {
            this.filteredClients = [];
            this.sourceFilteredMunicipalities = [];
            this.destinationFilteredMunicipalities = [];
        },
        filterStates() {
            if (!this.searchState) {
                this.filteredStates = this.states;
            } else {
                this.filteredStates = this.states.filter((state) =>
                    state.toLowerCase().includes(this.searchState.toLowerCase())
            );
            }
            this.isStateDropdownActive = true;
        },
        getFilteredStates(searchValue) {
            if (searchValue.length < 0) {
                return [];
            }
            return this.allStates.filter(state =>
                state.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        },
        selectClientState(name) {
            this.client_state = name;
            this.filteredClientStates = [];
        },
        selectSourceState(name){
            this.source_state = name;
            this.filteredSourceStates = [];
        },
        agregarCampos() {
            this.gruposCampos.push({ unit: '', operator: '', start_km: 0, end_km: 0, assistance_note: this.noteDetails.id, assigned_by: this.user_id});
        },
        async guardarDatos() {
            const apiDirectory = process.env.API_DIRECTORY;
            //Call the unit assignations endpoint to check if any of the units that is about to be assigned is in hard busy state
            let units = [];
            let response;
            const busyUnits = {};
            for (let obj of this.gruposCampos) {
                let unitValue = obj['unit'];
                units.push({ 'unit': unitValue });
            }
            console.log(units)
            try {
                response = await axios.post(`${apiDirectory}/get-unit-assignations`, units);
                // This iteration ensures even if the response contains duplicates, they are removed 
                response.data.forEach(item => {
                    if (item.status === "Ocupada") {
                        busyUnits[item.unit_short_id] = item.status;
                    }
                });
            }
            catch (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Al menos una de las unidades que proporcionó no existen.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                } else if (error.response && error.response.status === 400) {
                    console.error("Bad request", error)
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Por favor intentelo de nuevo. Si el error persiste, contacte a su administrador.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                } else if (error.response && error.response.status === 500) {
                    console.error("Internal server error", error)
                    Swal.fire({
                        title: '¡Error interno del servidor!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Por favor intentelo de nuevo. Si el error persiste, contacte a su administrador.',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                } else {
                    console.error("Uncaught error", error)
                    Swal.fire({
                        title: '¡Error!',
                        text: 'Hubo un error al obtener los estatus de las unidades. Verifique que la información que introdujo sea válida',
                        icon: 'error',
                        showCancelButton: false, // Hide cancel button
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // return from func
                            return
                        }
                    });
                    // even if user ignores popup, return from func
                    return
                }
            }
            // After the unit status have been succesfully fetched, warn the user if there is a hard busy unit, if its not the case, or they 
            // ack the warning, continue, else stop
            if (Object.keys(busyUnits).length > 0) {
                // Construct message for SweetAlert
                let message = "Al menos una de las unidades que está asignando ya se encuentra ocupada.\n\n";
                message += "Unidades Ocupadas:\n";
                for (const unitId in busyUnits) {
                    message += `${unitId}\n`;
                }
                const result = await Swal.fire({
                    title: "¡Advertencia!",
                    text: message,
                    icon: "warning",
                    showCancelButton: true,
                    buttons: {
                        cancel: "Cancelar",
                        confirm: "Continuar"
                    }
                });
                if (!result.isConfirmed) return
            }
            try {
                // Update note status to 5
                const noteID = this.localNoteDetails.note_ident;
                this.localNoteDetails.note_status = 5;
                this.sanitizeForm();
                await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
            }
            // Update the note status
            catch (error) {
                console.error(error)
                Swal.fire({
                    title: '¡Error!',
                    text: 'Hubo un error al actualizar el estatus de la nota, Por favor intente de nuevo.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        return
                    }
                });
                // Even if the user ignores the popup, exit out of the func
                return
            }
            // Update the note assignations    
            try {
                if (Object.keys(this.assignations).length === 0) {
                    // If assignations is empty, use POST method
                    response = await axios.post(`${apiDirectory}/set-note-assignations`, this.gruposCampos);
                } else {
                    // If assignations is not empty, use PUT method
                    response = await axios.put(`${apiDirectory}/set-note-assignations`, this.gruposCampos);
                }
                Swal.fire({
                    title: '¡Actualizado!',
                    text: 'Las asignaciones se han actualizado con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(); // Reload the page after 'Ok' is clicked
                    }
                });
            } catch (error) {
                // Check if the error is due to DNE 
                if (error.response && error.response.status === 404) {
                    console.error("Not found", error)
                    Swal.fire(
                        '¡Error!',
                        'Verifique el id de las unidades y operadores que intenta asignar. Si esta unidad existía en el pasado, asegurese de que no ha sido eliminada.',
                        'error'
                    );
                } else if (error.response && error.response.status === 400) {
                    console.error("Bad request", error)
                    Swal.fire(
                        '¡Error!',
                        'Verifique que los valores que intenta asignar son correctos.',
                        'error'
                    );
                } else {
                    console.error("Failed to update assignation:", error);
                    Swal.fire(
                        '¡Error!',
                        'Hay errores en las asignaciones',
                        'error'
                    );
                }
            }
        },
        async eliminarCampos(index = this.gruposCampos.length - 1) {
            const apiDirectory = process.env.API_DIRECTORY;

            if (this.gruposCampos.length > 1) {
                const assignationToDelete = this.gruposCampos[index];

                try {
                // Make the API call for the specific assignation
                await axios.post(`${apiDirectory}/delete-note-assignations`, assignationToDelete);

                // Remove the row from the gruposCampos array
                this.gruposCampos.splice(index, 1);

                Swal.fire({
                    title: '¡Actualizado!',
                    text: 'Las asignaciones se han actualizado con éxito',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                    location.reload(); // Reload the page after 'Ok' is clicked
                    }
                });
                } catch (error) {
                console.error(error);
                Swal.fire({
                    title: 'Error',
                    text: 'Hubo un problema al eliminar la asignación. Por favor, inténtalo de nuevo.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                }
            } else {
                Swal.fire('Error', 'Debe haber al menos un grupo de campos.', 'error');
            }
        },
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        showForm(formName) {
            this.currentForm = formName;
        },
        saveNote: async function() {
            try {
                const noteID = this.noteDetails.note_ident; // Ensure this matches the actual ID property
                const apiDirectory = process.env.API_DIRECTORY;
                this.sanitizeForm();
                this.saving_note = true
                await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                Swal.fire(
                    '¡Actualizado!',
                    'Los datos del formulario se han actualizado con éxito.',
                    'success'
                ).then((result) => {
                if (result.isConfirmed) window.location.reload();
                });
                this.saving_note = false;
                this.$forceUpdate;
                // Additional actions after success, e.g., redirecting or updating local state
            } catch (error) {
                // Polygon condition and bad serializer cases
                console.error(error)
                this.saving_note=false
                if (error.response.status == 400) {
                    let isPolygon = false
                    if (error.response.data) {
                        const errorDataString = JSON.stringify(error.response.data);
                        try { isPolygon = errorDataString.includes("Mexico") }
                        catch { isPolygon = false }
                    }
                    if (isPolygon) {
                        Swal.fire('Error', 'Verifique que la latitud y longitud sean válidas. \nLos datos que intentó ingresar no corresponden a una dirección dentro de México.', 'error');
                    }
                    else {
                        Swal.fire('Error', 'Verifique que los datos que está ingresando son válidos', 'error');
                    }
                }
                else {
                    Swal.fire('Error', 'Hubo un problema al actualizar los datos de la nota.', 'error');
                }
            }
        },
        completeNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.note_status = 4; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while completing the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Completado!',
                    text: 'La nota se ha completado con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al completar la nota.', 'error');
            }
        },
        reOpenNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.note_status = 1; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while reopening the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Reabierto!',
                    text: 'La nota se ha reabierto con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al reabrir la nota.', 'error');
            }
        },
        cancelNote: async function() {
            try {
                const noteID = this.localNoteDetails.note_ident; 
                const apiDirectory = process.env.API_DIRECTORY;
                this.localNoteDetails.note_status = 2; 
                this.sanitizeForm();
                const response = await axios.put(`${apiDirectory}/notes/${noteID}/`, this.localNoteDetails);
                
                // Check for 500 error in response
                if (response.status === 500) {
                    Swal.fire('Error', 'Internal Server Error occurred while closing the note.', 'error');
                    return;
                }

                Swal.fire({
                    title: '¡Cancelado!',
                    text: 'La nota y sus asignaciones se han cancelado con éxito',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload();
                    }
                });
            } catch (error) {
                console.error("Failed to update note:", error);
                Swal.fire('Error', 'Hubo un problema al cancelar la nota.', 'error');
            }
        },
        fetchLocations() {
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/fetch-last-vehicle-locations`)
                .then(response => {
                    this.locations.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching locations:', error);
                });
        },
        onMounted() {
            this.fetchLocations();
            setInterval(this.fetchLocations, 20000);
        },
        async saveNewClient() {
            const clientData = {
                client_ident: 'test',
                client_name: this.client_name,
                client_address: {
                    street: this.clientDetails.client_address.street,
                    codigoPostal: this.clientDetails.client_codigoPostal,
                    neighborhood: this.client_neighborhood,
                    city: this.client_city,
                    state: this.client_state,
                },
                client_rfc: this.clientDetails.client_rfc,
                phone: this.clientDetails.phone,
                client_status: 1,
                is_privileged: false
                
            };
            try {
                // Validate clientData here (optional)

                // Replace 'http://yourbackend.api/clients' with your actual API endpoint
                //console.log(clientData);
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/create-client`, clientData, {
                            headers:
                            {
                                'Content-Type': 'application/json',
                            },
                        });
                this.client_id = response.data.id;
                this.localNoteDetails.client = this.client_id;
                
                // If the request is successful
                Swal.fire('Guardado!', 'El nuevo cliente se guardó correctamente.', 'success');
                

            } catch (error) {
                console.error('There was an error saving the client:', error);
                Swal.fire('Error', 'Hubo un problema al guardar el cliente nuevo.', 'error');
            }
        },
        saveBilling() {
            // Generate sign and stamp invoice
            const noteID = this.localNoteDetails.note_ident; 
            const vectorNoteID = this.localNoteDetails.note_ident.split('-');
            const apiDirectory = process.env.API_DIRECTORY;
            const productDescriptions = {
                '78101803': "TRAFICO",
                '72154503': 'SERVICIO DE GRUA',
                '78121603': 'KILOMETRAJE',
                '90121800': 'ASISTENCIA DE EMERGENCIA',
                '81141601': 'MONTACARGAS 15 MIL LBS',
                // Add more keys as needed
            };
            const retention_perc = this.applies_retention ? 0.04 : 0.00;
            // const subbrandinfo = axios.post(`${apiDirectory}/get-sub-brand`, {"sub_brand_id": firstAssignation.assistance_note.vehicle_sub_brand});
            // console.log(subbrandinfo);
            
            return axios.get(`${apiDirectory}/get-billing-info/${noteID}/`)
                .then(response => {
                    // console.log(response.data['client']['tax_regime']);
                    
                    try{
            let conceptos;
            // console.log(this.invoiceBreakdown);
                if (this.invoiceBreakdown === false){
                    conceptos = { 
                        //Custom product key
                        1:{
                        clave_prod_serv: this.prefered_product_key_traffic,
                        cantidad:'1',
                        clave_unidad: this.prefered_measurement_unit_traffic,    
                        descripcion: productDescriptions[this.prefered_product_key_traffic],
                        valor_unitario: response.data['subtotal'],
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: response.data['iva'],
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: Number(response.data['subtotal']) * retention_perc,
                            }
                        },
                    }};
                } else {
                    // console.log('hola');
                    const grandTraffic = Number(response.data['cost_by_traffic']);
                    const grandPermissions = Number(response.data['cost_by_permissions']);
                    const grandManeuvers = Number(response.data['cost_by_maneuvers']);
                    console.log(grandManeuvers);
                    const grandHr = Number(response.data['cost_by_hr']);
                    console.log(grandTraffic);
                    console.log(grandPermissions);
                    console.log(grandHr);
                    // const grandImpoundLot =  Number(response.data['lot_total_cost']);
                    conceptos = { 
                        1:{ //TRAFICO
                        clave_prod_serv: this.prefered_product_key_traffic,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_traffic,    
                        descripcion: 'TRAFICO',
                        valor_unitario: grandTraffic.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandTraffic * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: grandTraffic * retention_perc,
                            }
                        },
                    },
                    2: {//MANOIBRAS
                        clave_prod_serv:this.prefered_product_key_maneuvers,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_maneuvers,    
                        descripcion: 'MANIOBRAS',
                        valor_unitario: grandManeuvers.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandManeuvers * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: grandManeuvers * retention_perc
                            }
                        },
                    },
                    3:{//Permissions = casetas
                        clave_prod_serv:this.prefered_product_key_traffic,
                        cantidad:'1',
                        clave_unidad:this.prefered_measurement_unit_traffic,    
                        descripcion: 'PERMISOS',
                        valor_unitario: grandPermissions.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandPermissions * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: grandPermissions * retention_perc
                            }
                        },
                    },
                    4:{//COSTO POR HORA
                        clave_prod_serv:this.prefered_product_key_service_hours,
                        cantidad: response.data['total_hours'].toString(),
                        clave_unidad:this.prefered_measurement_unit_service_hours,    
                        descripcion: 'COSTO POR HORA',
                        valor_unitario: grandHr.toString(),
                        objeto_imp: '02', 
                        no_identificacion: '8635',
                        impuestos:{
                            traslados:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota:'0.16',
                                importe: grandHr * .16,
                            },
                            retenciones:{
                                impuesto:'002',
                                tipo_factor:'Tasa',
                                tasa_o_cuota: retention_perc.toString(),
                                importe: grandHr * retention_perc
                            }
                        },
                    },
                    // 5:{//PENSION
                    //     clave_prod_serv:this.prefered_product_key_pension_days,
                    //     cantidad: dateDifferenceDays,
                    //     clave_unidad:this.prefered_measurement_unit_pension_days,    
                    //     descripcion: 'PENSION',
                    //     valor_unitario: response.data['lot_cost_per_day'].toString(),
                    //     objeto_imp: '02', 
                    //     no_identificacion: '8635',
                    //     impuestos:{
                    //         traslados:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.16',
                    //             importe: Number(response.data['lot_cost_per_day'])  * .16,
                    //         },
                    //         retenciones:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota: retention_perc.toString(),
                    //             importe: Number(response.data['lot_cost_per_day']) * retention_perc
                    //         }
                    //     },
                    // }
                };
                }
                //comenzar diccionario con info
                this.billingInfo = {
                    cartaporte: this.cartaporte,
                    receptor: {
                        rfc: response.data['client']['client_rfc'],
                        nombre: response.data['client']['client_name'],
                        uso_cfdi: response.data['invoice_use'],
                        domicilio_fiscal_receptor: response.data['client']['client_address']['codigoPostal'],
                        regimen_fiscal_receptor: response.data['client']['tax_regime']
                    },
                    metodo_pago: response.data['payment_method'],
                    forma_pago: response.data['payment_type'],
                    serie: vectorNoteID[0],
                    folio: vectorNoteID[2],
                    conceptos: conceptos,
                    // { 
                    //     1:{
                    //     clave_prod_serv:claveProdServ,
                    //     cantidad:'1',
                    //     clave_unidad:'ACT',    
                    //     descripcion: 'SERVICIO DE LAS ORDENES ' + orderNumbers,
                    //     valor_unitario: grandSubtotal.toString(),
                    //     objeto_imp: '02', 
                    //     no_identificacion: '8635',
                    //     impuestos:{
                    //         traslados:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.16',
                    //             importe: grandSubtotal * .16,
                    //         },
                    //         retenciones:{
                    //             impuesto:'002',
                    //             tipo_factor:'Tasa',
                    //             tasa_o_cuota:'0.04',
                    //             importe: grandSubtotal * .04
                    //         }
                    //     },
                    // }},
                    
                };
                console.log(this.billingInfo);
                return this.invoiceStamping(this.billingInfo)
            
            .then(stampedInvoice => {
                return stampedInvoice;
            })
            .catch(error => {
                console.error('Error fetching locations:', error);
            });
        } catch (error) {
        console.error('Error constructing billing info:', error);
    }


                    // this.billingInfo = {
                    //     cartaporte:0,
                    //     receptor:{
                    //         rfc: response.data['client']['client_rfc'], 
                    //         nombre: response.data['client']['client_name'],
                    //         uso_cfdi: response.data['invoice_use'],
                    //         domicilio_fiscal_receptor: response.data['client']['client_address']['codigoPostal'],
                    //         regimen_fiscal_receptor: response.data['client']['tax_regime']
                    //     },
                    //     metodo_pago: response.data['payment_method'],
                    //     forma_pago: response.data['payment_type'], 
                    //     serie: vectorNoteID[0], 
                    //     folio: vectorNoteID[2], 
                    //     conceptos:{ 
                    //         1:{
                    //         clave_prod_serv:this.prefered_product_key,
                    //         cantidad:'1',
                    //         clave_unidad: this.prefered_measurement_unit,
                    //         descripcion: productDescriptions[this.prefered_product_key],
                    //         valor_unitario: response.data['subtotal'],
                    //         objeto_imp: '02', 
                    //         no_identificacion: '8635',
                    //         impuestos:{
                    //             traslados:{
                    //                 impuesto:'002',
                    //                 tipo_factor:'Tasa',
                    //                 tasa_o_cuota:'0.16',
                    //                 importe: response.data['iva'],
                    //             },
                    //             retenciones:{
                    //                 impuesto:'002',
                    //                 tipo_factor:'Tasa',
                    //                 tasa_o_cuota:'0.04',
                    //                 importe: 0
                    //             }
                    //         },}
                    //     }};
                    return this.invoiceStamping(this.billingInfo);
                })
                .then(stampedInvoice => {
                    return stampedInvoice;
                })
                .catch(error => {
                    console.error('Error getting forklift information:', error);
                    // Swal.fire('Error', 'Hubo un problema al guardar el cliente nuevo.', 'error');
                    // console.log(error);
                });
        },
        invoiceStamping(billingInfo) {
            const apiDirectory = process.env.API_DIRECTORY;
            return axios.post(`${apiDirectory}/invoice-stamping`, billingInfo)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    // const errorMessage = 
                    //     error.response.data.error === 'conversion from NoneType to Decimal is not supported'
                    //         ? 'Error en datos de costos'
                    //         : error.response.data.error;
                    console.error('Error generando factura:', error);
                    Swal.fire('Error', 'Hubo un problema al generar la factura. ' + error.response.data.error, 'error');
                    console.log(error.response.data.error);
                    console.log(error);
                });
        },
        async generateInvoiceDocument(){
            const apiDirectory = process.env.API_DIRECTORY;
            const signedXML = await this.saveBilling();
            const firstAssignation = this.assignationsData[0];
            const dateDifferenceHours = Math.ceil(Math.abs(new Date(firstAssignation.end_time) - new Date(firstAssignation.start_time)) / (1000 * 60 * 60));
            axios.post(`${apiDirectory}/generate-invoice-documents`, 
                        {signedXML: signedXML,
                        plh_authorize: this.localNoteDetails.approved_by,
                        plh_operatorName: firstAssignation.operator.employee_first_name + ' ' + firstAssignation.operator.employee_last_name,
                        plh_operatorNumber: firstAssignation.operator.employee_ident,
                        plh_orderId: this.localNoteDetails.note_ident,
                        plh_unitNumber: firstAssignation.unit.unit_short_id,
                        plh_plates: this.localNoteDetails.vehicle_plates,
                        plh_vehicleBrand: this.selectedBrandId + ' ' + this.selectedSubBrandId, 
                        plh_vehicleModel: this.localNoteDetails.vehicle_model + '  ' + this.localNoteDetails.vehicle_color,
                        plh_niv: this.localNoteDetails.vehicle_engine_series,
                        plh_insuredName: 'No disponible',//this.localNoteDetails.vehicle_owner_name,
                        plh_record: this.localNoteDetails.file_number,
                        plh_insurancePolicy: 'No disponible', //this.localNoteDetails.insurance_policy,
                        plh_maneuver: this.localNoteDetails.maneuver_to_perform,
                        plh_origin: this.localNoteDetails.source.neighborhood + ', ' + this.localNoteDetails.source.city + ' ' + this.localNoteDetails.source.state,
                        plh_serviceDescription: this.localNoteDetails.source_notes,
                        plh_startTime: firstAssignation.start_time,
                        plh_arrivalTime: firstAssignation.arrival_time,
                        plh_endTime: firstAssignation.end_time,
                        plh_retention:this.localNoteDetails.retention,
                        plh_totalTime: dateDifferenceHours,
                        plh_discount: '0.00',
                        note_type: 'MTC'
                        }, 
            {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'factura_multiformato.zip');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('Error generating files', error)
                // Swal.fire('Error', 'Hubo un problema al generar el documento', 'error');
                // console.log(error.response.data.error);
            });
        },
        fetchAssignationsForNote() {
            const apiDirectory = process.env.API_DIRECTORY;
            const noteId = this.localNoteDetails.note_ident;
            console.log("Note ID here");
            console.log(noteId);
            axios.post(`${apiDirectory}/get-note-assignations`, { note_ident: noteId })
                .then(response => {
                    this.assignationsData = response.data.map(assignation => ({
                        ...assignation,
                        unidad: assignation.unit.unit_short_id,
                        operador: assignation.operator.employee_ident,
                        operador_name: assignation.operator.employee_first_name,
                        operador_last_name: assignation.operator.employee_last_name,
                        unidad_name: assignation.unit.unit_sub_brand
                    }));
                    //Used afterwards to compare if the original or the updated value should be used when making an update
                    this.initialAssignations = JSON.parse(JSON.stringify(this.assignationsData));
                    if (this.assignationsData[0]) {
                const startTime = new Date(this.assignationsData[0].start_time);
                const endTime = new Date(this.assignationsData[0].end_time);
                const timeDifference = (endTime - startTime) / (1000 * 60 * 60); // Convert ms to hours

                // Set the total_hours in localNoteDetails
                this.localNoteDetails.total_hours = parseFloat(timeDifference.toFixed(2));
                console.log("here")
            }
                })
                .catch(error => {
                    const startTime = 0;
                    const endTime = 0;
                    const timeDifference = (endTime - startTime) / (1000 * 60 * 60); // Convert ms to hours

                    // Set the total_hours in localNoteDetails
                    this.localNoteDetails.total_hours = parseFloat(timeDifference.toFixed(2));
                    console.log("here2")
                    console.error('Error fetching assignations:', error);
                });
        },
        toISOFormatWithTimezone(dateString) {
            if (!dateString) return null;
            try {
                const date = new Date(dateString);
                let isoDate = date.toISOString();
                return isoDate;
            }
            catch (error) {
                console.error(error);
                throw new Error('Failed to format date str: ' + error.message);
            }
        },
        async saveAssignation(assignationData, index) {
            let payload;
            // check if indeed there was a change. If the original time and current time are different, plus current is not null, set flag to true
            const start_time_updated = assignationData.start_time != null && (assignationData.start_time != this.initialAssignations[index].start_time);
            const arrival_time_updated = assignationData.arrival_time != null && (assignationData.arrival_time != this.initialAssignations[index].arrival_time);
            const contact_time_updated = assignationData.contact_time != null && (assignationData.contact_time != this.initialAssignations[index].contact_time);
            const end_time_updated = assignationData.end_time != null && (assignationData.end_time != this.initialAssignations[index].end_time);
            try {
                payload = {
                    // Depending on the flag control wether to use the (new) current value, or the existing one
                    start_time: start_time_updated ? this.toISOFormatWithTimezone(assignationData.start_time) : this.initialAssignations[index].start_time,
                    arrival_time: arrival_time_updated ? this.toISOFormatWithTimezone(assignationData.arrival_time) : this.initialAssignations[index].arrival_time,
                    contact_time: contact_time_updated ? this.toISOFormatWithTimezone(assignationData.contact_time) : this.initialAssignations[index].contact_time,
                    end_time: end_time_updated ? this.toISOFormatWithTimezone(assignationData.end_time) : this.initialAssignations[index].end_time,
                };
            }
            catch (error) {
                console.error(error)
                Swal.fire({
                    title: 'Error!',
                    text: 'Se recibio un valor inválido de tiempo para la actualización. Verifique los datos que introdujo.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                })
                return
            }
            const apiDirectory = process.env.API_DIRECTORY;
            const assignationId = assignationData.id;

            try {
                await axios.patch(`${apiDirectory}/assignation-detail/${assignationId}/`, payload);

                if (assignationData.start_time) {
                    this.localNoteDetails.note_status = 1;
                    await this.saveNote();
                }

                Swal.fire({
                    title: '¡Completado!',
                    text: 'La hora y fecha se actualizó con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(); // Reload the page after 'Ok' is clicked
                    }
                });
            } catch (error) {
                console.error('Error al actualizar la asignación:', error);
                Swal.fire('Error', 'Hubo un problema al actualizar las asignaciones', 'error');
            }
        },
        formatDateTime(isoString) {
            if (!isoString) {
                // If the string is empty or null, returns the message
                return "No hay datos de tiempo";
            }

            // Check if the timezone offset contains seconds and remove them if present
            const timezoneIndex = isoString.lastIndexOf('-') !== -1 ? isoString.lastIndexOf('-') : isoString.lastIndexOf('+');
            let timezone = isoString.substring(timezoneIndex);
            const timezoneParts = timezone.split(':');
            if (timezoneParts.length > 2) {
                // Remove seconds from the timezone offset
                timezone = `${timezoneParts[0]}:${timezoneParts[1]}`;
            }
            const isoStringWithoutSeconds = isoString.substring(0, timezoneIndex) + timezone;

            // Attempt to create a Date object with the corrected ISO string
            const date = new Date(isoStringWithoutSeconds);

            // Check if the date is 'Invalid Date'
            if (isNaN(date.getTime())) {
                return "No hay datos de tiempo";
            }

            const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };

            const formattedDate = date.toLocaleDateString('es-MX', optionsDate);
            const formattedTime = date.toLocaleTimeString('es-MX', optionsTime).toLowerCase();

            // Combine the parts by eliminating AM/PM repetition
            const matchAmPm = formattedTime.match(/am|pm/);
            const amPm = matchAmPm ? ` ${matchAmPm[0]}` : '';
            return `${formattedTime.replace(/am|pm/, '').trim()} del ${formattedDate}${amPm}`;
        },
        sanitizeForm() {
            /**Used to sanitize values before submitting any changes */

            //Since not all notes require km, change empty str to null
            this.localNoteDetails.source_km == "" ? this.localNoteDetails.source_km = null : true
            //Since user is advised to not fill model if unkown, check if empty then change to null if its the case
        },
        validatePositiveIntegerField(event) {
            /**Used to validate positive integer fields */
            if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault();
            }
        },
        validateFloatField(event) {
            const charCode = event.charCode;
            const value = event.target.value;

            const isDigit = charCode >= 48 && charCode <= 57;
            const isMinus = charCode === 45 && value.indexOf('-') === -1;
            const isDot = charCode === 46 && value.indexOf('.') === -1;

            if (!isDigit && !isMinus && !isDot) {
                event.preventDefault();
            }
        },
        isRecommendationValid(recommendation){
            return recommendation['unit']!='No disponible';
        }
    },
    async created() {
        // Here you can add any additional initialization that needs to happen when the component is created
        // For instance, setting initial form values based on props
        this.localNoteDetails = { ...this.noteDetails };
        this.greenVehicleNames.value = [];
        this.redMarkerPosition.value = { lat: 25.689214, lng: -100.314343 }; 
        this.fetchClients();
        this.fetchClientById(this.localNoteDetails.client);       
        this.blockEdit = this.noteDetails.status == 2 || this.noteDetails.status == 4 // Determines wheter editing the note should be enabled or disabled based on status
        this.blockRecommend = true; // Safelock to prevent buttons from being used before recommendations are computed
        this.recommenderLoading = false; // Safelock to prevent recommender from being invoked multiple times 
        this.recommendations = [
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' },
            { 'unit': 'Presione el boton para recomendar', 'time_queue': 'Esperando' }]
            console.log(this.localNoteDetails)
    }
};
</script>